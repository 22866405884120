.awo-nwl {
    margin-bottom: 4rem;
    @include mq(sm) {
        margin-bottom: 5.75rem;
    }
    .awo-nwl__image {
        img {
            width: 100%;
            object-position: center;
            object-fit: cover;
            height: 224px;
            border-radius: 20px;
            @include mq(sm) {
                height: 480px;
            }
        }
    }
    .awo-nwl__container-wrapper {
        padding: 0 1rem;
        margin-top: -60px;
        z-index: 1;
        position: relative;
        @include mq(sm) {
            padding: 0 74px;
            margin-top: -125px;
        }
    }
    .awo-nwl__container-purple {
        background-color: #8d538d;
        border-radius: 10px;
        padding: 40px 24px;
        @include mq(sm) {
            padding: 50px 60px;
        }
        .awo-nwl__cp-title {
            color: #fff;
            font-family: $lu;
            font-size: 36px;
            line-height: 45px;
            font-weight: 400;
            margin: 0;
            margin-bottom: 2.25rem;
            text-align: center;
            @include mq(sm) {
                font-size: 45px;
                line-height: 45px;
                margin-bottom: 1.75rem;
            }
        }
        .awo-nwl__cp-form {
            .awo-nwl__cp-form-cols {
                display: flex;
                align-items: center;
                gap: 12px;
                margin-bottom: 12px;
                flex-wrap: wrap;
                @include mq(sm) {
                    flex-wrap: nowrap;
                    gap: 22px;
                    margin-bottom: 1.5rem;
                }
                input {
                    font-family: $lato;
                    background: #fff;
                    color: #2b2b2b;
                    font-size: 14px;
                    line-height: 9px;
                    font-weight: 900;
                    text-transform: uppercase;
                    border-radius: 10px;
                    height: 48px;
                    &::placeholder {
                        color: #2b2b2b;
                        opacity: 100%;
                        font-weight: 700;
                    }
                }
            }
            button {
                background-color: #2b2b2b;
                color: #fff;
                border-radius: 10px;
                text-align: center;
                font-family: $lato;
                font-size: 14px;
                font-weight: 700;
                line-height: 9px;
                text-transform: uppercase;
                border: none;
                padding: 1.25rem 1rem;
                min-width: 11.25rem;
                transform: perspective(1px) translateZ(0);
                transition-duration: 0.3s;
                transition-property: color, background-color, border;
                width: 100%;
                @include mq(sm) {
                    width: auto;
                }
                &:hover {
                    background-color: #f7a824;
                    color: #2b2b2b;
                }
            }
        }
    }
}
