.awo-two-col-links {
  .awo-two-col-links__grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 3.25rem;
    row-gap: 4rem;
    margin-top: 3rem;

    @include mq(sm) {
      grid-template-columns: repeat(2, 1fr);
      row-gap: 5.75rem;
    }

    .awo-two-col-links__grid-item {
      font-family: $font-awo-body;
      font-weight: 600;
      font-size: 1.125rem;
      line-height: 1.5625rem;
      color: $awo-black-jet-dark;
      position: relative;
      padding-top: 1.25rem;

      &:hover {
        color: $awo-flame;

        &::after {
          background-color: $awo-flame;
        }
      }

      &::after {
        content: "";
        width: 100%;
        height: 0.45rem;
        background-color: $awo-black-jet-dark;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }

  .awo-two-col-links__button {
    text-align: center;
    margin-top: 2.75rem;
  }
}
