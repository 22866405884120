.footer {
    position: relative;
    background-color: #383838;
    padding-top: 27px;
    padding-bottom: 42px;
    margin-top: 4vw;
    &::before {
        content: '';
        position: absolute;
        top: -4vw;
        left: 0;
        width: 100%;
        height: 4vw;
        background: url('/img/shape-footer-top.png') no-repeat center;
        background-size: cover;
        z-index: 11;
    }

}   
.footer-bird{
    position: absolute; 
    right: -190px;
    top: 70px;
    z-index: -999999;
}
.footer-top {
    margin-bottom: 42px;
}
.footer-top__title {
    margin-bottom: 17px;
    color: #ffffff;
    font-family: $lu;
    font-size: 30px;
    font-weight: 400;
}

form.footer__form {
    display: flex;
    align-items: center;
    justify-content: space-between;

    ::placeholder {
        color: #231f20;
    }
    input {
        background: #fff;
        height: 52px;
        border-radius: 12px;
        border: 0;
        font-family: $lato;
        font-size: 16px;
        font-weight: 700;
        color: #000;
        letter-spacing: 0.15px;
        padding: 0 27px;
        &:nth-child(1) {
            
            flex-basis: 36%;
            min-height: 52px;
            max-width: 425px;
        }
        &:nth-child(2) {
           
            flex-basis: 42%;
            max-width: 495px;
            min-height: 52px;
        }
    }  
    button {
        flex-basis: 19%;
        min-width: 0%;
        height: 52px;
        border-radius: 12px;
        background-color: $orange-color;
        border: 0;
        text-align: center;
        color: #ffffff;
        letter-spacing: 0.08px;
        font-family: $lato;
        font-size: 16px;
        font-weight: 400;
        &:hover{
            @extend %orange-hover;
        }
    }
}

.footer-middle {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 50px;
    position: relative;
    .col:nth-child(1) {
        flex-basis: 36%;
    }
    .col:nth-child(2) {
        flex-basis: 42%;
    }
    .col:nth-child(3) {
        flex-basis: 19%;
    }

}

.footer__logo {
    margin-bottom: 40px;
}

.footer__address {
    color: #e9e9e9;
    font-size: 15px;
    font-weight: 700;
    line-height: 1.35;
    letter-spacing: 0.37px;
}

.footer__nav {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: -25px;
    li {
        flex-basis: 50%;
        margin-bottom: 25px;
        a {
            text-transform: uppercase;
            color: #fff;
            font-size: 14px;
            font-weight: 700;
            letter-spacing: 0.54px;
            &:hover{
                color: $orange-color;
            }
        }
    }
}

.footer__socials {
    display: flex;
    justify-content: space-between;
    align-items: center;
    a {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 33px;
        height: 33px;
        border-radius: 50%;
        background-color: #e9e9e9;
        color: #383838;
    }
}

.footer-bot {
    .row:nth-child(1) {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 45px;
    }
    .row:nth-child(2) {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}
.footer__desc {
    color: #949494;
    font-family: Lato;
    font-size: 14px;
    font-weight: 700;
    line-height: 1.35;
    letter-spacing: 0.35px;
    margin-right: 30px;
}
.footer__copy {
    color: #949494;
    font-family: Lato;
    font-size: 14px;
    font-weight: 700;
    line-height: 1.35;
    letter-spacing: 0.35px;
    margin-right: 30px;
}
.footer-bot__links {
    font-size: 0;
    a {
        display: inline-block;
        vertical-align: middle;
        color: #949494;
        font-family: Lato;
        font-size: 14px;
        font-weight: 700;
        text-transform: uppercase;
        letter-spacing: 0.35px;
        &:not(:last-child) {
            margin-right: 25px;
        }
    }
}
