.home-links {
    margin-top: 8vw;
    margin-bottom: 7vw;
    background-color: $yellow-color-light;
    position: relative;

    &::before {
        content: '';
        position: absolute;
        top: -8vw;
        left: 0;
        width: 100%;
        height: 8vw;
        background: url('/img/shape-top-yellow.png') no-repeat center;
        background-size: cover;
        z-index: 12;
    }

    &::after {
        content: '';
        position: absolute;
        bottom: -5vw;
        left: 0;
        width: 100%;
        height: 5vw;
        background: url('/img/shape-bot-yellow.png') no-repeat center;
        background-size: cover;
        z-index: 12;
    }

    .container {
        max-width: 1090px;
    }

}

.home-links__list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: -20px;
}
.home-links__item {
    width: calc((100%/2) - 10px);
    height: 265px;
    border-radius: 18px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
    text-shadow: 0 0 28px rgba(43, 43, 43, 0.6);
    color: #ffffff;
    font-family: $lu;
    font-size: 45px;
    font-weight: 400;
    letter-spacing: 2.04px;
    margin-bottom: 20px;
    overflow: hidden;
    position: relative;
    span {
        position: relative;
        z-index: 2;;
    }
    &:hover::before {
        background-color: rgba($orange-color,0.45);
    }
    &::before {
        content: '';
        z-index: 0;
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background: rgba(#1e1e1e,0.1);
    }
}