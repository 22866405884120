.awo-cc-eb {
    // padding-bottom: 1rem;
    // @include mq(lg) {
    //     padding-bottom: 3rem;
    // }
    .awo-cc-eb__accordion {
        margin-top: 3rem;
        @include mq(lg) {
            margin-top: 4rem;
        }
    }
}
.awo-cc-eb__accordion {
    margin-top: 50px;
    h2 {
        font-family: $font-awo-title;
        font-size: 36px;
        line-height: 40px;
        color: $awo-onyx;
        @include mq(md) {
            font-size: 45px;
            line-height: 55px;
        }
    }
}
