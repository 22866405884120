@mixin image-left-common {
    padding:30px 30px 30px 0px;
}
@mixin image-right-common {
    padding: 30px 0px 30px 30px;
}

@mixin image_formate_style()  {  
    img {
        &.left {
           float: left;
           @include image-left-common();
        }
        &.right{
            float: right;
            @include image-right-common();
          
        }
       &[style*="float:left"], &[style*="float: left"] {
            
            @include image-left-common();
         }
       
     &[style*="float:right"],&[style*="float: right"]{
            @include image-right-common();
         }
        &.centre, &.center {
            display: block;
            margin-left: auto;
            margin-right: auto;
        
        }
        
        @media (max-width: 48rem) {
            &.left{
                float: none;
                width: 100% !important;
                padding:30px;
                margin: auto;   
                height: auto !important;
            }
            &.right {
                float: none;
                width: 100% !important;
                padding:30px;
                margin: auto;
                height: auto !important;
            }
            &.centre,  &.center {
                float: none;
                width: 100% !important;
                padding:30px;
                margin: auto;
                height: auto !important;
            }
            &[style*="float:left"], &[style*="float: left"] {
                width: 100% !important;
                height: auto !important;
            }
          
            &[style*="float:right"],&[style*="float: right"]{
                width: 100% !important;
                height: auto !important;
              
            }
        }
    }
}

