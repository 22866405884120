.wave-content-one {
  .wave-content-one__top-wave,
  .wave-content-one__bottom-wave {
    svg {
      fill: $awo-old-lace;
    }
  }
  .wave-content-one__top-wave {
    margin-bottom: -0.25rem;
  }
  .wave-content-one__content {
    background-color: $awo-old-lace;
    overflow: hidden;
    padding-bottom: 5.5rem;
  }
}
