.awo-three-numbers {
  .awo-h2 {
    text-align: center;
    margin-bottom: 2rem;
    @include mq(md) {
      margin-bottom: 2.5rem;
    }
  }
  .awo-three-numbers__grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    column-gap: 9rem;
    row-gap: 2.5rem;
    @include mq(sm) {
      grid-template-columns: repeat(3, 1fr);
      column-gap: 4rem;
    }
    @include mq(md) {
      column-gap: 9rem;
    }
    .awo-three-numbers__grid-item {
      .awo-three-numbers__grid-item__name {
        font-family: $font-awo-title;
        font-size: 4rem;
        line-height: 4rem;
        font-weight: 400;
        color: $awo-flame;
        text-align: center;
        @include mq(md) {
          font-size: 5rem;
          line-height: 5rem;
        }
      }
      .awo-three-numbers__grid-item__text {
        font-family: $font-awo-body;
        font-weight: 600;
        font-size: 0.9375rem;
        line-height: 1.375rem;
        color: $awo-onyx;
        text-align: center;
        max-width: 12rem;
        margin: 0 auto;
        @include mq(md) {
          font-size: 1.25rem;
          line-height: 2.25rem;
        }
      }
    }
  }
}
