.shop {
    padding-bottom: 30px;
    padding-top: 30px;
    .container {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
    }
    position: relative;
    &-dots{
        position: absolute;
        right: 0;
        top: 25%;
    }
    &-dots-patterns{
        position: absolute;
        left: 0;
        bottom: -41px;
    }
}

.shop-sidebar {
    flex: 0 1 300px;
    padding-right: 60px;
    &-bird{
        margin-top: 30px;
        margin-left: -30px;
    }
}
.shop-sidebar__title {
    color: $main-color;
    font-family: $lu;
    font-size: 45px;
    font-weight: 400;
    letter-spacing: 2.04px;
    margin-bottom: 25px;
    width:300px;
}
.shop-sidebar__cats {
    li {
        position: relative;
        margin-bottom: 15px;
        padding-left: 2px;
        &:before {
            content: '';
            padding-left: -2px;
            width: 238px;
            height: 4px;
            display: block;
            margin-bottom: 15px;
            background: url('/img/shop/icon-category.png') no-repeat center;
            background-size: cover;
        }
        a {
            color: $main-color;
            font-size: 14px;
            font-weight: 700;
            letter-spacing: 0.75px;
            width: 100%;
            display: block;
            border-radius: 5px;
            padding: 5px 10px;
            &.active{
               
                background-color: #f7a824;
            }
        }
    }
}

.shop-content {
    width: 100%;
}

.shop__list {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: -60px;
}
.shop__item {
    width: calc( (100% - 40px)/3 );
    margin-bottom: 60px;
}
.shop__item-pic {
    width: 100%;
    padding-bottom: 90%;
    border-radius: 20px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    margin-bottom: 20px;
}
.shop__item-title {
    font-size: 20px;
    color: #413d4b;
    font-weight: 700;
    margin-bottom: 5px;
}
.shop__item-price {
    color: $orange-color;
    font-size: 24px;
    font-weight: 400;
}

.shop__pagination {
    margin-top: 30px;
    text-align: right;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    a {
        border-radius: 4px;
        border: 1px solid #b8b8b8;
        background-color: #fff;
        font-size: 14px;
        color: #231f20;
        height: 29px;
        width: 29px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 700;
        &:not(:last-child) {
            margin-right: 10px;
        }
    }
    a.current {
        background-color: #383838;
        color: #fff;
    }
    a.arrow {
        color: #777777;
        position: relative;
        &.left::before{
            content: "";
            position: absolute;
            width: 14px;
            height: 14px;
            background-image: url('/img/svg/icon-left-arrow.svg');
            background-size: cover;
            left: 6px;
            top:6px;
        }
        &.right::before{
            content: "";
            position: absolute;
            width: 14px;
            height: 14px;
            background-image: url('/img/svg/icon-right-arrow.svg');
            background-size: cover;
            left: 6px;
            top:6px;
        }
    }
}


.line__item{
    margin-bottom: 20px;
    overflow: hidden;
    backface-visibility: visible;
    min-height: 65px;
    position: relative;
    opacity: 1;
    transition: opacity 0.2s ease-in-out;
}
.line__item-image {
    width: 65px;
    height: 65px;
    border-radius: 3px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    background-color: #e5e5e5;
    position: absolute;
    img{
        max-width: 100%;
    }
}
.line__item-content{
    width: 100%;
    padding-left: 75px;
    &-row{
        display: inline-block;
        width: 100%;
        margin-bottom: 5px;
        position: relative;
    }
}
.line__item-title{
    float: right;
    font-weight: bold;
    font-size: 11px;
    line-height: 17px;
    color: #767676;
    max-width: 540px;
}
.line__item-price{
    line-height: 23px;
    float: right;
    font-weight: bold;
    font-size: 15px;
    margin-right: 40px;
}
.line__item-quantity{
    padding: 10px 60px 10px 35px;
    border: 1px solid #CCCCCC;
    border-radius: 5px;
    font-size: 25px;
    color: #424242;
    position: relative;
}
.product__details{
    display: block;
    width: 100%;
}
    .product__details-pic, .product__details-info{
        float: left;
        display: block;
        display: inline-block;
       
    }
    .product__details-pic{
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        max-width: 500px;
        width: 45%;
        img{
            width: 100%;
            height: auto;
            border-radius: 20px;
        }
        padding: 0 50px 0 0;
    }
    .product__details-info{
        width: 55%;
        padding: 15px 0px 30px 50px;
        h3{
           
            color: #424242;
            font-size: 45px;
            font-weight: 400;
            letter-spacing: 1.43px;
            font-family: "Ludicrous-Stencil", serif;
            text-align: left;
        }
    }
    .product__details-price-original{
        color: #8b8b8b;
        text-decoration: line-through;
       
    }
    .product__details-price-now{
         margin-right: 30px;
    }
    .product__details-sizes{
        font-size: 25px;
        margin-top: 45px;
        margin-bottom: 15px;
        label, select{
            display: block;
            width: 100%;
            margin: 15px 0px;
        }
        select{
            border-radius: 12px;
            height: 60px;
            appearance: none;
            line-height: 58px;
            border: 1px solid #c8c8c8;
            padding-left: 22px;
            padding-right: 22px;
            font-family: "Source Sans Pro", Arial, sans-serif;
            font-size: 25px;
            font-weight: 400;
            position: relative;
            background-image: url(/img/icon-select.png);
            background-repeat: no-repeat;
            background-size: 26px;
            background-position: right 36px center;
            background-color: #fff;
        }
    }
    .product__details-add-to-cart{
        position: relative;
        margin: 15px 0px;
        input, button{
            font-size: 25px;
            height: 60px;
            text-align: center;
        }
        input{
            border: 1px solid #c8c8c8;
            border-right: 0;
            border-radius: 12px 0 0 12px;
            max-width: 120px;
            padding: 15px 22px;
        }
        button{
            border-radius: 0 12px 12px 0;
            padding: 13px 80px;
            background-color: #da5527;
            color: white;
            &:disabled{
                background-color: #d0ada0;
            }
            &:hover{
                @extend %orange-hover;
            }
        }

    }
    .product__details-description{
        margin: 45px 0px;
        font-size: 18px;
        ul{
            margin-left: 30px;
        }
        ul li{
            list-style-type: disc;
        }
    }
    .back-link{
        font-size: 18px;
        font-weight: bold;
        color: #424242;
    }
    .num_products_selected{
        position: absolute;
        display: block;
        text-align: center;
        font-size: 30px;
        color: white;
        top: -92px;
        background-color: #4eeb56 ;
        border-radius: 50%;
        padding: 30px;
        -webkit-animation-duration: 2s;

        -moz-animation-duration: 2s;
        animation-duration: 2s;

    }
    .hidden {
        display: none;
    }




.cart {
    width: 100%;
    display: block;
    h3{
        text-align: left;
    }

}
.cart__details{
    margin-bottom: 30px;
    .line__item-title, td{
        color: #424242;
    }
  
    width: 100%;
    tr{
        
        width: 100%;
        border-bottom: 1px solid #cccccc;
    }
    th{
        background-color: white;
        color: #424242;
        padding: 15px 20px;
    }
    th:nth-of-type(1){
        width: 60%;
        max-width: 720px;
    }
    th:nth-of-type(2) {
        width: 25%;
    
    }
    th:nth-of-type(3){
        width: 15%;
    }
    td{
        padding: 30px 20px;
        border: 0;
        &.line__item-image-title{   
            flex: 0 1 60%;
           
            .line__item-title{
                flex: 1;
            }

        }
        &.quantity, &.price{
           
            vertical-align: middle;
        }
        &.quantity{
            flex: 0 1 25%;
        }
        &.price{
            flex: 0 1 15%;
            font-size: 25px;
        }
    }
    .line__item-image-title-wrapper{
        display: flex;
        align-items: center;
        img{
            width: 140px;
            border-radius: 10px;
        }
        > p{
            font-size: 25px;
            padding-left: 48px;
            line-height: 30px;
        }
    }
    .line__item-quantity-decrease, .line__item-quantity-increase{
        position: absolute;
        right: 0px;
        svg{
            width: 10px;
        }
    }
    .line__item-quantity-decrease{
       bottom: 0px;
       padding: 0px 16px 3px 16px;
       border-left: 1px solid #cccccc;
    }
    .line__item-quantity-increase{
       top: 0px;
       padding: 3px 16px 0 16px;
       border-left: 1px solid #cccccc;
    }
    .remove{
        margin-left: 25px;
        &:hover{
            cursor: pointer;
        }
    }

}
.cart__summary{
    td{
        border: 0;
        &.blank{
            width: 60%;
        }
        &.title{
            width: 25%;
        }
        &.cost{
            width: 15%;
            font-size: 25px;
        }
        &.checkout-btn{
            padding-top: 35px;
        }
    }

    .cart__checkout-button{
        text-transform: capitalize;
        font-size: 25px;
        width: 100%;
        display: block;
    }
}
























