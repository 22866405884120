@font-face {
  font-family: 'Ludicrous-Stencil';
  src: url(/fonts/Ludicrous-Stencil.woff) format('woff'),
  url(/fonts/Ludicrous-Stencil.ttf) format('truetype'),
  url(/fonts/Ludicrous-Stencil.woff2) format('woff2');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Lato-Light';
  src: url(/fonts/lato/Lato-Light.woff) format('woff'),
  url(/fonts/lato/Lato-Light.ttf) format('truetype'),
  url(/fonts/lato/Lato-Light.woff2) format('woff2');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Lato-Regular';
  src: url(/fonts/lato/Lato-Regular.woff) format('woff'),
  url(/fonts/lato/Lato-Regular.ttf) format('truetype'),
  url(/fonts/lato/Lato-Regular.woff2) format('woff2');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Lato-Semibold';
  src: url(/fonts/lato/Lato-Semibold.woff) format('woff'),
  url(/fonts/lato/Lato-Semibold.ttf) format('truetype'),
  url(/fonts/lato/Lato-Semibold.woff2) format('woff2');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Lato-Bold';
  src: url(/fonts/lato/Lato-Bold.woff) format('woff'),
  url(/fonts/lato/Lato-Bold.ttf) format('truetype'),
  url(/fonts/lato/Lato-Bold.woff2) format('woff2');
  font-weight: 700;
  font-style: normal;
}
