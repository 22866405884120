.awo-quick-links-outer-wrapper {
  position: relative;

  &.reveal {
    transform: translate(0);
    opacity: 1;

    a.awo-button-hover-effect {
      h3,
      span {
        opacity: 0;
        transition: opacity 500ms ease 200ms;
      }

      @for $i from 0 through 6 {
        &:nth-child(#{$i}) {
          h3,
          span {
            transition-delay: #{0.25 + ($i * 0.1)}s;

          }
        }
      }
    }

    &.revealed {
      a.awo-button-hover-effect {
        h3,
        span {
          opacity: 1;
        }
      }
    }
  }

  img {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 9rem;
    margin-bottom: -3.25rem;

    @include mq(md) {
      display: none;
    }
  }
}

.awo-quick-links {
  margin-top: -3rem;
  z-index: 2;
  position: relative;
  box-sizing: border-box;

  @include mq(md) {
    margin-top: -3.25rem;
  }

  .quick-links-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 1.25rem;
    row-gap: 1.25rem;

    @include mq(sm) {
      grid-template-columns: repeat(3, 1fr);
      column-gap: 1.5rem;
      row-gap: 1.25;
    }

    @include mq(md) {
      grid-template-columns: repeat(5, 1fr);
      column-gap: 1.5rem;
      row-gap: 0;
    }
  }

  a {
    display: block;
    border-radius: 1rem;
    background-color: $awo-yellow-bright-crayola;
    position: relative;
    min-height: 11.25rem;
    cursor: pointer;

    @include mq(md) {
      min-height: 13.75rem;
    }

    h3 {
      color: $awo-white;
      font-family: $font-awo-title;
      font-size: 26px;
      line-height: 28px;
      margin: 0;
      padding: 0;
      margin-top: 2rem;
      padding: 0 1rem;

      @include mq(md) {
        font-size: 28px;
        line-height: 32px;
        padding: 0 1.5rem;
      }
    }

    span {
      display: flex;
      bottom: 2rem;
      position: absolute;
      width: 100%;

      svg {
        stroke: currentColor;
        fill: currentColor;
        color: white;
        margin: 0 auto;
        position: relative;
      }
    }
  }

  a:nth-child(1) {
    svg {
      height: 4rem;

      @include mq(md) {
        height: 5rem;
      }
    }
  }

  a:nth-child(2) {
    background-color: $awo-indian-red;

    svg {
      height: 5rem;

      @include mq(md) {
        height: 6rem;
      }
    }
  }

  a:nth-child(3) {
    background-color: $awo-camel;

    svg {
      height: 3.75rem;

      @include mq(md) {
        height: 4.75rem;
      }
    }
  }

  a:nth-child(4) {
    background-color: $awo-flame;

    svg {
      height: 5.5rem;

      @include mq(md) {
        height: 6.5rem;
      }
    }
  }

  a:nth-child(5) {
    background-color: $awo-bistre-brown;

    svg {
      height: 5rem;

      @include mq(md) {
        height: 6rem;
      }
    }
  }

  a:hover {
    background-color: $awo-black-jet-dark;
  }
}
