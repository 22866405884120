.dismissable-modal.modal {

  background-color: $white;
  max-width: 850px;

  .content {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .button-group {
    margin-top: 2rem;
    margin-bottom: 2rem;
    display: flex;
    flex-wrap: wrap;

    @include mq(sm) {
      flex-wrap: nowrap;
    }

    div {
      flex: 0 0 100%;

      @include mq(sm) {
        flex: 0 0 auto;
      }
    }

    a {
      padding: 1rem 3rem;
      background-color: $white;
      color: $pink-color;
      text-transform: uppercase;
      cursor: pointer;
      border-radius: 12px;
      border: #ba454f solid 1px;
      display: inline-block;
      margin-right: 1rem;

      &:hover {
        background-color: $pink-color;
        color: $white;
      }
    }

    a#redress-notice-proceed-button {
      color: $white;
      background-color: $pink-color;
      margin-top: 1rem;
      border: $pink-color solid 1px;

      @include mq(sm) {
        margin-top: 0;
      }

      &:hover {
        color: $white;
        background-color: #ba454f;
      }
    }
  }
}
