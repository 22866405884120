.resource-hub__pagination {

  ul {
    margin-top: 4rem;
    list-style: none;
    display: flex;
    justify-content: center;
    max-width: unset;

    li {
      flex: 0 0 auto;
      font-size: 22px;
      font-family: $lato;
      font-weight: 700;
      color: #ce3b47;
    
      a:link, a:active, a:hover, a:focus, a:visited {
        color: $almost-black;
        text-decoration: none;
      }
    }

    li::before {
      content: unset;
    }

    li:first-child {
      a {
        font-size: 0;
        position: relative;

        &::after {
          content: "";
          background-image: url('/img/icons/icon-left-chevron-grey.svg');
          background-repeat: no-repeat;
          height: 1.5rem;
          width: 1.5rem;
          position: absolute;
          bottom: -0.25rem;
          right: 0;
        }
      }

      span {
        font-size: 0;
        position: relative;

        &::after {
          content: "";
          background-image: url('/img/icons/icon-left-chevron-grey.svg');
          background-repeat: no-repeat;
          height: 1.5rem;
          width: 1.5rem;
          position: absolute;
          bottom: -0.25rem;
          right: 0;
        }
      }
    }

    li:last-child {
      a {
        font-size: 0;
        position: relative;

        &::after {
          content: "";
          background-image: url('/img/icons/icon-right-chevron-grey.svg');
          background-repeat: no-repeat;
          height: 1.5rem;
          width: 1.5rem;
          position: absolute;
          bottom: -0.25rem;
          left: 0.5rem;
        }
      }

      span {
        font-size: 0;
        position: relative;

        &::after {
          content: "";
          background-image: url('/img/icons/icon-right-chevron-grey.svg');
          background-repeat: no-repeat;
          height: 1.5rem;
          width: 1.5rem;
          position: absolute;
          bottom: -0.25rem;
          left: 0.5rem;
        }
      }
    }


  }
}
