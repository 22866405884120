.awo-resources {
  margin-top: 3rem;

  .awo-resource__wave-top,
  .awo-resource__wave-bottom {
    fill: $awo-old-lace;
    margin-bottom: -1rem;
  }

  .awo-resource__title {
    font-family: $font-awo-title;
    font-size: 36px;
    line-height: 40px;
    color: $awo-onyx;
    max-width: 18rem;
    margin: 0 auto 1.5rem;

    @include mq(md) {
      font-size: 45px;
      margin-bottom: 2.5rem;
      max-width: 100%;
    }
  }

  .awo-resource__wrapper {
    background-color: $awo-old-lace;
    padding-top: 3rem;
    padding-bottom: 4.5rem;

    @include mq(md) {
      padding-bottom: 0.5rem;
    }
  }

  .awo-resource__container--mobile {
    position: relative;

    @include mq(md) {
      display: none;
    }

    .awo-resource__container--mobile-swiper-pagination {
      text-align: center;
      margin-bottom: -3rem;
    }

    .swiper-slide {
      text-align: center;

      .awo-button-wrapper {
        width: 100%;

        a {
          margin-bottom: 0.75rem;
          padding: 0.75rem 2rem;
        }
      }
    }

    .swiper-pagination.swiper-pagination-horizontal.awo-resource__container--mobile-swiper-pagination {
      text-align: center;
      margin-bottom: -3.5rem;

      .swiper-pagination-bullet:not(.swiper-pagination-bullet-active) {
        background-color: $awo-gray-gainsboro;
        opacity: 1;
      }
    }
  }

  .awo-resource__container {
    display: none;
    flex-wrap: wrap;
    justify-content: center;

    @include mq(md) {
      display: flex;
    }
  }

  a {
    margin: 0 0 1.75rem;
    padding: 1rem 2rem;

    @include mq(md) {
      margin: 0 0.6rem 1.75rem;
    }
  }
}
