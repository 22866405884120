.content-page {

  color: $awo-onyx;
  padding-bottom: 50px;

  big {
	font-size: 28px;
	line-height: 1.35;
	letter-spacing: -0.28px;
	font-weight: 700;
	margin-bottom: 40px;
	display: block;
  }

  p {
		font-size: 17px;
		line-height: 27px;
		letter-spacing: 0.15px;
		margin-bottom: 25px;
		max-width: 50rem;
		@include image_formate_style();
  }

  b {
	font-weight: 700;
  }

  a {
	color: $orange;
	text-decoration: underline;
  }


  ul {
	margin-bottom: 40px;
	max-width: 880px;

	li {
	  position: relative;
	  padding-left: 20px;
	  font-size: 18px;
	  line-height: 1.55;

	  &:not(:last-child) {
		margin-bottom: 10px;
	  }

	  &::before {
		content: '';
		position: absolute;
		left: 0;
		top: 15px;
		height: 5px;
		width: 5px;
		background-color: #4d4d4d;
		border-radius: 50%;
	  }
	}
  }

  ol {
	list-style-type: decimal;

	li {
	  display: list-item;
	  position: relative;
	  font-size: 18px;
	  line-height: 1.55;

	  &:not(:last-child) {
		margin-bottom: 10px;
	  }
	}
  }

  li ol li {
	&::before {
	  content: none;
	}
  }

  a.video-preview {
	position: relative;
	display: block;
	width: 100%;
	padding-bottom: 46%;
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;

	&::before {
	  content: '';
	  position: absolute;
	  left: 50%;
	  top: 50%;
	  transform: translate(-50%, -50%);
	  width: 127px;
	  height: 123px;
	  background: url('/img/icon-video-preview.png') no-repeat center;
	  background-size: cover;
	  z-index: 3;
	}

	&::after {
	  content: '';
	  position: absolute;
	  left: 0;
	  top: 0;
	  right: 0;
	  bottom: 0;
	  height: 100%;
	  width: 100%;
	  background: rgba(#000, 0.1);
	  z-index: 2;
	}
  }

  .accordeon {
	height: 61px;
	overflow: hidden;
	margin-bottom: 30px;

	&.active {
	  height: auto;
	  margin-bottom: 0;

	  .accordeon__title .down {
		transform: rotate(-180deg) translate(0, -50%);
		top: 0%;
	  }
	}

	&__title {
	  border-radius: 12px;
	  background-color: $orange-color;
	  padding: 18px 40px;
	  font-size: 25px;
	  font-weight: 700;
	  color: #fff;
	  letter-spacing: -0.25px;
	  position: relative;
	  z-index: 2;
	  cursor: pointer;

	  .down {
		position: absolute;
		font-size: 32px;
		top: 50%;
		right: 40px;
		transform: translate(0, -50%);
	  }
	}

	&__content {
	  background-color: #fff;
	  position: relative;
	  top: -10px;
	  padding: 50px 45px 40px 45px;
	  border-radius: 0 0 20px 20px;
	  font-size: 18px;
	  line-height: 1.35;
	  color: #2a2a2a;
	  font-weight: 700;
	}

	&.active {
	  .accordeon__content {
		background-color: $yellow-color-light;
	  }
	}
  }

  .accordion {
	dt a {
	  display: block;
	  border-radius: 12px;
	  background-color: $orange-color;
	  padding: 18px 100px 18px 40px;
	  margin-bottom: 30px;
	  font-size: 25px;
	  line-height: 32px;
	  font-weight: 700;
	  color: #fff;
	  letter-spacing: -0.25px;
	  text-decoration: none;
	  position: relative;
	  z-index: 2;

	  .down {
		position: absolute;
		font-size: 32px;
		top: 50%;
		right: 40px;
		transform: translate(0, -50%);
	  }
	}

	dd {
	  background-color: $yellow-color-light;
	  position: relative;
	  top: -40px;
	  padding: 50px 45px 40px 45px;
	  border-radius: 0 0 20px 20px;
	  font-size: 18px;
	  line-height: 1.35;
	  color: #2a2a2a;
	  font-weight: 700;
	}
  }

  .document {

	padding: 40px 50px 40px 150px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	background: $yellow-color-light url('/img/icon-document.png') no-repeat left 55px top 43px;
	border-radius: 20px;
	margin-bottom: 35px;

	&__content {
	  width: 100%;
	  padding-right: 100px;
	}

	&__name {
	  margin-bottom: 15px;
	  font-size: 20px;
	  font-weight: 700;
	  letter-spacing: -0.2px;
	}

	&__desc {
	  font-size: 18px;
	  line-height: 1.55;
	  letter-spacing: 0.63px;
	}

	&__link {
	  color: #fff;
	  text-decoration: none;
	  min-width: 170px;
	}

  }

	h2 {
    font-family: $font-awo-title;
    font-size: 36px;
    line-height: 40px;
    color: $awo-onyx;
    text-align: center;

    @include mq(md) {
      font-size: 45px;
      line-height: 55px;
			max-width: 66rem;
    }
  }

}

.main {
  position: relative;
}

.main .top-left-pattern, .main .bottom-right-pattern {
  position: absolute;
  z-index: -999999;
}

.main .top-left-pattern {
  top: 10%;
}

.main .bottom-right-pattern {
  right: 0;
  bottom: 10%;
}

.content-gallery {

  margin-top: 8vw;
  margin-bottom: 8vw;
  background-color: $yellow-color-light;
  position: relative;

  &::before {
	content: '';
	position: absolute;
	top: -8vw;
	left: 0;
	width: 100%;
	height: 8vw;
	background: url('/img/shape-top-yellow.png') no-repeat center;
	background-size: cover;
	z-index: 12;
  }

  &::after {
	content: '';
	position: absolute;
	bottom: -5vw;
	left: 0;
	width: 100%;
	height: 5vw;
	background: url('/img/shape-bot-yellow.png') no-repeat center;
	background-size: cover;
	z-index: 12;
  }

}

.content-gallery__list {
  display: flex;
  flex-wrap: wrap;
}

.content-gallery__item {
  width: calc((100% - 18px) / 4);
  height: 19vw;
  background-position: center;
  background-size: cover;

}
