:root {
  --swiper-navigation-color: #fff;
}

.shopify-product-image {
  width: 100%;
  height: auto;
  object-fit: cover;
  object-position: center;
}

.shopify-product-container {


  .shop-image-container {
    display: block;
  }

  @include mq(lg) {
    display: flex;
  }

  .swiper-product-images {

    @include mq(sm) {
      max-width: 28rem;
    }

    img {
      border-radius: 1rem;
    }
  }

  .product__details-info {
    flex: 0 1 auto;
    width: 100%;
  }
}
