/*  -------------------------------------------------------------
* 	=Helpers - reusable classes 
*	------------------------------------------------------------- */

	.ir {
		background-color: transparent;
		border: 0;
		overflow: hidden;
		*text-indent: -9999px;
	}

	.ir:before {
		content: "";
		display: block;
		height: 150%;
		width: 0;
	}


	.container {
		@include grid-container;
	}

	.row {
		@include grid-row;
	}

	.clearfix {
		@extend %clearfix;
	}

	.hide-on-print {
		@extend %hide-on-print;
	}

	.no-wrap {
		@extend %no-wrap;
	}

	.visuallyhidden {
		@extend %visuallyhidden;
	}

	.fixed-element {
		position: fixed;
	}
	.hidden{
		display: none;
	}

	.training-page-content {
		margin-bottom: 35px;
	}
