.st {

  &-content {
	padding-top: 2rem;
	padding-bottom: 350px;
	background-image: url('#{$images}video-content.png');
	background-repeat: no-repeat;
	background-position: center bottom -200px;
	background-size: 1800px auto;
	position: relative;

	@include mq(sm) {
	  padding-bottom: 570px;
	}

	@include mq(1800px) {
	  background-size: 100% auto;
	}

  }

  &__video {
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
	width: 100%;
	height: auto;
	position: relative;
	padding:2rem 0 1rem;

	.container{
	  display: flex;
	  flex-flow:wrap;
	  > div{
		flex:0 0 100%;
		display: flex;
		justify-content: center;
		flex-flow: column wrap;

		@include mq(md) {
		  flex:0 0 50%;
		}
	  }
	}
  }

  &__img-wrapper {
	position: relative;
	min-height:200px;

	&-mask {
	  position: absolute;
	  z-index: 1;
	  top: -1px;
	  right: 0;
	  width: 100%;
	}

	@include mq(lg) {
	  position: relative;
	}
  }

  &__video-button {
	width: 100px;
	height: 100px;
	position: absolute;
	top: 50%;
	transform: translate(-50%, -50%);
	left: 50%;
	cursor: pointer;
	display: block;
	background: #da5527;
	fill:white;
	border-radius: 99rem;
	padding: 1rem;

	@include mq(sm) {
	  width: 180px;
	  height: 180px;
	}

	&:hover {
	  background: darken(#da5527, 20%);
	}
  }

  &__play-icon {
	width: 100%;
	height: 100%;
	@include play-button($white, $white);
  }

  &__video-content {
	box-sizing: border-box;
	width: 100%;
	height: auto;
	background-color:#da5527;
	border-radius: 40px;
	background-image: url('#{$images}video-content.png');
	background-repeat: no-repeat;
	background-size: contain;
	background-position: center;
	padding: 1rem;
	margin: 1rem auto;

	p{
	  font-size:.8rem;
	  line-height:1.5;
	}

	@include mq(sm) {
	  padding: 3rem 4rem;
	  background-color: transparent;
	  border-radius: 0;
	  width:50%;
	  max-width: 600px;
	  min-height: 400px;
	  margin: 4rem auto 3rem;

	  p{
		font-size:.9rem;
	  }
	}
	@include mq(lg) {
	  min-height: 500px;
	  margin: 6rem auto 5rem;
	  padding: 3rem 3rem;
	  p{
		font-size:1rem;
	  }
	}

  }

  &__video-title {
	color: $white;
	font-size: 1.25rem;
	font-weight: 700;
	letter-spacing: -0.7px;
	margin:1rem 0;

	@include mq(sm) {
	  margin:0 0 1rem;
	  font-size: 1.5rem;
	}
	@include mq(sm) {
	  margin:0 0 1.5rem;
	  font-size: 2rem;
	}
  }

  &__video-desc {
	font-size: 18px;
	font-weight: 300;
	line-height: 28px;
	letter-spacing: 0.6px;
	color: $white;
  }

  &__text {
	margin-top: 1rem;
	background: none;
	padding-bottom: 2rem;

	@include mq(xs) {
	  margin-top: 3rem;
	  padding-bottom: 6rem;
	}

	@include mq(1800px) {
	  background-size: 100% auto;
	}

	&-title {
	  color: #484848;
	  font-weight: 700;
	  font-size: 30px;
	  letter-spacing: -1px;
	  margin-bottom: 0;

	  @include mq(sm) {
		margin-bottom: 35px;
	  }

	  @include mq(lg) {
		font-size: 40px;
		margin-bottom: 56px;
	  }
	}
	p {
	  color: $dark-grey;
	  font-size: 16px;
	  font-weight: 300;
	  line-height: 31px;
	  letter-spacing: 0.63px;
	  &:not(:last-child) {
		margin-bottom: 31px;
	  }

	  @include mq(sm) {
		font-size: 18px;
	  }
	}

	&:last-child{
	  background: none;
	}
  }

  &__text--left {
	&:last-child{
	  background: none;
	}
  }

}


.in-between-text{
  word-break: break-word;
  padding: 2rem 1rem;
  font-size: 1rem;

  @include mq(md) {
	font-size: 1.25rem;
	padding: 3rem 0;
  }

}
