.awo-tac {
    margin-bottom: 12rem;
    position: relative;
    .awo-tac__header {
        align-items: center;
        display: none;
        gap: 4px;
        a {
            color: #404040;
            font-family: $lu;
            font-size: 32px;
            line-height: 28px;
            font-weight: 400;
            background-color: #f7a824;
            border-radius: 10px 10px 0 0;
            height: 72px;
            display: flex;
            align-items: center;
            justify-content: left;
            padding: 0 1rem;
            transform: perspective(1px) translateZ(0);
            transition-duration: 0.3s;
            transition-property: color, background-color, border;
            flex: 1 1 auto;
            position: relative;
            padding: 0 1.5rem;
            &:focus {
                outline: none;
            }
            @include mq(sm) {
                justify-content: center;
                font-size: 24px;
                line-height: 55px;
                padding: 0;
            }
            span {
                position: absolute;
                right: 0;
                top: 0;
                height: 100%;
                display: flex;
                align-items: center;
                margin-right: 24px;
                color: #404040;
                svg {
                    width: 27px;
                    height: 16px;
                }
            }
            span.is-active {
                color: #D85625;
            }
            &:hover {
                background-color: #f7efe1;
            }
        }
        a.is-active {
            background-color: #f7efe1;
        }
        @include mq(sm) {
            display: flex;
        }
    }
    .awo-tac__content {
        background-color: #f7efe1;
        padding-bottom: 3.5rem;
        display: none;
        @include mq(sm) {
            display: block;
            padding-top: 4.75rem;
        }
        .awo-wysiwyg {
            h2 {
                margin: 0;
                text-align: left;
                margin-bottom: 0.75rem;
            }
            h2:not(:first-child) {
                margin-top: 4.25rem;
            }
            p {
                text-align: left;
                //&:last-of-type {
                //    margin-bottom: 0;
                //}
            }
        }
    }
    .awo-tacm__content {
        .awo-wysiwyg {
            h2,
            p {
                text-align: left;
            }
        }
    }
    .awo-tac__svg {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        margin-bottom: 1px;
        height: 1px;
        display: none;
        @include mq(sm) {
            display: block;
        }
    }
    .awo-tacm {
        display: block;
        @include mq(sm) {
            display: none;
        }
        a:not(.awo-button):not(.awo-cc-hww__link) {
            font-family: $lu;
            font-size: 32px;
            line-height: 28px;
            font-weight: 400;
            color: #404040;
            display: block;
            border-radius: 10px;
            background-color: #f7a824;
            padding: 1.5rem;
            position: relative;
            span {
                color: #404040;
                position: absolute;
                right: 0;
                top: 0;
                height: 100%;
                display: flex;
                align-items: center;
                margin-right: 1.5rem;
                svg {
                    width: 27px;
                    height: 16px;
                    transition: all 300ms ease-in-out;
                }
            }
        }
        a:not(.awo-button):not(.awo-cc-hww__link).is-active {
            background-color: #f7efe1;
            border-radius: 10px 10px 0 0;
            span {
                color: #D85625;
                svg {
                    transform-origin: center;
                    transform: rotate(180deg);
                }
            }
        }
        .awo-tacm__content {
            margin-bottom: 1rem;
            max-height: 0;
            transform: scaleY(0);
            opacity: 0;
            transform-origin: top;
            overflow: hidden;
            position: relative;
            .awo-tacm__content-bg {
                background-color: #f7efe1;
                padding-top: 2.5rem;
                padding-bottom: 4rem;
            }
        }
        .awo-tacm__content.is-active {
            max-height: 100%;
            transform: scaleY(100%);
            opacity: 1;
            transition: all 500ms ease-in-out;
        }
        .awo-tacm__svg-wrapper {
            position: relative;
            height: 4rem;
        }
        .awo-tacm__svg {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            margin-bottom: 1px;
            height: 1px;
            svg {
                background-color: #f7efe1;
            }
        }
    }
}
