.document-items-row {
  &.reveal {
    .document-items-row__item {
      opacity: 0;
      transition: opacity 500ms ease;

      @for $i from 0 through 20 {
        &:nth-child(#{$i}) {
          transition-delay: #{0.25 + ($i * 0.07)}s;
        }
      }
    }

    &.revealed {
      .document-items-row__item {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }

  .document-items-row__item {
    background-color: $awo-old-lace;
    border-radius: 0.75rem;
    padding: 2rem 2rem;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    position: relative;
    margin-bottom: 1.5rem;

    @include mq(md) {
      padding: 1.35rem 2.25rem;
      flex-wrap: nowrap;
    }
  }

  .document-items-row__item-icon {
    margin-right: 0.75rem;

    svg {
      width: 100%;
      height: 3rem;
    }

    @include mq(sm) {
      margin-right: 1.5rem;

      svg {
        height: 2.75rem;
      }
    }
  }

  .document-items-row__item-title {
    font-family: $font-awo-body;
    font-weight: 700;
    font-size: 1.0625rem;
    line-height: 1.6875rem;
    color: $awo-gray-davys;
    margin-right: 1rem;
    flex: 1 1 100%;
    margin-top: 0;
    display: flex;
    align-items: center;

    @include mq(md) {
      font-size: 1.25rem;
      line-height: 1.75rem;
      flex: 1 0 auto;
      margin-bottom: 0;
    }
  }

  .document-items-row__item-link {
    margin-right: 1.5rem;
    margin-bottom: 1rem;
    flex: 1 1 100%;

    @include mq(sm) {
      margin-bottom: 0;
      flex: 0 0 auto;
    }
  }

  .document-items-row__item-link > a,
  .documents-items-row__item-attachment > a {
    padding: 1rem 2rem;
  }

  .document-items-row__button {
    text-align: center;
    margin-top: 3.25rem;
  }
}
