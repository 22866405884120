.awo-hcss {
    .awo-bgy__content {
        padding-top: 1.5rem;
        @include mq(sm) {
            padding-top: 0.5rem;
        }
    }
    .awo-hcss__container {
        display: flex;
        align-items: flex-start;
        gap: 2rem;
        flex-wrap: wrap;
        @include mq(md) {
            flex-wrap: nowrap;
            gap: 3rem;
        }
        .awo-hcss__left {
            order: 2;
            @include mq(sm) {
                order: 1;
            }
            .awo-hcss__title {
                color: #404040;
                font-family: $lu;
                font-size: 36px;
                line-height: 40px;
                font-weight: 400;
                @include mq(sm) {
                    font-size: 45px;
                    line-height: 55px;
                }
            }
            .awo-hcss__content {
                color: #2b2b2b;
                font-family: $lato;
                font-size: 1rem;
                line-height: 27px;
                font-weight: 400;
            }
        }
        .awo-hcss__right {
            order: 1;
            flex: 1 1 auto;
            @include mq(md) {
                order: 2;
                flex: 1 0 auto;
            }
            img {
                border-radius: 20px;
                overflow: hidden;
                width: 100%;
                @include mq(md) {
                    max-width: 486px;
                }
            }
        }
    }
}
