.awo-person-details-content {
  .awo-person-details-content__wave-top,
  .awo-person-details-content__wave-bottom {
    fill: $awo-old-lace;
  }
  .awo-person-details-content__wave-top {
    margin-bottom: -0.25rem;
  }
  .awo-person-details-content__wrapper {
    background: $awo-old-lace;
    overflow: hidden;
    .awo-h2 {
      margin-top: 2.75rem;
      margin-bottom: 0.25rem;
    }
    .awo-person-details-content__subtext {
      font-family: $font-awo-body;
      font-weight: 600;
      font-size: 1.25rem;
      line-height: 2.1875rem;
      color: $awo-raisin-black;
      margin-bottom: 1.75rem;
      text-align: center;
      @include mq(md) {
        font-size: 1.75rem;
        line-height: 2.3125rem;
        text-align: left;
      }
    }
    .awo-person-details-content__image {
      background-color: $awo-gray-gainsboro;
      min-height: 32rem;
      border-radius: 1rem;
      overflow: hidden;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      margin-bottom: 3rem;
      position: relative;
      @include mq(md) {
        display: flex;
        border-radius: 2rem;
      }
      &::after {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-color: $awo-black-jet-dark;
        @include mq(md) {
          content: none;
        }
      }
      .awo-person-details-content__image-mobile {
        width: 100%;
        height: auto;
        display: block;
        min-height: 11.5rem;
        object-fit: cover;
        object-position: center;
        z-index: 1;
        position: relative;
        @include mq(md) {
          display: none;
        }
      }
      blockquote {
        font-family: $font-awo-body;
        font-weight: 600;
        color: $awo-white;
        font-size: 1.25rem;
        line-height: 2.1875rem;
        max-width: 30rem;
        padding-left: 0;
        position: relative;
        z-index: 1;
        margin: 1.5rem 1.75rem;
        @include mq(sm) {
          margin: 2rem auto;
        }
        @include mq(md) {
          margin: auto 0;
          margin-left: 4rem;
          font-size: 1.75rem;
          line-height: 3.125rem;
        }
        &::before {
          content: '“  ';
          font-family: $font-awo-title;
          color: $awo-yellow-bright-crayola;
          background: none;
          font-size: 4rem;
          margin-top: 1.75rem;
          @include mq(md) {
            font-size: 5.5rem;
            margin-top: 2.25rem;
          }
        }
        &::after {
          content: '”';
          font-family: $font-awo-title;
          color: $awo-yellow-bright-crayola;
          background: none;
          font-size: 4rem;
          margin-top: 1.25rem;
          margin-left: 0;
          @include mq(md) {
            margin-top: 1.75rem;
            font-size: 5.5rem;
            margin-left: 1.25rem;
          }
        }
      }
    }
    .awo-person-details-content__wysiwyg {
      margin-bottom: 3rem;
      p {
        font-family: $font-awo-body;
        font-weight: 400;
        font-size: 0.9375rem;
		    line-height: 1.5625rem;
        color: $awo-onyx;
        margin-bottom: 1.75rem;
        text-align: center;
        @include mq(md) {
          font-size: 1.0625rem;
          line-height: 1.6875rem;
          text-align: left;
        }
      }
    }
  }
}
