.home-numbers {
    margin-top: 4vw;
    margin-bottom: 7vw;
    background-color: $yellow-color-light;
    position: relative;
    padding-top: 30px;
    padding-bottom: 40px;

    &--camp {
        padding-bottom: 100px;
        margin-bottom: -50px;
        &::after {
            display: none;
        }
    }

    &::before {
        content: '';
        position: absolute;
        top: -4vw;
        left: 0;
        width: 100%;
        height: 4vw;
        background: url('/img/shape-top-yellow2.png') no-repeat center;
        background-size: cover;
        z-index: 12;
    }

    &::after {
        content: '';
        position: absolute;
        bottom: -5vw;
        left: 0;
        width: 100%;
        height: 5vw;
        background: url('/img/shape-bot-yellow2.png') no-repeat center;
        background-size: cover;
        z-index: 12;
    }
}

.home-numbers__list {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}
.home-numbers__item {
    text-align: center;
    flex-basis: 31%;
}
.home-numbers__item-title {
    margin-bottom: 30px;
    color: $orange-color;
    font-family: $lu;
    font-size: 80px;
    font-weight: 400;
    letter-spacing: 3.63px;
}
.home-numbers__item-desc {
    color: $main-color;
    font-size: 28px;
    font-weight: 400;
    line-height: 40px;
    letter-spacing: 1.27px;
}