.contact-locations {
   
    margin-bottom: 60px;
    h3 {
        text-align: left;
    }

}
.contact-locations .container{
  
    background: url('/img/contact/two-birds.png') no-repeat;
    background-size: initial;
    background-position: right 50px bottom 50px;
}
.contact-touch .large-dots-wrapper{
    position: absolute;
    bottom:  -3vw;
    right: 0;
    width: 606px;
    height: 1000px;
    background: url('/img/contact/large-dots-with-pattern.png') no-repeat;
    background-size: initial;
    background-position: right bottom;
}
.contact-locations__box {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.contact-locations__list {
    width: calc( (100% - 50px) / 2 );
}

.contact-locations__item {
    height: 61px;
    overflow: hidden;
    margin-bottom: 10px;
    &.active {
        height: auto;
        margin-bottom: 0;
        .contact-locations__item-title .down {
            transform: rotate(-180deg) translate(0,-50%);
            top: 0%;
        }
    }
}
.contact-locations__item-title {
    border-radius: 12px;
    background-color: $orange-color;
    padding: 18px 40px;
    font-size: 25px;
    font-weight: 700;
    color: #fff;
    letter-spacing: -0.25px;
    position: relative;
    z-index: 2;
    cursor: pointer;
    .down {
        position: absolute;
        font-size: 32px;
        top: 50%;
        right: 40px;
        transform: translate(0,-50%);
    }
}
.contact-locations__item-content {
    background-color: $yellow-color-light;
    position: relative;
    top: -10px;
    padding: 50px 45px 40px 45px;
    border-radius: 0 0 20px 20px;
    .row {
        font-size: 18px;
        line-height: 1.35;
        color: #2a2a2a;
        font-weight: 700;
        padding-left: 47px;
        background-position: left center;
        background-repeat: no-repeat;
        &:not(:last-child) {
            margin-bottom: 30px;
        }
    }
    .row--location {
        background-size: 26px 33px;
        background-image: url('/img/svg/icon-location.svg');
        background-size: 26px 33px;
    }
    .row--address {
        background-size: 33px 32px;
        background-image: url('/img/svg/icon-house.svg');
        background-size: 33px 32px;
    }
    .row--phone {
        background-size: 31px 28px;
        background-image: url('/img/svg/icon-phone.svg');
        background-size: 31px 28px;
        line-height: 2em;
        a{
            color: #2a2a2a;
        }
    }
    .row--fax {
 
        background-image: url('/img/svg/icon-fax.svg');
        background-size: contain;
        line-height: 2em;
    }
    .row--mail {
        background-size: 31px 23px;
        background-image: url('/img/svg/icon-mail.svg');
        background-size: 31px 23px;
        line-height: 2em;
        a{
            color: #2a2a2a;
        }
    }
    .row--link {
        background-size: 31px 23px;
        background-image: url('/img/svg/icon-link.svg');
        background-size: 31px 23px;
        line-height: 2em;
    }
}
.contact-locations__map {
    width: calc( (100% - 50px) / 2 );
}

.contact-locations__map-wrapper {
    width: 100%;
    padding-bottom: 100%;
    position: relative;
    background-color: orange;
}

.contact-touch {

    margin-top: 10vw;
    background-color: $yellow-color-light;
    position: relative;
    margin-bottom: clamp(30px, 4vw, 80px);
    padding-bottom: 6vw;

    h3 {
        text-align: left;
    }

    /* &::before {
        content: '';
        position: absolute;
        top: -8vw;
        left: 0;
        width: 100%;
        height: 8vw;
        background: url('/img/shape-top-yellow.png') no-repeat center;
        background-size: cover;
        z-index: 12;
    } */
    > .container {
        padding-bottom: clamp(120px, 12vw, 260px);
    }
}

.contact-form {

    .input-group {
        width: 100%;
        max-width: 556px;
        margin-bottom: 30px;
        label {
            margin-bottom: 10px;
            font-weight: 700;
            font-family: $lato;
            color: #413d4b;
            font-size: 20px;
            letter-spacing: 1px;
            display: block;
        }
    }

    input[type=text], input[type=email], textarea {
        width: 100%;
        background: #fff;
        border: 0;
        height: 52px;
        border-radius: 12px;
        padding: 0 20px;
        font-size: 16px;
        font-family: $lato;
    }

    textarea {
        resize: none;
        height: 175px;
        padding-top: 10px;
        padding-bottom: 10px;
    }

}

.contact-touch__desc {
    font-size: 16px;
    color: #413d4b;
    letter-spacing: 0.8px;
    font-weight: 700;
    margin-bottom: 40px;
}