.foster-care-wave-text {
  .foster-care-wave-text__video {
    margin-top: 4.5rem;
  }

  .foster-care-wave-text__links {
    margin-top: 5.5rem;
  }
}

.foster-care-wave-text__video.margin-bottom-4rem {
  margin-bottom: 4rem;
}

.foster-care-form {
  margin-bottom: 3.5rem;

  .captureform2 {
    display: grid;
    grid-template-columns: 1fr;
    column-gap: 2rem;

    @include mq(sm) {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  .grid-field-group {
    display: grid;
    grid-template-columns: 1fr;
    column-gap: 1.5rem;

    @include mq(sm) {
      grid-template-columns: 1fr 1fr;
    }
  }

  .grid-dob-field {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 0.5rem;
    width: 100%;

    select {
      margin-left: 0 !important;
      width: 100% !important;
    }
  }

  .unabale-to-service-foster-care {
    margin-top: 2.75rem;
    max-width: 100%;

    @include mq(sm) {
      grid-column: 1/3;
    }
  }

  .hidden_field_contain {
    display: none;
  }
}
