
:root {
  --navigation-min-height: 23rem;
  --navigation-bottom-min-height: 18rem;
  --cubic-bezier-menu-items: cubic-bezier(0.4, 0.01, 0.165, 0.99);
  --ease-in-out: cubic-bezier(0.4, 0, 0.2, 1);
}

.awo-header__top-navigation--overlay-transparent {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  transition: transform, opacity;
  transition-delay: 400ms, 75ms;
  transition-duration: 0.5s;
  transition-timing-function: var(--ease-in-out);
  opacity: 0;
  transform-origin: top;
  transform: scaleY(0);
  display: none;

  @media screen and (min-width: $top-nav-max-width){
    display: block;
  }

  &.active {
    background-color: $awo-black;
    opacity: 0.7;
    transition-property: opacity;
    transition-duration: 0.5s;
    transition-timing-function: var(--ease-in-out);
    transition-delay: 0.2s;
    transform: scaleY(1);
  }
}

.awo-header__top-navigation--overlay {
  background-color: $awo-black-jet-dark;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  min-height: var(--navigation-min-height);
  z-index: 4;
  transform-origin: top;
  transform: scaleY(0);
  transition: all 300ms var(--ease-in-out);
  display: none;

  @media screen and (min-width: $top-nav-max-width){
    display: block;
  }

  &.active {
    transform-origin: top;
    transform: scaleY(1);
    transition: all 500ms var(--ease-in-out);
  }
}

.awo-header__bottom-navigation--overlay {
  background-color: $awo-white;
  position: fixed;
  left: 0;
  top: 0;
  min-height: var(--navigation-bottom-min-height);
  width: 100%;
  z-index: 3;
  margin-top: 8.45rem;
  -webkit-box-shadow: 0 15px 30px 0 rgba(0,0,0,.11),0 5px 15px 0 rgba(0,0,0,.08);
  box-shadow: 0 15px 30px 0 rgba(0,0,0,.11),0 5px 15px 0 rgba(0,0,0,.08);
  transform-origin: top;
  transform: scaleY(0);
  transition: all 300ms var(--ease-in-out);
  display: none;

  @media screen and (min-width: $top-nav-max-width){
    display: block;
  }

  &.active {
    transform-origin: top;
    transform: scaleY(1);
    transition: all 500ms var(--ease-in-out);
  }
}

.awo-header__top--mobile-button {
  position: absolute;
  top: 0;
  right: 0;
  height: 5rem;
  width: 3.75rem;
  z-index: 20;
  display: flex;

  button.hamburger {
    margin: auto 0;
  }

  @media screen and (min-width: $top-nav-max-width) {
    display: none;
  }
}

.awo-header {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5;

  &.is-fixed {
    position: fixed;
    transform: translate3d(0, -250%, 0);
    transition: transform 0.5s;
    opacity: 0;
  }

  &.is-visible {
    transform: translate3d(0, 0, 0);
    opacity: 100%;
  }

  .awo-header__top {
    background-color: $awo-black-jet-dark;
    padding: 0;
    position: relative;

    @media screen and (min-width: $top-nav-max-width) {
      padding: 1.1rem 0;
    }

    .awo-header__top-container {
      display: flex;
      position: relative;
      align-items: center;
    }

    &.mobile-menu-active {
      .awo-header__top-container__buttons {
        transform: scaleY(1);
        opacity: 1;
        transition: transform 1ms var(--ease-in-out), opacity 300ms var(--ease-in-out) 5ms;
      }
    }

    .awo-header__top-container__buttons {
      background-color: $awo-black-jet-dark;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      min-height: 5rem;
      z-index: 5;
      transform-origin: top;
      transform: scaleY(0);
      opacity: 0;
      display: flex;

      @media screen and (min-width: $top-nav-max-width) {
        display: none;
      }

      .awo-mobile-menu__top-buttons--cart {
        display: flex;
        border-right: 1px solid $awo-black-jet-alt;
        min-width: 5.25rem;
        flex: 0 0 auto;

        a {
          color: $awo-white;
          position: relative;
          display: block;
          margin: auto auto;

          span {
            background-color: $awo-yellow-bright-crayola;
            color: $awo-black-jet-dark;
            font-family: $font-awo-body;
            font-weight: 700;
            font-size: 0.625rem;
            line-height: 0.6875rem;
            border-radius: 100%;
            position: absolute;
            right: 0;
            top: 0;
            width: 1rem;
            height: 1rem;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: -0.5rem;
            margin-top: -0.25rem;
          }

          svg {
            width: 1.7rem;
            height: 1.7rem;
          }
        }
      }

      .awo-mobile-menu__top-buttons--search {
        flex: 1 1 auto;

        a {
          display: flex;
          justify-content: center;
          align-items: center;
          max-width: 5.25rem;
          border-right: 1px solid $awo-black-jet-alt;
          height: 100%;

          svg {
            fill: $awo-gray-davys;
            width: 1.5rem;
            height: 1.5rem;
          }
        }

        form {
          height: 100%;
          display: flex;
          align-items: center;
          padding: 0 1rem;

          input {
            color: $awo-white;
            padding-left: 0;
            background-color: transparent;
            border-bottom: 1px solid $awo-gray-spanish;
            border-radius: 0;

            &::placeholder {
              opacity: 1;
              color: $awo-white;
            }
          }
        }
      }

      .awo-mobile-menu__top-buttons--close {
        flex: 0 0 auto;
        background-color: $awo-white;
        min-width: 4.5rem;
      }
    }

    .awo-header__top-donate {
      position: absolute;
      right: 0;
      top: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-right: 2rem;
      height: 100%;

      @media screen and (max-width: 1510px){
        display: none;
      }

      .awo-header__top-donate-button {
        font-family: $lato;
        font-weight: bold;
        font-size: 13px;
        line-height: 15px;
        text-transform: uppercase;
        display: inline-block;
        width: auto;
        padding: 0.65rem 1.25rem;
        border-radius: 0.6rem;
        background-color: $awo-flame;
        border-color: $awo-flame;
        color: $white;
        z-index: 5;

        &:hover {
          background-color: $awo-orange-peel;
          color: $white;
          border-color: $awo-orange-peel;
        }
      }
    }

    .awo-header__top-logo {
      width: 11.75rem;
      background-color: $awo-black-jet-dark;

      .awo-logo {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        justify-content: center;
        background-color: $awo-black-jet-dark;
        width: auto;
        margin-left: 0.75rem;
        z-index: 4;
        padding: 1rem;
        margin-top: -1.5rem;

        @media screen and (min-width: $top-nav-max-width) {
          width: 11.75rem;
          padding: 0;
          margin-top: 0;
        }

        .awo-logo__is-home {
          display: none;

          @media screen and (min-width: $top-nav-max-width) {
            display: block;
          }

          svg {
            width: 8rem;
            height: 12.25rem;
          }
        }

        .awo-logo__is-home-mobile {
          @media screen and (min-width: $top-nav-max-width) {
            display: none;
          }

          svg {
            width: 5.5rem;
            height: 6.5rem;
          }
        }

        .awo-logo__is-other-page {
          display: none;

          @media screen and (min-width: $top-nav-max-width) {
            display: block;
          }

          svg {
            width: 5.5rem;
            height: 6.5rem;
          }
        }

        &.is-home {
          margin-top: 0;
          min-width: 9.75rem;
          margin-left: 1.25rem;
          padding-bottom: 1.2rem;

          @media screen and (min-width: $top-nav-max-width) {
            margin-left: 2.25rem;
          }

          @media screen and (min-width: $top-nav-max-width) {
            margin-top: 0.75rem;
            min-width: auto;
            margin-left: 0.75rem;
            padding-bottom: 1rem;
          }

          img.is-home {
            display: none;

            @media screen and (min-width: $top-nav-max-width) {
              display: block;
              margin: 0 auto;
            }
          }

          img.is-home-mobile {
            @media screen and (min-width: $top-nav-max-width) {
              display: none;
            }
          }
        }

        &.is-other-page {
          padding: 0;
          margin-top: 0.85rem;
          margin-left: 1.25rem;

          @media screen and (min-width: $top-nav-max-width) {
            padding: 1rem;
            margin-top: -1.25rem;
            margin-left: 0.75rem;
          }

          img.is-other-page {
            display: none;

            @media screen and (min-width: $top-nav-max-width) {
              display: block;
              margin: 0 auto;
            }
          }

          .is-other-page-mobile {
            svg {
              fill: $awo-white;
              width: 10.5rem;
            }

            @media screen and (min-width: $top-nav-max-width) {
              display: none;
            }
          }
        }

        a {
          width: 100%;
          text-align: center;
        }
      }
    }

    .awo-header__top-navigation {
      margin-left: 4.75rem;
      z-index: 5;
      position: relative;
      display: none;

      @media screen and (min-width: $top-nav-max-width) {
        display: block;
      }

      ul {
        display: flex;

        li {
          margin-right: 2rem;

          &.active,
          &.page-active,
          &:hover {
            color: $awo-orange-peel;

            a {
              &::after {
                content: "";
                height: 2px;
                width: 100%;
                background-color: $awo-orange-peel;
                position: absolute;
                bottom: 0;
                left: 0;
                display: flex;
                border-right: 6px solid $awo-black-jet-dark;
                border-left: 6px solid $awo-black-jet-dark;
                margin-bottom: -0.5rem;
                z-index: 2;
              }
            }
          }

          a {
            font-size: 14px;
            line-height: 12px;
            font-family: $lato;
            font-weight: normal;
            color: $white;
            text-transform: uppercase;
            position: relative;
          }

          &.active {
           .awo-header__top-navigation--children {
              transition: transform 500ms var(--ease-in-out);
              transform-origin: top;
              transform: scaleY(1);

              ul {
                background-color: transparent;

                li {
                  transform: scale(1) translateY(0);
                  opacity: 1;

                  @for $i from 0 through 10 {
                    &:nth-child(#{$i}) {
                      transition-delay: #{0.25 + ($i * 0.07)}s;
                    }
                  }
                }
              }
           }
          }

          .awo-header__top-navigation--children {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            background-color: $awo-flame;
            z-index: 1;
            padding-top: 1.75rem;
            margin-top: 1rem;
            background-color: $awo-black-jet-dark;
            min-height: auto;
            min-width: 40rem;

            transform-origin: top;
            transform: scaleY(0);

            ul {
              display: block;
              column-count: 2;
              max-width: 36rem;
              column-gap: 1rem;
              padding-top: 3rem;
              padding-bottom: 2rem;

              li {
                margin-bottom: 1.25rem;

                transform: translateY(-30px);
                opacity: 0;
                transition: transform 0.5s var(--cubic-bezier-menu-items), opacity 0.6s var(--cubic-bezier-menu-items);
                will-change: transform;

                a {
                  text-transform: none;

                  &:hover {
                    color: $awo-orange-peel;
                  }

                  &::after {
                    content: none;
                    display: none;
                  }
                }
              }
            }
          }
        }
      }
    }

    .awo-header__top-icons {
      display: none;
      align-items: center;
      z-index: 5;
      position: relative;

      @media screen and (min-width: $top-nav-max-width) {
        display: flex;
      }

      .awo-header__top-icons--cart {
        position: relative;
        display: block;

        &:hover {
          svg {
            fill: $awo-orange-peel;
          }
        }

        svg {
          fill: $white;
          width: 1.5rem;
          height: 1.5rem;
        }

        span {
          position: absolute;
          top: 0;
          right: 0;
          border-radius: 100%;
          background-color: $awo-yellow-bright-crayola;
          color: $black;
          font-family: $lato;
          font-weight: bold;
          font-size: 10px;
          width: 1rem;
          height: 1rem;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: -0.75rem;
          margin-top: -0.25rem;
        }
      }

      .awo-header__top-icons--search {
        cursor: pointer;

        &:hover {
          svg {
            fill: $awo-orange-peel;
          }
        }

        svg {
          fill: $white;
          width: 1.5rem;
          height: 1.5rem;
        }
      }
    }

    .awo-header__top-buttons {
      flex: 1 1 auto;
      text-align: right;
      z-index: 5;
      position: relative;
      display: none;
      margin-right: -0.4rem;

      @media screen and (min-width: $top-nav-max-width) {
        display: block;
      }

      @media screen and (min-width: 1510px) {
        max-width: 30rem;
      }

      .awo-header__top-buttons--exit,
      .awo-header__top-buttons--help {
        font-family: $lato;
        font-weight: bold;
        font-size: 13px;
        line-height: 15px;
        text-transform: uppercase;
        display: inline-block;
        width: auto;
        padding: 0.65rem 1.25rem;
        border-radius: 0.6rem;

        &:hover {
          background-color: $awo-orange-peel;
          color: $white;
          border-color: $awo-orange-peel;
        }
      }

      .awo-header__top-buttons--exit {
        background-color: $awo-straw;
        border: 1px solid $awo-straw;
        color: $awo-black-jet;
        margin-right: 1.25rem;
        z-index: 5;
      }

      .awo-header__top-buttons--help {
        border: 1px solid $awo-gray-platinum;
        color: $awo-gray-light;
        z-index: 5;
      }

      .awo-header__top-buttons--donate {
        font-family: $lato;
        font-weight: bold;
        font-size: 13px;
        line-height: 15px;
        text-transform: uppercase;
        display: inline-block;
        width: auto;
        padding: 0.65rem 1.25rem;
        border-radius: 0.6rem;
        background-color: $awo-flame;
        border-color: $awo-flame;
        color: $white;
        z-index: 2;
        margin-left: 1.25rem;

        @media screen and (min-width: 1510px) {
          display: none;
        }

        &:hover {
          background-color: $awo-orange-peel;
          color: $white;
          border-color: $awo-orange-peel;
        }
      }
    }
  }

  .awo-header__bottom {
    background-color: $awo-black-jet-dark;
    padding: 1.45rem 0;
    z-index: 3;
    position: relative;
    min-height: 5rem;

    @media screen and (min-width: $top-nav-max-width) {
      min-height: auto;
    }

    &:not(.active) {
      -webkit-box-shadow: 0 15px 30px 0 rgba(0,0,0,.11),0 5px 15px 0 rgba(0,0,0,.08);
      box-shadow: 0 15px 30px 0 rgba(0,0,0,.11),0 5px 15px 0 rgba(0,0,0,.08);
    }


    @media screen and (min-width: $top-nav-max-width) {
      background-color: $white;
    }

    .awo-header__bottom-container {
      display: flex;

      .awo-header__bottom-logo {
        width: 11.80rem;
        background-color: $awo-gray-platinum;
        position: relative;

        &::after {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          display: none;
          height: 100%;
          background-color: $awo-black-jet-dark;
          margin-top: -1.45rem;
          margin-left: 0.1rem;
          min-height: 3.9rem;

          @media screen and (min-width: $top-nav-max-width) {
            display: flex;
          }
        }

        &.is-home {
          &::after {
            min-height: 11rem;
            z-index: 3;
          }
        }
      }

      .awo-header__bottom-navigation {
        margin-left: 4.75rem;
        display: none;
        position: relative;

        @media screen and (min-width: $top-nav-max-width) {
          display: block;
        }

        ul {
          display: flex;
          align-items: center;
          width: 100%;

          li {
            flex: 1 1 auto;
            margin-right: 2rem;

            &.active,
            &.page-active,
            &:hover {
              color: $awo-orange-peel;

              a {
                position: relative;

                &::after {
                  content: "";
                  height: 2px;
                  width: 100%;
                  background-color: $awo-orange-peel;
                  position: absolute;
                  bottom: 0;
                  left: 0;
                  display: flex;
                  border-right: 6px solid $white;
                  border-left: 6px solid $white;
                  margin-bottom: -0.5rem;
                  z-index: 3;
                }
              }
            }

            a {
              color: $awo-black-jet-dark;
              font-family: $lato;
              font-weight: bold;
              font-size: 17px;
              line-height: 12px;
              text-transform: uppercase;
            }

            &.active {
              .awo-header__bottom-navigation--children {
                transition: transform 500ms var(--ease-in-out);
                transform-origin: top;
                transform: scaleY(1);
                margin-top: 1rem;

                ul {
                  li {
                    transform: scale(1) translateY(0);
                    opacity: 1;

                    @for $i from 0 through 15 {
                      &:nth-child(#{$i}) {
                        transition-delay: #{0.25 + ($i * 0.07)}s;
                      }
                    }
                  }
                }
              }
            }

            .awo-header__bottom-navigation--children {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              z-index: 2;
              padding-top: 1.75rem;
              margin-top: 1rem;
              background-color: $white;
              min-height: auto;
              min-width: 44rem;
              margin-top: 1rem;

              transform-origin: top;
              transform: scaleY(0);

              ul {
                display: block;
                column-count: 2;
                max-width: 45rem;
                column-gap: 1rem;
                padding-top: 1rem;
                padding-bottom: 2rem;
                background-color: transparent;

                li {
                  margin-bottom: 1rem;

                  transform: translateY(-30px);
                  opacity: 0;
                  transition: transform 0.5s var(--cubic-bezier-menu-items), opacity 0.6s var(--cubic-bezier-menu-items);
                  will-change: transform;

                  a {
                    font-size: 0.9375rem;
                    line-height: 1.375rem;
                    font-family: $lato;
                    font-weight: normal;
                    color: $awo-black-jet-dark;
                    text-transform: none;

                    &:hover {
                      color: $awo-orange-peel;
                    }

                    &::after {
                      content: none;
                      display: none;
                    }
                  }
                }
              }
            }
          }

          li:last-child {
            margin-right: 0;
          }
        }

        li.awo-header__bottom-navigation-cart {
          position: relative;

          &:hover {
            svg {
              fill: $awo-orange-peel;
            }
          }

          a.awo-header__top-icons--cart {
            position: absolute;
            top: 0;
            right: 0;
            display: inline-block;
            margin-top: -0.75rem;
            margin-right: -1.25rem;

            &::after {
              display: none;
            }

            span {
              position: absolute;
              top: 0;
              right: 0;
              background-color: $awo-sunglow;
              width: 1rem;
              height: 1rem;
              border-radius: 100%;
              font-size: 10px;
              display: flex;
              align-items: center;
              justify-content: center;
              margin-right: -0.7rem;
              margin-top: -0.5rem;
            }

            svg {
              width: 1.45rem;
              height: 1.45rem;
            }
          }
        }
      }
    }
  }
}

.awo-header__quick-links {
  display: flex;

  @media screen and (min-width: $top-nav-max-width) {
    display: none;
  }

  &.always-on {
    position: fixed;
    z-index: 10;
    width: 100%;
    top: 0;
  }

  a {
    font-family: $font-awo-body;
    font-weight: 700;
    font-size: 0.875rem;
    line-height: 0.9375rem;
    color: $awo-white;
    text-transform: uppercase;
    flex: 1 1 50%;
    text-align: center;
    padding: 1.1rem 0;
    background-color: $awo-flame;
  }

  a:nth-child(2) {
    background-color: $awo-black-jet-dark;
    color: $awo-gray-light;
  }

  a:nth-child(3) {
    background-color: $awo-straw;
    color: $awo-black-jet-dark;
    flex: 0 1 auto;
    width: 4rem;
    position: relative;

    .awo-header__quick-links-exit-wrapper {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        width: 1.25rem;
        height: 1.25rem;
      }
    }
  }
}
