.container.resource-hub {

  display: flex;
  flex-wrap: wrap;

  .resource-hub__item {
    flex: 1 0 100%;
    margin: 1rem 0.8%;
    border-radius: 20px 20px 20px 20px;
    overflow: hidden;
    background-color: #ce3b47;

    @include mq(sm) {
      flex: 0 1 48%;
    }

    @include mq(md) {
      flex: 0 1 23%;
    }

    &__image {
      background-repeat: no-repeat;
      background-position: top center;
      background-size: cover;
      height: 15rem;
      z-index: 1;
      position: relative;

      .wavy-effect {
        bottom: 0;
        position: absolute;
        background-image: url('/img/wavy-effect.png');
        background-size: cover;
        background-repeat: no-repeat;
        width: 100%;
        height: 0.8rem;
        z-index: 2;
      }
    }

    &__title {
      font-family: $lato;
      font-weight: 700;
      font-size: 18px;
      color: $white;
      padding: 1rem;
      min-height: 6rem;
      max-height: 6rem;
    }

    &__button {
      padding: 0 1rem 1rem;
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;

      span {
        display: inline-block;
        background-color: $almost-black;
        text-align: center;
        text-transform: uppercase;
        border: 0;
        border-radius: 12px;
        font-family: $lato;
        font-size: 14px;
        font-size: 400;
        color: $white;
        padding: 0.5rem;
        width: auto;
      }
    }


  }

  a.resource-hub__item {
    
    &:link, &:active, &:visited, &:focus, &:hover {
      text-decoration: none;
      color: $white;
    }
  }
  
}