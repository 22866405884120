.awo-wwb {
    margin-bottom: 3.25rem;
    padding-top: 4rem;
    @include mq(lg) {
        padding-top: 0;
        margin-bottom: 5rem;
    }
    .awo-wwb__container {
        align-items: flex-start;
        display: flex;
        flex-wrap: wrap;
        gap: 4rem;
        justify-content: space-between;
        margin-bottom: 10px;
        @include mq(md) {
            flex-wrap: nowrap;
            gap: 4rem;
        }
        .awo-wwb__left {
            flex: 1 1 auto;
            order: 2;
            @include mq(md) {
                order: 1;
            }
        }
        .awo-wwb__right {
            flex: 1;
            order: 1;
            @include mq(md) {
                order: 2;
                flex: 0 0 auto;
            }
        }
        .awo-wwb__content {
            h2 {
                color: #404040;
                font-family: $lu;
                font-size: 36px;
                line-height: 40px;
                font-weight: 400;
                max-width: 644px;
                margin-bottom: 1rem;
                margin-top: 0;
                @include mq(lg) {
                    font-size: 45px;
                    line-height: 55px;
                    margin-bottom: 0.5rem;
                }
            }
            p {
                color: #2b2b2b;
                font-family: $lato;
                font-size: 16px;
                font-weight: 400;
                line-height: 27px;
                letter-spacing: 0.15px;
                max-width: 740px;
                margin-bottom: 2rem;
            }
        }
        .awo-wwb__discover {
            background: #8d538d;
            border-radius: 10px;
            padding: 24px 32px 40px;
            text-align: center;
            .awo-wwb__discover-title {
                font-family: $lu;
                font-size: 35px;
                line-height: 43px;
                font-weight: 400;
                text-align: center;
                color: #fff;
                display: block;
                max-width: 14rem;
                margin: 0 auto 0.5rem;
            }
            .awo-wwb__discover-content{
                font-family: $lato;
                text-align: center;
                font-size: 18px;
                font-weight: 400;
                line-height: 26px;
                color: #fff;
                max-width: 14rem;
                margin: 0 auto 1.5rem;
            }
            .awo-wwb__discover-button-wrapper {
                display: inline-block;
                width: 100%;
                @include mq(sm) {
                    width: auto;
                }
                .awo-wwb__discover-button {
                    background: #fff;
                    border-radius: 10px;
                    color: #2b2b2b;
                    text-align: center;
                    font-family: $lato;
                    font-size: 14px;
                    font-weight: 700;
                    line-height: 9px;
                    text-transform: uppercase;
                    padding: 1rem 1.375rem;
                    display: flex;
                    align-items: center;
                    min-height: 48px;
                    justify-content: center;
                    min-width: 11.25rem;
                    transform: perspective(1px) translateZ(0);
                    transition-duration: 0.3s;
                    transition-property: color, background-color, border;
                    &:hover {
                        background: #2b2b2b;
                        color: #fff;
                    }
                }
            }
        }
    }
    .awo-wwb__buttons {
        display: flex;
        align-items: center;
        gap: 1.5rem;
        flex-wrap: wrap;
        @include mq(sm) {
            flex-wrap: nowrap;
        }
        .awo-wwb__button {
            color: #2b2b2b;
            text-align: center;
            font-family: $lato;
            font-size: 14px;
            line-height: 9px;
            font-weight: 700;
            text-transform: uppercase;
            border-radius: 10px;
            background: #f7a824;
            padding: 0.25rem 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 0.75rem;
            min-height: 48px;
            transform: perspective(1px) translateZ(0);
            transition-duration: 0.3s;
            transition-property: color, background-color, border;
            width: 100%;
            @include mq(lg) {
                width: auto;
                padding: 0.25rem 2.875rem;
            }
            svg {
                width: 31px;
                height: 31px;
            }
            &:hover {
                background-color: #2b2b2b;
                color: #fff;
            }
        }
        .awo-wwb__button-secondary {
            background: #2b2b2b;
            color: #fff;
            &:hover {
                background: #f7a824;
                color: #2b2b2b;
            }
        }
    }
}
