.events {
    padding-bottom: 30px;
    padding-top: 10px;
    position: relative;
    .container{
        position: relative;
    }
    &-left-bird{
        position: absolute;
        top: 10px;
        left: -232px;
        z-index: -999999;
    }
    &-dots{
        position: absolute;
        top: 1150px;
        right: 0;
        z-index: -999999;
    }
}

.events-filters {
    border-radius: 18px;
    padding: 40px 50px;
    margin-bottom: 35px;
    background: url('/img/news/news-dots-in-filter.png') $yellow-color-light no-repeat;
    background-position: right 30px center;

}

.news-events-large {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
}

.events-large__item {
    display: flex;
    background: $orange-color;
    border-top-right-radius: 18px;
    border-bottom-right-radius: 18px;
    overflow: hidden;
    &:not(:last-child) {
        margin-bottom: 40px;
    }
    &:hover {
        // background-color: #2b2b2b;
        // .events-large__item-content {
        //     background-color: #2b2b2b;
        // }
    }
}
.events-large__item-content {
    flex-basis: 60%;
    padding: 30px 35px;
    background: $orange-color url('/img/news/item-icon.png') no-repeat right center;
    background-size: auto 100%;
}

.events-large__item-date, .events-large__item-location,  {
    margin-bottom: 5px;
    font-size: 18px;
    font-weight: 400;
    color: white;
    letter-spacing: 0.09px;
    font-weight: bold;
}

.events-large__item-info {
    
     &::after {
        content: '';
        display: block;
        width: 480px;
        height: 6px;
        background: url('/img/news/icon-news-title.png') no-repeat center;
        background-size: cover;
        margin-top: 20px;
        margin-bottom: 20px;
    }
}
.events-large__item-title {
    font-size: 26px;
    font-weight: 700;
    line-height: 1.25;
    color: #ffffff;
    letter-spacing: 0.13px;
    position: relative;
    margin-bottom: 15px;
    
}
.events-large__item-desc {
    font-size: 17px;
    font-weight: 400;
    line-height: 28px;
    color: #ffffff;
    letter-spacing: 0.59px;
    margin-bottom:20px;
}
.events-large__item-pic, .events-large__item-pic-mobile  {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    flex-basis: 40%;
}
.events-large__item-pic-mobile {
    display: none;
    height: 400px;
}

.events-small {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 30px;
    .events-small__item{
        &:nth-of-type(3n), &:last-child{
            margin-right: 0px;
        }

    } 

}

.events-small__item {
    background-color: $orange-color;
    border-bottom-right-radius: 18px;
    border-bottom-left-radius: 18px;
    width: calc((100%/3) - 15px);
    margin-right: 22px;
    &:hover {
        background-color: #2b2b2b;
    }
    z-index: 2;


}
.events-small__item-pic {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 280px;
}
.events-small__item-title {
    padding: 20px 30px 50px 30px;
    font-size: 26px;
    font-weight: 700;
    color: #fff;
    line-height: 1.25;
}

.events__button {
    text-align: center;
    margin-top: 35px;
    a {
        margin: 0 auto;
    }

}
.chunk-hidden{
    display: none;
}
