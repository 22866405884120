.reveal {
  opacity: 0;
  transform: translateY(15px);
  transition: opacity 0.65s linear, transform 0.65s ease;
  transition-delay: 0.15s;

  &.revealed {
    opacity: 1;
    transform: translateY(0);
  }
}

.reveal-fade {
  opacity: 0;
  transition: opacity 0.65s linear, transform 0.65s ease;
  transition-delay: 0.15s;

  &.revealed-fade {
    opacity: 1;
  }
}
