
.megamenu__overlay {
	z-index: 100;
}

.header {
	background: #2b2b2b;
	padding: 0;
	z-index: 3000;

	.logo-container {
		@include vertical-center;
		left: 0;
		padding: 0 20px;
		position: absolute;
		z-index: 10;

		.header__logo {
			display: block;
		}
	}

	> .container {
		max-width: none;
	}
}

.atm {
	height: 525px;
	padding-bottom: 0;

	> .logo-container {
		@include deadCenter;
		display: none;
		max-width: 1240px;
		padding: 40px 20px;
		position: absolute;
		width: 100%;
		z-index: 10;

		.header__logo {
			display: block;
		}
	}

	.container {
		position: relative;
		z-index: 15;

		> .hight-light {
			width: 100%;

			h1 {
				font-size: 125px;
			}
		}
	}

	.slick-slide {
		position: relative;

		> div {
			> div {
				&:after {
					background: rgba(0, 0, 0, 0.2);
					content: " ";
					height: 100%;
					left: 0;
					position: absolute;
					top: 0;
					width: 100%;
					z-index: 1;
				}

				> .container {
					position: relative;
					z-index: 10;
				}
			}
		}
	}
}


.home-what {
	.home-what__list {
		margin: 0 -10px 55px -10px;
		flex-wrap: wrap;
		justify-content: flex-start;

		.home-what__item {
			background: none;
			flex: 0 0 25%;
			padding: 0 10px 20px;
			width: auto;

			&:hover {
				background: none;
			}

			&:nth-child(1) {
				> a {
					background: #f7a824;

					&:hover {
						background: #f69e0b;
					}
				}
			}
			&:nth-child(2) {
				> a {
					background: $awo-indian-red;

					&:hover {
						background: #ce3b47;
					}
				}
			}
			&:nth-child(3) {
				> a {
					background: #c69c6d;

					&:hover {
						background: #b38d62;
					}
				}
			}
			&:nth-child(4) {
				> a {
					background: #da5527;

					&:hover {
						background: #c64c22;
					}
				}
			}

			> a {
				@include transition(background-color, 0.3s);
				border-radius: 20px;
				display: block;
				height: 100%;
				padding: 35px 10px 125px 10px;
				width: 100%;

				.home-what__item-pic {
					bottom: 45px;
					height: 64px;
					top: auto;

					> img {
						height: 100%;
						width: auto !important;
					}
				}
			}
		}
	}

	.home-what__title {
		margin-bottom: 21px;
	}

	.home-what__desc {
		p {
			line-height: 1.6em;
		}
	}
}


.home-why {
	padding-bottom: 90px;
	padding-top: 30px;

	.home-why__list {
		margin: 0 -10px 0 -10px;

		.home-why__item {
			flex: 1;
			margin-right: 0;
			padding: 15px;

			> .inner {
			}

			.home-why__item-pic {
				> img {
					height: 100%;
				}
			}

			.home-why__item-title {
				font-size: 19px;
			}
		}
	}
}



.home-slides {
    margin-top: 4vw;
    margin-bottom: 8vw;
    background-color: #f7efe1;
    position: relative;
    z-index:2;

	> .container {
		padding-bottom: 20px;
	}

    &::before {
        content: '';
        position: absolute;
        top: -8vw;
        left: 0;
        width: 100%;
        height: 8vw;
        background: url('/img/shape-top-yellow.png') no-repeat center;
        background-size: cover;
        z-index: 2;
    }

    &::after {
        content: '';
        position: absolute;
        bottom: -5vw;
        left: 0;
        width: 100%;
        height: 5vw;
        background: url('/img/shape-bot-yellow.png') no-repeat center;
        background-size: cover;
        z-index: 12;
    }

	.home-slides__list {
		background: #75726c;
		border-radius: 20px;
		height: 450px;
		overflow: hidden;
		position: relative;
		width: 100%;

		.home-slides__item {
			@include transition(opacity, 0.3s);
			background-position: center;
			background-size: cover;
			background-repeat: no-repeat;
			height: 100%;
			left: 0;
			opacity: 0;
			position: absolute;
			top: 0;
			width: 100%;
			z-index: 1;

			&.active {
				opacity: 1;
				z-index: 10;
			}

			.btn {
				@include horizontal-center;
				background: #2b2b2b;
				border-radius: 10px;
				bottom: 90px;
				color: #ffffff;
				font-size: 16px;
				line-height: 26px;
				padding: 9px 33px;
				position: absolute;
				text-transform: uppercase;
				z-index: 5;

				&:hover {
					background: #161616;
				}
			}
		}

		.home-slides__pagination {
			@include horizontal-center;
			bottom: 36px;
			font-size: 0;
			padding: 3px 6px;
			position: absolute;
			z-index: 50;

			> li {
				@include transition(all, 0.3s);
				background: transparent;
				border: 1px solid #ffffff;
				border-radius: 50%;
				cursor: pointer;
				display: inline-block;
				height: 13px;
				width: 13px;

				+ li {
					margin-left: 18px;
				}

				&.active {
					background: #f7a824;
					border-color: #f7a824;
				}
			}
		}
	}
}



.footer {
	.footer-top {
		position: relative;
		z-index: 10;

		form.footer__form {
			input:nth-child(1) {
				flex-basis: 34%;
			}
			input:nth-child(2) {
				flex-basis: 39%;
			}
			button {
				background: $awo-indian-red;
				flex-basis: 24%;
				text-transform: uppercase;

				&:hover {
					background: #ba454f;
				}
			}
		}
	}

	.footer-middle {
		margin-bottom: 25px;

		.col:nth-child(1) {
			flex-basis: 34%;
		}
		.col:nth-child(2) {
			flex-basis: 22%;
		}
		.col:nth-child(3) {
			flex-basis: 41%;
		}

		.footer-bird {
			opacity: 0.1;
			top: -100px;
			z-index: 0;
		}
	}

	.footer__socials {
		display: block;
		margin-bottom: 20px;

		> div {
			display: inline-block;

			+ div {
				margin-left: 30px;
			}

			> a {
			}
		}
	}

	.footer__logos {
		margin-bottom: 16px;
	}

	.footer__logo {
		margin-bottom: 16px;
	}

	.footer__address {
		line-height: 1.7em;
	}

	.footer__desc {
		font-size: 13px;
		line-height: 1.7em;
		margin-right: 0;
	}

	.footer__nav {
		display: block;

		> li {
			margin-bottom: 0;

			+ li {
				margin-top: 11px;
			}
		}
	}
}




@media (min-width : 1367px)
{
	.header {
		.logo-container {
			display: none;
		}

		.header__nav {
			flex: 1;
			padding-top: 0;
			text-align: center;

			> ul {
				display: inline-block;
				margin: 0 auto;


				> li {
					border-bottom: 6px solid #2b2b2b;
					text-align: left;
					padding: 20px 0;

					&:hover {
						border-color: #f7a824;

						> a {
							color: #f7a824;
						}
					}

					&.active {
						border-color: #da5527;

						> a {
							color: #da5527;
						}
					}

					> a {
						display: block;
						line-height: 22px;
						padding: 6px 12px;
					}
				}
			}

			.header__nav-hidden {
				margin-left: 100px;
				padding-top: 30px;
				top: 80px;
			}
		}

		.header__right {
			padding-right: 36px;
			padding-top: 20px;

			.header__buttons {
				margin-right: 20px;
			}

			a.header__order {
				font-size: 12px;
				margin-right: 20px;
				padding: 12px 15px 16px 50px;
			}
		}
	}

	.atm {
		> .logo-container {
			display: block !important;
		}
	}
}

@media (min-width : 1550px)
{
	.header {
		.header__nav {
			> ul {
				padding-left: 360px;

				.header__nav-hidden {
					margin-left: 360px;
				}
			}
		}
	}
}
@media (max-width : 1549px)
{
	.header {
		.header__nav {
			text-align: right;
		}
	}
}
@media (max-width : 1366px)
{
	.header {
		padding-top: 20px;
        padding-bottom: 20px;
        .header__nav {
			text-align: left;
		}
	}

	.home-what {
		.home-what__list {
			.home-what__item {
				.home-what__item-title {
					font-size: 36px;
				}
			}
		}
	}
}

@media (max-width : 1024px)
{

	.header {
		.logo-container {
			padding-left: 10px;
		}
	}

	.atm {
		height: 80vh;
		max-height: 500px;

		.container {
			> .hight-light {
				h1 {
					font-size: 60px;
				}
			}
		}
	}

	.home-what {
		.home-what__list {
			.home-what__item {
				> a {
					padding-bottom: 30px;
					padding-left: 110px;
					padding-top: 30px;
					position: relative;

					br {
						display: none;
					}

					.home-what__item-title {
						text-align: left;
					}

					.home-what__item-pic {
						@include vertical-center;
						bottom: auto;
						height: 50px;
						left: 20px;
						position: absolute;
						top: 50% !important;
					}
				}
			}
		}
	}

	.home-slides {
		.home-slides__list {
			height: 40vw;

			.home-slides__item {
				.btn {
					bottom: 50px;
				}
			}

			.home-slides__pagination {
				bottom: 15px;
			}
		}
	}

	.home-why {
		.home-why__list {
			flex-wrap: wrap;
			flex-direction: row;

			.home-why__item {
				flex: none;
				width: 50%;
			}
		}
	}

	.footer {
		.footer-middle {
			.col:nth-child(3) {
				flex: 100%;
			}
		}

		.footer-bot {
			> .row {
				display: block;
				margin-bottom: 0;
				text-align: center;
			}
		}
	}

}

@media (max-width : 480px)
{

	.home-slides {
		.home-slides__list {
			height: 60vw;
		}
	}
}
@media (min-width: 1540px){

	.template-default .header .logo-container,.template-contact .header .logo-container,.template-contact-new .header .logo-container{

		.header__logo{
			background: url('/img/mobile-logo.png') no-repeat center;
			width: 240px;
    		height: 63px;
		}

	}
}
@media (min-width: 1900px){

	.template-default .header .logo-container,.template-contact .header .logo-container,.template-contact-new .header .logo-container {
		left: 10%;

	}
}
@media (min-width: 2500px){

	.template-default .header .logo-container,.template-contact .header .logo-container,.template-contact-new .header .logo-container {
		left: 15%;

	}
}
