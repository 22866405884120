// ----------------------------------------------------------
// =Style guide!
// This file is included in editor.css and only includes elements used within the editor
// ----------------------------------------------------------
// Headings h1 - h6
%headings {
	@include rem(margin, $vertical-margins, 0);
	font-family: font(heading);
	color: red;
	color: palette(secondary-text);
}
@each $header, $size in $heading-sizes {
	#{$header},
	.#{$header} {
		@extend %headings;
		@include font-size($size);
	}
	.#{$header}.ludicrous {
		font-family: "Ludicrous-Stencil", serif;
	}
}
h1 {
	margin-top: 0;
}
legend {
	@extend h3;
}
// Set base font family and colour
body {
	color: palette(primary-text);
	font-family: font(default);
	font-weight: normal;
	line-height: 1.2;
	@include image_formate_style();
}
// Paragraphs
p {
	@include rem(margin, 0, 0, 15px);
	@include font-size(m);
	line-height: 1.3;
}
// Links
a {
	@extend %transition-links !optional;
	text-decoration: none;
	color: palette(link);
}
hr {
	display: block;
	height: 1px;
	border: 0;
	border-top: 1px solid palette(primary-border-color);
	margin: $vertical-margins 0;
	padding: 0;
}
table {
	width: 100%;
}
th {
	text-align: left;
}
strong{
	font-family: font(heading);
}
blockquote{
	position: relative;
	display: block;
	font-size: 25px;
	font-weight: 700;
	line-height: 1.4;
	margin-bottom: 40px;
	padding-left: 25px;
	p{
		display: inline;
		font-size: 25px!important;
	}
	&:before{
		content: open-quote;
		background: url('/img/svg/icon-openning-quote.svg') 0 0 no-repeat;
		width: 17px;
		height: 17px;
		background-size: contain;
		display: inline-block;
		position: absolute;
		left: 0;
	}
		&:after{
		content: close-quote;
		background: url('/img/svg/icon-closing-quote.svg') 0 0 no-repeat;
		width: 17px;
		height: 17px;
		background-size: contain;
		display: inline-block;
		position: absolute;
		margin-left: 20px;
	}
}
//form elements
.input-group {
	width: 100%;
	max-width: 556px;
	margin-bottom: 30px;
	label {
		margin-bottom: 10px;
		font-weight: 700;
		font-family: 'Lato', sans-serif;;
		color: #413d4b;
		font-size: 20px;
		letter-spacing: 1px;
		display: block;
	}
}
input[type=text], input[type=email], textarea {
	width: 100%;
	background: #fff;
	border: 0;
	height: 52px;
	border-radius: 12px;
	padding: 0 20px;
	font-size: 16px;
	font-family: 'Lato', sans-serif;;
}
textarea {
	resize: none;
	height: 175px;
	padding-top: 10px;
	padding-bottom: 10px;
}
table{
	th{
		text-align: left;
		color: #fff;
		background-color: $orange;
		padding: 10px 20px;
		&:first-child{
			border-radius: 10px 0 0 0;
		}
		&:last-child{
			border-radius: 0 10px 0 0;
		}
	}
	td{
		color: #4d4d4d;
		padding: 10px 20px;
		border: 1px solid #4d4d4d;
	}
}
//these are used in icms wisiwig as well as on page content
%btn{
	text-align: center;
	padding: 14px 35px 15px 35px;
	border-radius: 9px;
	text-transform: uppercase;
	font-size: 16px;
	color: #fff!important;
	font-weight: 400;
	letter-spacing: 0.19px;
	font-family: 'Lato', sans-serif;
	border: 0;
	text-decoration: none!important;
}
.btn--gray {
	@extend %btn;
	background-color: #2b2b2b;
	&:hover{
		@extend %grey-hover;
	}
}
.btn--red {
	@extend %btn;
	background-color: #a73336;
}
.btn--orange{
	@extend %btn;
	background-color: #da5527;
	&:hover{
		@extend %orange-hover;
	}
}
.custom-text-image-block {
	content: " ";
	display: table;
	clear: both;
	span.cke_widget_image{
		display: inline;
	}
	img{
		padding: 15px;
		@media all and (max-width: 480px){
			width: 100%;
			height: auto;
		}
	}
	img.img-left{
		float: left;
		padding-left: 0;
	}
	img.img-right{
		float: right;
		padding-right: 0;
	}
}