.awo-principles {
  margin-top: 4.5rem;

  @include mq(md) {
    margin-top: 7.75rem;
  }

  &.reveal {
    .awo-principles__grid-item {
      opacity: 0;
      transition: opacity 500ms ease;

      @for $i from 0 through 6 {
        &:nth-child(#{$i}) {
          transition-delay: #{0.25 + ($i * 0.07)}s;
        }
      }
    }

    &.revealed {
      .awo-principles__grid-item {
        opacity: 1;
      }
    }
  }

  .container {
    position: relative;

    img {
      position: absolute;
      top: 0;
      left: 0;
      margin-top: -7rem;
      display: none;

      @include mq(md) {
        display: block;
      }
    }
  }

  .awo-principles__title {
    font-family: $font-awo-title;
    font-size: 36px;
    line-height: 40px;
    color: $awo-onyx;
    max-width: 14rem;
    margin: 0 auto 2rem;

    @include mq(md) {
      font-size: 45px;
      margin-bottom: 3.25rem;
      max-width: 100%;
    }
  }

  .awo-principles__grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;

    @include mq(sm) {
      gap: 1rem;
      grid-template-columns: repeat(3, 1fr);
    }

    @include mq(md) {
      gap: 1rem;
      grid-template-columns: repeat(6, 1fr);
    }
  }

  .awo-principles__grid-item {
    text-align: center;

    h4 {
      font-family: $font-awo-body;
      font-size: 15px;
      line-height: 22px;
      font-weight: 700;
      text-align: center;
      color: $awo-black-liver;
      letter-spacing: -0.5px;
      max-width: 7.85rem;
      margin: 0.5rem auto;

      @include mq(md) {
        font-size: 20px;
        line-height: 20px;
        margin-top: 0.9375rem;
        max-width: 100%;
      }
    }

    svg {
      max-width: 5.25rem;
      margin: 0 auto;
      fill: $awo-raisin-black;

      @include mq(md) {
        max-width: 6.25rem;
      }
    }

    .awo-principles__grid-item--image {
      min-height: 5rem;

      @include mq(md) {
        min-height: 6.75rem;
      }
    }

    h4 {
      font-family: $font-awo-body;
      font-weight: 600;
      line-height: 1.625rem;
      max-width: 10rem;
    }

    &.principle-2 {
      svg {
        max-width: 4.75rem;

        @include mq(md) {
          max-width: 5.75rem;
        }
      }
    }

    &.principle-3 {
      svg {
        max-width: 5.75rem;

        @include mq(md) {
          max-width: 6.75rem;
        }
      }
    }

    &.principle-4 {
      svg {
        max-width: 5.5rem;

        @include mq(md) {
          max-width: 6.75rem;
        }
      }
    }

    &.principle-5 {
      svg {
        max-width: 4.75rem;

        @include mq(md) {
          max-width: 6rem;
        }
      }
    }

    &.principle-6 {
      svg {
        max-width: 5.5rem;

        @include mq(md) {
          max-width: 6.75rem;
        }
      }
    }
  }

  .awo-principles__button {
    text-align: center;
    margin-top: 2.25rem;

    @include mq(md) {
      margin-top: 1.5rem;
    }
  }
}
