.home-what {
    padding-bottom: 60px;
    .container {

    }
    position: relative;
    
}

.home-what.colourful-grid {
    a {
        text-decoration: none;
    }
}

.home-what .home-what-bird{
    position: absolute;
    right: 50px;
    bottom: 0px;
    z-index: 3;
}

.home-what__list {
    display: flex;
    justify-content: space-between;
    margin-bottom: 55px;
}
.home-what__item {
    width: calc( (100%/3) - 30px );
    background: red;
    padding: 110px 0 35px 0;
    text-align: center;
    position: relative;
    border-radius: 20px;
    &:nth-child(1) {
        background-color: $yellow-color;
        &:hover{
            @extend %yellow-hover;
        }
        img {
            width: 73px;
        }
    }
    &:nth-child(2) {
        background-color: $pink-color;
        &:hover{
            @extend %pink-hover;
        }
        img {
            width: 64px;
        }
    }
    &:nth-child(3) {
        background-color: $orange-color;
        &:hover{
            @extend %orange-hover;
        }
        img {
            width: 59px;
        }
    }
}
.home-what__item-pic {
    position: absolute;
    left: 50%;
    transform: translate(-50%,0);
    &:nth-child(1) {
        top: 35px;
    }
    &:nth-child(2) {
        top: 23px;
    }
    &:nth-child(3) {
        top: 31px;
    }
    img {

    }
}
.home-what__item-title {
    font-family: $lu;
    font-size: 45px;
    color: #fff;
    letter-spacing: 2.04px;
}

.home-what__title {
    max-width: 980px;
    margin: 0 auto 30px auto;
    font-size: 28px;
    font-weight: 500;
    line-height: 1.35;
    letter-spacing: 0.28px;
    text-align: center;
}
.home-what__desc {
    text-align: center;
    max-width: 1020px;
    font-size: 18px;
    line-height: 1.35;
    letter-spacing: 0.18px;
    text-align: center;
    margin: 0 auto;
}
