.awo-button {
  font-size: 14px;
  line-height: 17px;
  font-weight: 700;
  text-decoration: none;
  text-transform: uppercase;
  color: $awo-black-jet-dark;
  background-color: $awo-yellow-bright-crayola;
  border: 1px solid $awo-yellow-bright-crayola;
  padding: 0.85rem 1.75rem;
  border-radius: 10px;
  display: inline-block;
  text-align: center;
  transform: perspective(1px) translateZ(0);
  transition-duration: 0.3s;
  transition-property: color, background-color, border;

  @include mq(sm) {
    line-height: 9px;
    padding: 1.15rem 2.75rem;
  }

  &:hover {
    background-color: $awo-black-jet-dark;
    border: 1px solid $awo-black-jet-dark;
    color: $awo-white;
  }
}

.awo-button.button-black {
  background-color: $awo-black-jet-dark;
  border: 1px solid $awo-black-jet-dark;
  color: $awo-white;

  &:hover {
    background-color: $awo-yellow-bright-crayola;
    border: 1px solid $awo-yellow-bright-crayola;
    color: $awo-black-jet-dark;
  }
}

.awo-button.button-transparent-black {
  background-color: transparent;
  border: 1px solid $awo-black-jet-dark;
  color: $awo-black-jet-dark;

  &:hover {
    background-color: $awo-black-jet-dark;
    color: $awo-white;
  }
}

.awo-button.button-transparent-yellow {
  background-color: transparent;
  border: 1px solid $awo-yellow-bright-crayola;
  color: $awo-black-jet-dark;
  padding: 1.1rem 2.35rem;

  &:hover {
    background-color: $awo-yellow-bright-crayola;
    color: $awo-black-jet-dark;
  }
}

.button-full-mobile {
  width: 100%;

  @include mq(sm) {
    width: auto;
  }
}

.awo-button-hover-effect {
  transform: perspective(1px) translateZ(0);
  transition-duration: 0.3s;
  transition-property: color, background-color, border;

  &::after {
    transform: perspective(1px) translateZ(0);
    transition-duration: 0.3s;
    transition-property: color, background-color, border;
  }
}

.awo-button-wide-x {
  width: 100%;
  display: inline-block;

  @include mq(sm)
  {
    min-width: 22rem;
    width: auto;
  }
}
