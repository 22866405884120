.awo-home-hero {
  position: relative;

  &.reveal {
    transform: translate(0);

    .awo-home-hero--bg-image > img {
      transform: scale(1.15);
      filter: blur(4px);
      transition: transform 500ms ease, filter 150ms ease 400ms;
    }

    &.revealed {
      .awo-home-hero--bg-image > img {
        transform: scale(1);
        filter: blur(0);
      }
    }

  }

  .awo-home-hero--bg-image {
    min-height: 43rem;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    position: relative;

    img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }

    &::after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      background-color: black;
      width: 100%;
      height: 100%;
      opacity: 0.15;
    }

    @include mq(md) {
      min-height: 47.5rem;
    }

    .container {
      padding-top: 3rem;

      @include mq(md) {
        padding-top: 10rem;
      }
    }
  }

  .container {
    margin: auto;
    position: relative;
    box-sizing: border-box;
    z-index: 2;
  }

  .awo-home-hero--title {
    font-size: 70px;
    line-height: 70px;
    color: $awo-white;
    font-family: $font-awo-title;
    margin: 0;

    @include mq(md) {
      font-size: 93px;
      line-height: 90px;
    }
  }

  .awo-home-hero--description {
    font-size: 20px;
    line-height: 28px;
    color: $awo-white;
    margin-bottom: 1.75rem;
  }

  .awo-home-hero-swiper-pagination {
    position: absolute;
    bottom: 5.25rem;
    padding: 0 1rem;

    @include mq(sm) {
      padding: 0 2rem;
    }

    @include mq(md) {
      bottom: 5.5rem;
      padding: 0 0.5rem;
    }
  }

  .awo-home-hero-white-banner {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    z-index: 2;
    margin-bottom: -4rem;
    pointer-events: none;

    svg {
      fill: $awo-white;
      margin-bottom: -1rem;
    }
  }
}
