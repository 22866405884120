.awo-h2 {
  font-family: $font-awo-title;
  font-weight: 400;
  font-size: 2.25rem;
  line-height: 2.5rem;
  color: $awo-onyx;
  text-align: center;
  @include mq(md) {
    font-size: 2.8125rem;
    line-height: 3.4375rem;
    max-width: 66rem;
    text-align: left;
  }
}
