.awo-impact {
  margin-top: 3rem;

  @include mq(md) {
    margin-top: 6.25rem;
  }

  .awo-impact__top-wave {
    svg {
      fill: $awo-old-lace;
      margin-bottom: -1rem;
    }
  }

  .awo-impact__bottom-wave {
    svg {
      fill: $awo-old-lace;
      margin-bottom: 0;
    }
  }

  .container {
    position: relative;

    .awo-impact__bird {
      position: absolute;
      right: 0;
      top: 0;
      margin-top: -4rem;
      display: none;

      @include mq(md) {
        display: block;
      }
    }
  }

  .awo-impact__title {
    font-family: $font-awo-title;
    font-size: 36px;
    line-height: 40px;
    color: $awo-onyx;
    margin-top: 0.5rem;
    margin-bottom: 1.5rem;

    @include mq(md) {
      font-size: 45px;
      margin-top: 0;
      margin-bottom: 3rem;
    }
  }

  .awo-impact__wrapper {
    background-color: $awo-old-lace;
    padding-top: 2.5rem;
    padding-bottom: 4rem;
  }

  .awo-impact__container {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    row-gap: 2.25rem;

    @include mq(sm) {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  .awo-impact__item {
    text-align: center;

    .awo-impact__item-number {
      font-family: $font-awo-title;
      font-size: 64px;
      line-height: 70px;
      color: $awo-flame;

      @include mq(md) {
        font-size: 80px;
        line-height: 80px;
      }
    }

    .awo-impact__item-description {
      font-size: 15px;
      line-height: 22px;
      font-weight: 600;
      max-width: 15rem;
      margin: 0 auto;
      max-width: 14rem;

      @include mq(md) {
        font-size: 20px;
        line-height: 36px;
        max-width: 100%;
      }
    }
  }
}
