.awo-footer {
  position: relative;
  z-index: 1;

  .awo-footer__top-wave {
    svg {
      fill: $awo-black-jet;
      margin-bottom: -0.25rem;
    }
  }

  .awo-footer__wrapper {
    background: $awo-black-jet;
  }

  &.awo-footer-mt-2 {
    margin-top: -6rem;
  }

  .awo-newsletter {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;

    @include mq(sm) {
      padding-top: 3rem;
      padding-bottom: 3.75rem;
    }
  }

  .awo-newsletter__title {
    font-size: 36px;
    line-height: 40px;
    font-family: $lu;
    color: $white;
    text-align: center;
    padding: 0;
    letter-spacing: 0;
    max-width: 14rem;
    margin: 0 auto 1.75rem;

    @include mq(sm) {
      font-size: 45px;
      line-height: 45px;
      text-align: left;
      margin-bottom: 1rem;
      max-width: 100%;
    }
  }

  .awo-newsletter__container {
    .awo-newsletter__form {
      display: flex;
      flex-wrap: wrap;

      @include mq(sm) {
        flex-wrap: nowrap;
      }

      input:nth-child(1) {
        flex: 1 1 100%;
        margin-bottom: 0.75rem;

        @include mq(sm) {
          flex: 0 1 34%;
          margin-bottom: 0;
          margin-right: 1rem;
        }
      }

      input:nth-child(2) {
        flex: 1 1 100%;
        margin-bottom: 0.75rem;

        @include mq(sm) {
          flex: 0 1 40%;
          margin-right: 1rem;
          margin-bottom: 0;
        }
      }

      button {
        flex: 1 1 100%;
        padding: 1rem;

        @include mq(sm) {
          flex: 1 1 auto;
        }
      }

      input {
        font-family: $lato;
        font-weight: bold;
        font-size: 14px;
        line-height: 73px;
        text-transform: uppercase;
        color: $black;

        &::placeholder {
          color: $black;
          opacity: 1;
        }
      }

      button {
        font-family: $lato;
        font-size: 16px;
        color: $awo-black-jet;
        background-color: $awo-yellow-bright-crayola;
        border: none;
        border-radius: 12px;
        text-transform: uppercase;
        cursor: pointer;
        font-weight: 700;

        &:hover {
          background-color: $awo-black-jet-dark;
          color: $awo-white;
        }
      }
    }
  }

  .awo-details {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 1rem;

    @include mq(sm) {
      flex-wrap: wrap;
      margin-bottom: 1.25rem;
    }

    .awo-details__address {
      width: 100%;
      text-align: center;
      order: 1;

      .awo-details__address-logo {
        margin-left: auto;
        margin-right: auto;
        width: auto;
        @include mq(sm) {
          margin-left: 0;
          width: 11.75rem;
        }
      }

      @include mq(sm) {
        width: auto;
        text-align: left;
        flex: 0 1 35%;
      }

      @include mq(md) {
        flex: 1 1 auto;
      }

      img {
        max-width: 15rem;
        margin-bottom: 1.25rem;

        @include mq(sm) {
          // max-width: 11.5rem;
          margin-bottom: 1rem;
        }
      }

      p {
        font-family: $lato;
        font-weight: bold;
        font-size: 15px;
        line-height: 25px;
        color: $awo-gray-platinum;
        margin-bottom: 3.5rem;

        @include mq(sm) {
          line-height: 26px;
          margin-bottom: 1.75rem;
        }
      }

      .awo-follow-us {
        .awo-follow-us__title {
          font-size: 34px;
          line-height: 38px;
          font-family: $lu;
          color: $white;
        }

        .awo-social-icons {
          display: flex;
          justify-content: center;

          @include mq(sm) {
            justify-content: flex-start;
          }

          li {
            margin-right: 1.75rem;

            a {
              background-color: $awo-gray-platinum;
              border-radius: 100%;
              color: $awo-black-jet;
              display: block;
              width: 2rem;
              height: 2rem;
              display: flex;
              justify-content: center;
              align-items: center;
              cursor: pointer;

              &:hover {
                background-color: $awo-orange-peel;
                color: $white;
              }
            }
          }
        }
      }
    }

    .awo-details__logos {
      padding: 3.25rem 1rem 2rem;
      flex: 1 1 auto;
      justify-content: center;
      display: flex;
      order: 2;

      @include mq(sm) {
        padding: 2rem 1rem;
      }

      &.awo-ipad {
        display: none;
      }

      @include mq(sm) {
        justify-content: flex-end;
        padding: 0 3rem;
        order: 3;
        display: none;

        &.awo-ipad {
          display: flex;
          padding-left: 0;
          padding-right: 1rem;
          margin-top: 4rem;
          justify-content: flex-start;
        }
      }

      @include mq(md) {
        order: 2;
        display: flex;

        &.awo-ipad {
          display: none;
        }
      }

      .awo-details__logos-container {
        display:  flex;

        @include mq(md) {
          display: block;
          max-width: 7.25rem;
        }
      }

      .awo-details__logos--aboriginal-flag,
      .awo-details__logos--torres-strait-islander-flag,
      .awo-details__logos--pride-flag {
        width: 100%;
        display: flex;
        align-items: center;

        @include mq(md) {
          align-items: flex-start;
          margin-bottom: 1rem;
        }

        svg {
          width: auto;
          height: 1.75rem;
          margin: 0 1rem;

          @include mq(md) {
            width: 4.25rem;
            height: auto;
            margin: 0 auto;
          }
        }
      }

      .awo-details__logos--aboriginal-flag {
        @include mq(sm) {
          svg {
            margin: 0;
          }
        }

        @include mq(md) {
          svg {
            margin: 0 auto;
          }
        }
      }

      .awo-details__logos--pride-flag  {
        @include mq(md) {
          margin-bottom: 2rem;
        }
      }

      .awo-details__logos--acnc-logo {
        text-align: center;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 0;

        @include mq(sm) {
          gap: 1rem;
        }

        @include mq(md) {
          margin-top: 1.5rem;
        }

        img {
          width: 3rem;
          margin: 0 1rem;

          @include mq(sm) {
            margin: 0;
          }
        }
      }
    }

    .awo-details__text {
      flex: 0 1 auto;
      order: 3;

      @include mq(sm) {
        order: 2;
        flex: 0 1 64%;
      }

      @include mq(md) {
        order: 3;
        flex: 0 1 auto;
      }

      .awo-details__text-container {
        @include mq(sm) {
          max-width: 36rem;
          margin-right: 1.25rem;
          padding-left: 2rem;
        }

        @include mq(md) {
          padding-left: 0;
        }
      }

      p {
        font-family: $lato;
        font-weight: bold;
        font-size: 13px;
        line-height: 24px;
        color: $awo-gray-spanish;

        @include mq(sm) {
          font-size: 14px;
          line-height: 26px;
        }
      }
    }
  }

  $cubic: cubic-bezier(0.4, 0.01, 0.165, 0.99);
  $menuItems: 12;
  .awo-footer-links {
    display: grid;
    grid-template-columns: 1fr;
    border-top: 2px solid $awo-gray-davys;
    padding-top: 1.75rem;
    margin-bottom: 1.5rem;

    @include mq(md) {
      grid-template-columns: repeat(5, 1fr);
      padding-top: 3rem;
      margin-bottom: 2.25rem;
    }

    .awo-footer_links-container {
      margin-bottom: 0.5rem;

      @include mq(sm) {
        margin-bottom: 0;
      }

      .awo-footer__links-title {
        margin-bottom: 0.75rem;
        width: 100%;
        position: relative;
        cursor: pointer;

        @include mq(md) {
          max-width: 11.5rem;
          margin-bottom: 0.5rem;
        }

        a {
          font-family: $lato;
          font-size: 18px;
          line-height: 27px;
          text-transform: uppercase;
          color: $white;
          font-weight: bold;

          @include mq(sm) {
            font-size: 15px;
          }

          &:hover {
            color: $awo-orange-peel;
          }
        }

        &.is-active {
          a {
            color: $awo-yellow-bright-crayola;
          }

          span {
            svg {
              fill: $awo-yellow-bright-crayola;
              transform: rotate(180deg);
              transform-origin: center;
              transition: all 300ms ease-in-out;
            }
          }
        }

        span {
          width: 2rem;
          height: 2rem;
          position: absolute;
          top: 0;
          right: 0;
          display: block;
          margin-top: -0.25rem;

          @include mq(md) {
            display: none;
          }

          svg {
            width: 2rem;
            height: 2rem;
            fill: $white;
            transition: all 300ms ease-in-out;
          }
        }
      }

      .awo-footer__links-group {
        max-height: 0;
        opacity: 0;
        transform: scaleY(0);
        transform-origin: top;

        &.is-active {
          max-height: 100%;
          opacity: 1;
          transform: scaleY(1);
          margin-bottom: 2rem;

          li {
            transform: scale(1) translateY(0);
            opacity: 1;

            @for $i from 0 through $menuItems {
              &:nth-child(#{$i}) {
                transition-delay: #{0.1 + ($i * 0.07)}s;
              }
            }
          }
        }

        @include mq(md) {
          max-height: 100%;
          opacity: 1;
          transform: scaleY(1);
          max-width: 11.5rem;
        }

        li {
          margin-bottom: 1.25rem;
          transform: translateY(-30px);
          opacity: 0;
          transition: transform 0.5s $cubic, opacity 0.6s $cubic;
          will-change: transform;

          @include mq(md) {
            transform: translateY(0);
            opacity: 1;
            margin-bottom: 0.75rem;
          }

          a {
            font-family: $lato;
            font-weight: 300;
            font-size: 16px;
            line-height: 16px;
            color: $white;

            @include mq(sm) {
              font-family: $lato;
              font-size: 14px;
              line-height: 18px;
            }

            &:hover {
              color: $awo-orange-peel;
            }
          }
        }
      }
    }
  }

  .awo-footer-copyright {
    border-top: 2px solid $awo-gray-davys;
    display: flex;
    padding-top: 2rem;
    padding-bottom: 3rem;
    flex-wrap: wrap;

    @include mq(sm) {
      flex-wrap: nowrap;
      padding-top: 1.5rem;
    }

    .awo-footer-copyright__links {
      display: flex;
      justify-content: flex-start;
      width: 100%;

      @include mq(sm) {
        width: auto;
      }

      ul {
        display: flex;
        flex-wrap: nowrap;
        width: 100%;

        @include mq(sm) {
          width: auto;
        }

        li {
          margin-right: 1.5rem;
          width: 100%;
          text-align: left;
          margin-bottom: 0.5rem;

          @include mq(sm) {
            width: auto;
            margin-bottom: 0;
          }

          a {
            font-family: $lato;
            font-weight: bold;
            font-size: 13px;
            line-height: 24px;
            text-transform: uppercase;
            color: $awo-gray-spanish;

            @include mq(sm) {
              font-size: 14px;
              line-height: 26px;
            }

            &:hover {
              color: $awo-orange-peel;
            }
          }
        }
      }
    }

    .awo-footer-copyright__text {
      font-family: $lato;
      font-weight: bold;
      font-size: 13px;
      line-height: 24px;
      color: $awo-gray-spanish;
      margin-top: 0.5rem;
      width: 100%;
      text-align: left;

      @include mq(sm) {
        margin-top: 0;
        width: auto;
        font-size: 14px;
        line-height: 26px;
      }
    }

    .awo-footer-copyright__brightlabs {
      margin-left: 0;
      margin-right: auto;
      font-size: 14px;
      line-height: 18px;
      color: $awo-gray-spanish;
      margin-top: 1rem;
      display: flex;
      align-items: center;

      @include mq(sm)
      {
        margin-top: 0;
        margin-left: auto;
        margin-right: 0;
      }

      a {
        color: #00aff2;
        margin-left: 0.25rem;
      }
    }
  }
}
