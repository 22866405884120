.awo-hover-area {
  --border-radius: 18px;
  --slider-image-height: 29.5rem;
  --slider-image-height-ipad: 22rem;
  --slider-image-height-mobile: 15.5rem;

  position: relative;
  padding-top: 4rem;
  padding-bottom: 10rem;

  .awo-hover-area-slide__container {
    min-height: var(--slider-image-height-mobile);
    position: relative;

    @include mq(sm) {
      min-height: var(--slider-image-height-ipad);
    }

    @include mq(md) {
      min-height: var(--slider-image-height);
    }

    .awo-hover-area-slide-swiper {
      border-radius: var(--border-radius);
      overflow: hidden;

      img {
        object-fit: contain;
        object-position: center;
        max-height: var(--slider-image-height-mobile);

        @include mq(sm) {
          min-height: var(--slider-image-height-ipad);
        }

        @include mq(md) {
          max-height: var(--slider-image-height);
        }
      }

      &.reveal {
        img {
          transform: scale(1.15);
          transition: opacity 0.65s linear, transform 2s ease;
        }

        &.revealed {
          img {
            transform: scale(1);
            transition: opacity 0.65s linear, transform 2s ease;
          }
        }
      }
    }

    .awo-hover-area__content {
      display: flex;
      position: absolute;
      bottom: auto;
      padding: 0 1.25rem;
      z-index: 1;
      width: 100%;
      margin-top: -3.75rem;

      @include mq(md) {
        width: auto;
        padding: 0 4.25rem;
        margin-top: 0;
        margin-bottom: -10.75rem;
        bottom: 0;
      }

      .awo-hover-area__content-left {
        background-color: $awo-yellow-bright-crayola;
        border-radius: var(--border-radius);
        width: 100%;

        @include mq(md) {
          width: auto;
        }

        .awo-hover-area__content-left-slider {
          padding: 0 2rem 2rem;
          display: flex;
          align-items: center;

          @include mq(md) {
            padding: 0 2rem;
            min-width: 30.5rem;
            min-height: 19.5rem;
            max-width: 30.5rem;
          }

          .awo-hover-area__content-left-slider--title {
            font-family: $font-awo-title;
            font-size: 40px;
            line-height: 44px;
            color: $awo-white;
            display: block;
            padding-top: 2rem;
            padding-bottom: 0.5rem;

            @include mq(sm) {
              max-width: 12rem;
            }

            @include mq(md) {
              padding-top: 0;
              padding-bottom: 0;
              font-size: 70px;
              line-height: 75px;
              max-width: 100%;
            }
          }

          a {
            @include mq(md) {
              display: none;
            }
          }
        }
      }

      .awo-hover-area__content-right {
        align-items: flex-end;
        padding-left: 2rem;
        display: none;

        @include mq(md) {
          display: flex;
        }

        ul {
          li {
            a {
              font-family: $font-awo-title;
              font-size: 22px;
              line-height: 31px;
              color: $awo-light-gray;

              &:hover {
                color: $awo-yellow-bright-crayola;
              }
            }
          }

          li.is-active {
            a {
              color: $awo-yellow-bright-crayola;
            }
          }
        }
      }
    }
  }

  .swiper-pagination.swiper-pagination-horizontal.swiper-pagination-bullets.awo-hover-area-slide-swiper-pagination {
    text-align: center;
    margin-bottom: -5.5rem;

    @include mq(md) {
      display: none;
    }

    .swiper-pagination-bullet:not(.swiper-pagination-bullet-active) {
      background-color: $awo-gray-gainsboro;
      opacity: 1;
    }
  }
}
