.home-why {
    position: relative;
    padding-top: 50px;
    padding-bottom: 25px;
    .container{
            position: relative;
            .home-why-bird{
                position: absolute;
                left: -200px;
                top: -145px;
                z-index: 3;
            }
    }
    
}

.home-why__list {
    display: flex;
    justify-content: center;
    align-items: flex-start;
}
.home-why__item {
    &:not(:last-child) {
        margin-right: 100px;
    }
}
.home-why__item-pic {
    height: 101px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 25px;
    img {
        
    }
}
.home-why__item-title {
    font-size: 20px;
    color: #413d4b;
    font-weight: 700;
    text-align: center;
}

.home-why__button {
    text-align: center;
    margin-top: 35px;
    a {
        margin: 0 auto;
    }
}