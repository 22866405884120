.awo-itc {
    .awo-itc__top {
        display: flex;
        justify-content: space-between;
        gap: 1rem;
        align-items: center;
        margin-bottom: 1rem;
        h2 {
            color: #404040;
            font-family: $lu;
            font-size: 36px;
            line-height: 40px;
            font-weight: 400;
            @include mq(sm) {
                font-size: 45px;
                line-height: 55px;
            }
        }
        .awo-itc__top-buttons {
            display: flex;
            align-items: center;
            gap: 2rem;
            button {
                width: 2.5rem;
                height: 2.5rem;
                border-radius: 100%;
                background-color: #dfdfdf;
                display: flex;
                align-items: center;
                justify-content: center;
                border: none;
                padding: 0;
                color: #D85625;
            }
            button:nth-child(1) {
                svg {
                    transform: rotate(-180deg);
                }
            }
        }
    }
    .awo-itc__carousel {
        .swiper.is-orange {
            .awo-itc__carousel-card__left {
                background-color: #D85625;
            }
        }
        .swiper.is-purple {
            .awo-itc__carousel-card__left {
                background-color: #8D538D;
            }
        }
        .awo-itc__carousel-card {
            display: flex;
            border-radius: 25px;
            overflow: hidden;
            flex-wrap: wrap;
            @include mq(sm) {
                flex-wrap: nowrap;
            }
            .awo-itc__carousel-card__left {
                flex: 1 1 auto;
                color: #fff;
                padding: 2rem;
                position: relative;
                order: 2;
                padding-bottom: 2rem;
                width: 100%;
                @include mq(sm) {
                    order: 1;
                    padding-bottom: 6rem;
                    flex: 1 1 auto;
                }
                .awo-itc__carousel-card__title {
                    text-align: left;
                    color: #fff;
                    margin: 0;
                    font-family: $lato;
                    font-size: 26px;
                    font-weight: 700;
                    line-height: 32px;
                    margin-bottom: 1rem;
                }
                p {
                    font-family: $lato;
                    font-size: 17px;
                    font-weight: 400;
                    line-height: 28px;
                    max-width: 36rem;
                }
                a {
                    position: relative;
                    background-color: #000;
                    border-radius: 10px;
                    color: #fff;
                    font-family: $lato;
                    font-size: 14px;
                    font-weight: 700;
                    line-height: 9px;
                    text-transform: uppercase;
                    padding: 0.75rem;
                    transform: perspective(1px) translateZ(0);
                    transition-duration: 0.3s;
                    transition-property: color, background-color, border;
                    display: inline-block;
                    @include mq(sm) {
                        position: absolute;
                        left: 0;
                        bottom: 0;
                        margin-left: 2rem;
                        margin-bottom: 2rem;
                    }
                    &:hover {
                        background-color: #f7a824;
                        color: #2b2b2b;
                    }
                }
            }
            .awo-itc__carousel-card__right {
                flex: 1;
                order: 1;
                width: 100%;
                @include mq(sm) {
                    order: 2;
                    flex: 1 0 40%;
                    width: auto;
                }
                @include mq(md) {
                    flex: 1 0 auto;
                }
                img {
                    max-height: 14rem;
                    object-fit: cover;
                    object-position: center;
                    width: 100%;
                    height: 100%;
                    @include mq(sm) {
                        max-height: 100%;
                        max-width: 25.25rem;
                    }
                }
            }
        }
        .swiper-pagination-bullets {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            gap: 0.75rem;
            margin-top: 2.25rem;
            .swiper-pagination-bullet {
                width: 14px;
                height: 14px;
                background: #D9D9D9;
                opacity: 1;
            }
            .swiper-pagination-bullet-active {
                background: #DA5527;
            }
        }
    }
}
