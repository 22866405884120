.involved-page {
  z-index: 2;
  position: relative;

  .container {
    position: relative;

    h2 {
      text-align: left;
    }
  }
}

.involved-page-grid-title {
  padding-bottom: 1.5rem;

  h2 {
    text-align: center;
    margin-top: 0;
    margin-bottom: 1.25rem;
  }

  hr {
    border-top: 2px solid $awo-yellow-bright-crayola;
  }
}

.involved-page-bird {
  position: absolute;
  right: 0;
  bottom: 0;
  margin-right: 1rem;
  margin-bottom: -2.5rem;
  display: none;

  @include mq(md) {
    display: block;
  }
}
