.awo-ritc {
    overflow: hidden;
    margin-bottom: 4rem;
    .awo-ritc__container {
        display: flex;
        align-items: center;
        gap: 2.5rem;
        flex-wrap: wrap;
        @include mq(sm) {
            gap: 3rem;
            flex-wrap: nowrap;
        }
    }
    .awo-ritc__left {
        display: flex;
        justify-content: center;
        width: 100%;
        @include mq(sm) {
            width: auto;
        }
        img {
            width: 276px;
            height: 276px;
            border-radius: 100%;
            overflow: hidden;
            object-fit: cover;
            object-position: center;
            @include mq(sm) {
                width: 300px;
                height: 300px;
            }
            @include mq(lg) {
                width: 400px;
                height: 400px;
            }
        }
    }
    .awo-ritc__right {
        position: relative;
        width: 100%;
        @include mq(sm) {
            width: auto;
        }
        .awo-ritc__right-title {
            color: #2b2b2b;
            font-family: $lu;
            font-size: 36px;
            line-height: 40px;
            font-weight: 400;
            max-width: 38rem;
            margin-bottom: 2.5rem;
            @include mq(sm) {
                font-size: 45px;
                line-height: 55px;
                margin-bottom: 2rem;
            }
        }
        .awo-ritc__right-button {
            width: 100%;
            @include mq(sm) {
                width: auto;
            }
        }
        .awo-ritc__right-bird {
            position: absolute;
            right: 0;
            bottom: 0;
            height: 172px;
            margin-right: -8rem;
            margin-bottom: -3.5rem;
            display: none;
            @include mq(lg) {
                display: block;
            }
        }
    }
}
