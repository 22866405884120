.cmsform{
    .field_contain  {
        width: 100%;
        max-width: 556px;
        margin-bottom: 10px;
        color: $awo-onyx;
        padding-top: 0.75rem;
    }

    span.required {
        font-weight: 600;
        font-family: $lato;
        color: $awo-black-liver;
        font-size: 17px;

        &.hasError {
            color: $awo-red;
        }
    }

    label {
        font-weight: 600;
        font-family: $lato;
        color: $awo-black-liver;
        font-size: 17px;
        display: block;
        float: left;

        &.hasError {
            color: $awo-red;
        }
    }
    .field_input{
        margin-left: 0;
        position: relative;

        input.formError {
            border: 1px solid $awo-red;
        }

        .g-recaptcha{
            margin-top: 10px;
        }
        select {
            border-radius: 6px;
            width: 100%;
            height: 52px;
            appearance: none;
            line-height: 48px;
            border: 1px solid $awo-silver;
            padding-left: 22px;
            padding-right: 22px;
            font-family: $lato;
            font-size: 15px;
            font-weight: 600;
            position: relative;
            background-image: url("/img/dropdown-icon.png");
            background-repeat: no-repeat;
            background-size: 16px 10px;
            background-position: right 11px center;
            background-color: #fff;

            &[name$="_d"],
            &[name$="_m"],
            &[name$="_y"] {
                width: 31.3%;
            }

            &[name$="_m"],
            &[name$="_y"] {
                margin-left: 2%;
            }

            &.formError {
                border: 1px solid $awo-red;
            }
        }
    }
    .required{
        font-size: 20px;
        font-weight: 700;
    }
    .field_name{
        display: flex;
        width: 100%;
        text-align: left;
        margin-bottom: 10px;

        span.required {
            order: 2;
        }

        label {
            order: 1;
        }
    }

    input[type=text], input[type=email], textarea {
        width: 100%;
        background: #fff;
        border: 0;
        height: 52px;
        border-radius: 6px;
        padding: 0 20px;
        font-size: 15px;
        font-family: 'Lato', sans-serif;
        border: 1px solid $awo-silver;
    }
    input[type=checkbox]{
        height: 25px;
        width: 25px;
        background-color: #fff;
        border: 1px solid $awo-silver;
        position: relative;
        cursor: pointer;
        margin-top: 0.1rem;
        flex: 0 0 auto;

        &:checked::before{
            content: '';
            width: 13px;
            height: 13px;
            position: absolute;
            top: 5px;
            left: 5px;
            background-color: $awo-black-jet-dark;
        }
    }
    input[type=radio]{
        height: 25px;
        width: 25px;
        background-color: #fff;
        border: 1px solid $awo-silver;
        border-radius: 100%;
        position: relative;
        cursor: pointer;
        margin-top: 0.1rem;
        flex: 0 0 auto;

        &:checked::before{
            content: '';
            width: 11px;
            height: 11px;
            border-radius: 100%;
            position: absolute;
            top: 6px;
            left: 6px;
            background-color: $awo-black-jet-dark;
        }
    }
    .radio_item{
        margin-bottom: 10px;
        width: 100%!important;
    }
    .option_contain {
        margin-top: 2.5rem;
        width: auto !important;
    }
    .option_contain .checkbox_item span, .option_contain .radio_item span{
        padding-left: 10px;
    }
    textarea {
        resize: none;
        height: 175px;
        padding-top: 10px;
    }
    .form_submit{
        padding-left: 0px;
        padding-top: 16px;

        input[type=submit] {
            font-size: 14px;
            line-height: 17px;
            font-weight: 700;
            text-decoration: none;
            text-transform: uppercase;
            color: $awo-white;
            background-color: $awo-black-jet-dark;
            border: 1px solid $awo-black-jet-dark;
            padding: 0.85rem 1.75rem;
            border-radius: 8px;
            display: inline-block;
            text-align: center;
            transform: perspective(1px) translateZ(0);
            transition-duration: 0.3s;
            transition-property: color, background-color, border;

            @include mq(sm) {
                line-height: 12px;
                padding: 1.15rem 3.25rem;
            }

            &:hover {
                background-color: $awo-yellow-bright-crayola;
                border: 1px solid $awo-yellow-bright-crayola;
                color: $awo-black-jet-dark;
            }
        }
    }
}
.checkbox_item,
.radio_item {
    font-family: $lato;
    font-size: 17px;
    line-height: 28px;
    font-weight: 300;
    display: flex;
    align-items: flex-start;
    width: 100%!important;
    margin-bottom: 1.5rem;
}

