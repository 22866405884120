.filter-bar {
  padding-left: 10px;
  padding-right: 10px;
  
  .container {
    display: flex;
    border-radius: 18px;
    padding: 40px 20px;
    margin-bottom: 35px;
    background-color: $yellow-color-light;

    @include mq(sm) {
      padding: 40px 50px;
    }

    .news-filters__form {
      align-items: flex-end;
    }
  }

  &--left {
    flex: 0 1 100%;
    display: flex;
    flex-wrap: wrap;

    @include mq(sm) {
      flex: 0 1 100%;
    }

    @include mq(md) {
      flex: 0 1 75%;
    }

    select {
      max-width: unset !important;
      width: 100%;
      height: 52px;
      margin-bottom: 0 !important;

      @include mq(sm) {
        margin-bottom: 20px;
      }
    }

    &-upper {
      flex: 0 0 100%;
      display: flex;
      flex-wrap: wrap;

      @include mq(sm) {
        flex-wrap: nowrap;
      }

      div {
        flex: 0 0 100%;
        margin: 0.5rem 0;

        @include mq(sm) {
          flex: 0 0 45%;
          margin: 0 1rem 1rem 1rem;
        }
      }
    }

    &-lower {
      flex: 0 0 100%;
      display: flex;
      flex-wrap: wrap;

      @include mq(sm) {
        flex-wrap: nowrap;
      }

      div {
        flex: 0 0 100%;
        margin: 0.5rem 0;

        @include mq(sm) {
          flex: 0 0 45%;
          margin: 0 1rem 0 1rem;
        }
      }
    }
  }

  &--right {
    flex: 0 1 25%;
    margin-top: 0.5rem;

    @include mq(sm) {
      margin-top: 1rem;
      margin-left: 1rem;
    }

    @include mq(md) {
      margin-top: 0;
      margin-left: 0;
    }

    input[type="submit"] {
      background-color: $almost-black;
      text-align: center;
      text-transform: uppercase;
      border: 0;
      border-radius: 12px;
      height: 52px;
      font-family: $lato;
      font-size: 16px;
      font-size: 400;
      color: $white;
      padding: 0 3rem;
    }
  }
}
