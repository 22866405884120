.donate {
    padding: 30px 0 45px 0;
    position: relative;
}
    .donate-dots-bg-wrapper{
        position: absolute;
        left: 0;
        bottom: 300px;
        width: 252px;
        height: 424px;
        background: url('/img/donate/dots-with-pattern.png') no-repeat;
        background-size: contain;
        background-position: left;
    }
    .donate-large-bird{
        position: absolute;
        right: 50px;
        bottom: 230px;
    }
    .donate-small-bird{
        position: absolute;
        right: -100px;
        bottom:48%;
    }
.donate-form {
    position: relative;
    border-radius: 18px;
    background-color: $yellow-color-light;
    padding: 45px;

    .input-group {
        width: 100%;
        max-width: 556px;
        margin-bottom: 30px;
        label {
            margin-bottom: 10px;
            font-weight: 700;
            font-family: $lato;
            color: #413d4b;
            font-size: 20px;
            letter-spacing: 1px;
            display: block;
            float: none;
        }
    }
    input[name=donation_amount]{
        display: none;
    }
    input[type=text], input[type=email],input[type=number] {
        width: 100%;
        background: #fff;
        border: 0;
        height: 52px;
        border-radius: 12px;
        padding: 0 20px;
        font-size: 16px;
        font-family: $lato;
    }
    .donate__amount-item--other.selected input{
        color: darken(#231f20,10%);
    }
    .box {
        display: flex;
        align-items: center;
        &:not(:last-child) {
            margin-bottom: 15px;
        }
        input[type=radio] {
            min-width: 24px;
            height: 24px;
            background: #fff;
            border-radius: 50%;
            cursor: pointer;
        }
        input[type=checkbox] {
            min-width: 24px;
            height: 24px;
            background: #fff;
            border-radius: 7px;
            cursor: pointer;
        }
        input[type=checkbox]:checked, input[type=radio]:checked {
            background: #fff url('/img/icon-checked.png') no-repeat center;
        }
        label {
            width: auto;
            margin: 0 0 0 10px;
            cursor: pointer;
        }
    }

    button {
        border: 0;
        border-radius: 20px;
        background: $yellow-color url('/img/svg/icon-donate.svg') no-repeat center top 18px;
        background-size: 63px 48px;
        padding: 80px 40px 20px 40px;
        font-family: $lu;
        color: #fff;
        font-size: 45px;
        letter-spacing: 2.04px;
        &:hover{
            @extend %yellow-hover;
        }
    }

}

.donate__title {
    margin-bottom: 20px;
    color: $orange-color;
    font-family: $lu;
    font-size: 70px;
    letter-spacing: 3.17px;
}

.donate__desc {
    margin-bottom: 50px;
    color: #413d4b;
    font-size: 16px;
    line-height: 1.35;
    letter-spacing: 0.16px;
}

.donate__subtitle {
    margin-bottom: 20px;
    font-family: $lu;
    letter-spacing: 2.04px;
    font-size: 45px;
}

.donate__amount-wrapper {
    display: flex;
    justify-content: space-between;
    margin-bottom: 25px;
}

.donate__amount-item {
    width: calc( (100% - 100px) / 5 );
    height: 85px;
    background-color: $yellow-color;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: $lu;
    font-size: 45px;
    line-height: 1;
    color: #fff;
    cursor: pointer;
    &.selected {
        background-color: #8dc63f;
    }
    &--other {
        background-color: #fff;
        color: #231f20;
        ::placeholder {
            color: #231f20;
        }
        input[type=number] {
            width: 63%;
            margin-left: 10px;
            border-radius: 0;
            border: 0;
            font-size: 45px;
            font-family: $lu;
            color: #231f20;
            text-transform: uppercase;
            font-weight: 700;
            background-color: transparent;
            padding-left: 0;
            padding-right: 0;
        }
    }
}

.donate__label {
    margin-bottom: 35px;
    font-weight: 700;
    font-size: 16px;
    color: #413d4b;
}
.payment__methods{
   
    margin-bottom: 20px;

    .payment__method-button{
        width: 49%;
        background-color: $yellow-color;
        border-radius: 5px;
        align-items: center;
        height: 80px;
        line-height: 1;
        color: #fff;
        cursor: pointer;
        text-align: center;
        padding-top:20px;
        &:hover{
            cursor: pointer;
        }
        &.selected {
            background-color: #8dc63f;
        }
 
        
        
        b{
            font-size: 23px;
        }
        .payment__method{
            display: none;  
        }
        &:first-child{
            float:left;
        }
        &:nth-child(3){
            float:right;
        }
    }
    #payment__method-paypal{
        padding-top: 12px;
    }
 
}
.payment__details-wrapper{
    max-width: 700px;
    margin: 0 auto;
    text-align: center;
    .credit__card_details-name{
        max-width: 700px;
    }
    label{
        background: $yellow-color;
        padding:20px;
        border-radius: 5px 5px 0px 0px;
        margin-bottom: 0px!important;
        color: white!important;
        text-align: left;
    }
    input{
        border-radius: 0 0 5px 5px!important;
    }
    .credit__card_details-section-2{
        .input-group{
            float:left;
            margin-right: 15px;
            &:last-child{
                margin-right: 0px;
            }
        }
        .credit__card_details-expiry-date{
            width: 30%;
        }
        .credit__card_details-cvv{
            width: 16%;
        }
        .credit__card_details-number{
            width: 49%;
        }
    }
    #donform__btn--donate{
        background: #f7a824;
        padding: 25px 20px;
        width: 100%;
        margin-top: 30px;
        &:hover{
            @extend %yellow-hover;
        }
    }
    &.campaign{
        margin-top: 30px;
    }
}
.donate__summary{
  
    max-width: 700px;
    margin: 0 auto;
    &-header{
        margin-bottom: 15px;
        font-size: 30px;
        text-align: left;
        font-family: "Lato", sans-serif;
    }
    dl{
        font-size: 20px;
    }
    dt{
        font-weight: bold;
        margin-bottom: 5px;
    }
    dd{
        margin-bottom: 20px;
    }
    hr{
        height: 1px;
        border: 0;
        border-top: 0.0625rem solid #999;
        margin: 15px 0;
    }
     &.campaign{
        margin-top: 30px;
    }
}
.donate__action-buttons{
    margin-top: 30px;
    .donform__tab{
        width: 48%;
        float: left;
        background: #f7a824;
        padding: 25px 20px;
        color: white;
        border-radius: 20px;
        font-size: 45px;
        font-family: "Ludicrous-Stencil", serif;
        font-weight: bold;
        text-align: center;
        line-height: 45px;
        &.selected{
            background-color: #8dc63f;
            &:hover{
                background-color: darken(#8dc63f, 5%);
            }
        }
        &:first-child{
            margin-right: 15px;
        }
        &:hover{
            cursor: pointer;
            @extend %yellow-hover;
        }
    }
}
.donate-thankyou{
    a{
        text-decoration: none!important;
    }
}
    

