.awo-hero-careers {
    padding-bottom: 20px;
    padding-top: 130px;
    @media screen and (min-width: 1210px) {
        padding-top: 135px;
    }
    .atm:not(.atm--home) {
        min-height: 500px;
        h1 {
            color: #fff;
            font-family: $lu;
            font-size: 50px;
            font-weight: 400;
            line-height: 60px;
            margin-bottom: 28px;
            max-width: 25rem;
            overflow: visible;
            white-space: wrap;
            @include mq(lg) {
                font-size: 60px;
                line-height: 75px;
                margin-bottom: 0.75rem;
                max-width: 37.5rem;
            }
            @include mq(fhd) {
                font-size: clamp(60px, 6vw, 4vw);
                line-height: clamp(75px, 6vw, 4.5vw);
                max-width: 43rem;
            }
            @include mq(big) {
                max-width: 100%;
            }
        }
        .awo-button {
            margin-bottom: 64px;
            @include mq(lg) {
                margin-bottom: 2.5rem;
            }
        }
    }
    .atm:not(.atm--home).has-svg-fill-cream {
        max-height: 440px;
        min-height: 0;
        svg {
            fill: #f7efe1;
        }
    }
}
