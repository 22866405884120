.camp-content {

    margin-bottom: 70px;

    p {
        text-align: center;
        font-size: 18px;
        line-height: 1.35;
        color: #413d4b;
        letter-spacing: 0.18px;
    }

    background: url('/img/campaign/dots.png') no-repeat;
    background-size: initial;
    background-position: right center;
}

.camp-story {
    display: flex;
    margin-bottom: 50px;
    position: relative;
    &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 3vw;
        background: url('/img/campaign/story-shape-top.png') no-repeat center;
        background-size: cover;
    }
    &::after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 4vw;
        background: url('/img/campaign/story-shape-bottom.png') no-repeat center;
        background-size: cover;
    }
}
.camp-story__pic {
    flex-basis: 50%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}
.camp-story__right {
    flex-basis: 50%;
    background-color: $yellow-color-light;
}

.camp-story__content {
    width: 70%;
    padding-left: 60px;
    padding-top: 140px;
    padding-bottom: 140px;
    h3 {
        text-align: left;
    }
    p {
        font-size: 18px;
        line-height: 1.35;
        color: #413d4b;
        letter-spacing: 0.18px;
    }
}

.camp-donate {
    position: relative;
    margin-bottom: 120px;
    .container {
        max-width: 1060px;
    }
    .donate-text-content{
        margin-bottom: 50px;
    }
    p {
        text-align: center;
        font-size: 18px;
        line-height: 1.35;
        color: #413d4b;
        letter-spacing: 0.18px;
       
    }
    .donate__amount-item--other {
        border: 1px solid #cfcfcf;
    }

    .donate-form {
        background-color: transparent;
        padding: 0;
        .input-group {
            input[type=text], input[type=email],input[type=number] {
                border: 1px solid #cfcfcf;
            }
        }
    }

    &-left-bg-wrapper{
        position: absolute;
        left: 0;
        bottom: 100px;
        width: 216px;
        height: 330px;
        background: url('/img/campaign/dots-with-pattern.png') no-repeat;
        background-size: contain;
        background-position: left;

    }
    &-right-bg-wrapper{
        position: absolute;
        right: 100px;
        bottom: 100px;
        width: 354px;
        height: 324px;
        background: url('/img/campaign/two-birds.png') no-repeat;
        background-size: initial;
        background-position: left;
    }
    
}