// ----------------------------------------------------------------------------------------
//	=Functions
// ----------------------------------------------------------------------------------------

	// Base font size, used to calculate rem/em
	$font-size-base: 16;
	$mq-active: false;


	//	font
	//	Return font vars
	//
	//	@param property 		:  string
	//
	@function font($property) {
		@return map-get($font, $property);
	}


	//	palette
	//	Return palette vars
	//
	//	@param colour 			:  string
	//
	@function palette($colour) {
		@return map-get($palette, $colour);
	}


	//	guard
	//	Return guard vars
	//
	//	@param size 			:  string
	//
	@function guard($size) {
		@return map-get($guard, $size);
	}


	//	calc-percent
	//	Calculate percentage - useful for translating pixel values into percentages
	//
	//	@param target 			:  int
	//	@param container 		:  int
	//
	@function calc-percent($target, $container) {
		@return ($target / $container) * 100%;
	}


	//	int
	//	Convert a size value to just a number
	//
	//	@param n 			:  px, em, %
	//
	@function int($n) {
		@return $n / ($n * 0 + 1);
	}


	//	calculateRem
	//	Calculate Rem size based on base font size
	//
	//	@param size 			:  int
	//	@param base 			:  int for overriding default base size
	//
	@function calculateRem($size, $base: $font-size-base) {
		$remSize: ((int($size) / $base) +rem);
		@return $remSize;
	}


	//	setRGBA
	//	Get a colour and return a semi-transparent version of it
	//
	//	@param colour 			:  #hex
	//	@param transparency 	:  decimal
	//
	@function setRGBA($colour, $transparency) {
		$rgba: rgba(red($colour),green($colour),blue($colour),$transparency);
		@return $rgba;
	}


	//	calc-spriteblock
	//	Calculate and return the pixel location of an element within a spriteblock
	//
	//	@param number 			:  int
	//
	// @function calc-spriteblock($number, $sprite) {
	// 	$position: (($number - 1) * (map-get(map-get($sprites, $sprite), block-size))) * (-1);
	// 	@return $position;
	// }


	//	getBreakPoint
	//	Return a breakpoint based on global breakpoints
	//
	//	@param bp 			:  string, px
	//
	@function getBreakPoint($bp, $type: 'min') {

		// Check to see if it's a global breakpoint
		@if ($bp == 'xs' or $bp == 'sm' or $bp == 'md' or $bp == 'lg' or $bp == 'xlg' or $bp == 'fhd' or $bp == 'big') {
			$bp: map-get($guards, $bp);
		}

		@if ($type == 'max') {
			$bp: $bp - 1;
		}

		// Get break point value, minus half the gutter width on each side
		// $bp: ($bp - (map-get($outer-grid, gutter) * 1));

		@return $bp;
	}


	//	calculateEm
	//	Convert a px size into EM, based on base font size
	//
	//	@param size 			:  px, em, %
	//	@param base 			:  int for overriding default base size
	//
	@function calculateEm($size, $base: $font-size-base) {
		@return ((int($size) / $base) +em);
	}



	//	readable-to-number
	//	Convert a human readable size to numbers - used within grids
	//
	//	@param size 			:  string // Word size phrases
	//
	@function readable-to-number($size) {

		// Set up human readable spans, that aren't tied to a specific grid
		@if ($size == 'one') {
			$size: 1;
		}
		@else if ($size == 'two') {
			$size: 2;
		}
		@else if ($size == 'three') {
			$size: 3;
		}
		@else if ($size == 'four') {
			$size: 4;
		}
		@else if ($size == 'five') {
			$size: 5;
		}
		@else if ($size == 'six') {
			$size: 6;
		}
		@else if ($size == 'seven') {
			$size: 7;
		}
		@else if ($size == 'eight') {
			$size: 8;
		}
		@else if ($size == 'nine') {
			$size: 9;
		}
		@else if ($size == 'ten') {
			$size: 10;
		}

		// Set up human readable column counts
		@else if ($size == 'fill' or $size == 'fills' or $size == 'whole') {
			$size: 1;
		}
		@else if ($size == 'half' or $size == 'halves') {
			$size: 2;
		}
		@else if ($size == 'third' or $size == 'thirds') {
			$size: 3;
		}
		@else if ($size == 'quarter' or $size == 'quarters') {
			$size: 4;
		}
		@else if ($size == 'fifth' or $size == 'fifths') {
			$size: 5;
		}
		@else if ($size == 'sixth' or $size == 'sixths') {
			$size: 6;
		}
		@else if ($size == 'seventh' or $size == 'sevenths') {
			$size: 7;
		}
		@else if ($size == 'eighth' or $size == 'eighths') {
			$size: 8;
		}
		@else if ($size == 'ninth' or $size == 'ninths') {
			$size: 9;
		}
		@else if ($size == 'tenth' or $size == 'tenths') {
			$size: 10;
		}

		// If not a human readable column count, generate custom width
		@else {
			$size: $size;
		}

		@return $size;
	}


	//	calc-grid-size
	//	Calculate the % width of a column
	//
	//	@param columns 			:  int // Total number of columns
	//	@param span 			:  int // Number of columns to span
	//
	@function calc-grid-size($columns, $span) {
		@return (100% / readable-to-number($columns) * readable-to-number($span));
	}


// ----------------------------------------------------------------------------------------
//	=Mixins - Grid & Media Queries
// ----------------------------------------------------------------------------------------

	//	grid-col
	//	Create a basic grid structure - uses default $vars, but can be overridden
	//
	//	@param span 			:  int // Number of columns to span
	//	@param columns 			:  int // Total number of columns
	//	@param gutter 			:  px // Gutter width between columns
	//	@param gutter-reset 	:  reset, false // Resets the gutter to the gutter var default if called inside a media query
	//
	@mixin grid-col($span, $columns: map-get($outer-grid, columns), $gutter: map-get($outer-grid, gutter), $gutter-reset: false) {

		// Check to see if the grid column is NOT being set in a media query
		// This is likely to be the "initial" size
		@if ($mq-active != true) {
			@extend %grid-base;

			// Check to see if the gutter width is default
			@if ($gutter == (map-get($outer-grid, gutter))) {
				@extend %grid-gutters;
			}
			// If gutter is not default, output specific gutter sizes
			@else {
				@include grid-gutters($gutter);
			}
		}

		// If being set in a MQ, include the grid properties and set gutters (can't extend)
		@else {
			@if ($gutter-reset == reset or $gutter != (map-get($outer-grid, gutter))) {
				@include grid-gutters($gutter);
			}
		}

		// Set the width
		width: (100% / readable-to-number($columns) * readable-to-number($span));

	}


		//	grid-row
		//	Set a negative margin on grid-rows, to account for padding on elements inside
		//	@param gutter 			:  px // Gutter width between columns, defaults to main gutter declaration
		//
		@mixin grid-row($gutter: map-get($outer-grid, gutter)) {

			// Warn if the grid gutters are bigger than the outer-grid default
			@if ($gutter > (map-get($outer-grid, gutter))) {
				@warn "The specified gutter size is bigger than the outer-grid and may cause horizontal-scrolling";
			}

			// Rows should always be initially set on the base level
			@if ($mq-active != true) {

				// Check to see if they're default grid gutters
				@if ($gutter == (map-get($outer-grid, gutter))) {
					@extend %grid-rows;
				}
				@else {
					@include grid-row-gutter($gutter);
				}
				clear: both;

				&:after {
					@extend %clearfix;
				}
			}

			// This row is generated inside a MQ
			@else {
				@include grid-row-gutter($gutter);
			}
		}



		//	grid-container
		//	Set a negative margin on grid-rows, to account for padding on elements inside
		//	@param gutter 			:  px // Gutter width between columns, defaults to main gutter declaration
		//  @param fluid            : bool
		@mixin grid-container($gutter: map-get($outer-grid, gutter), $fluid: false) {

			width: 100%;
			margin: 0 auto;
			clear: both;
			padding-left: calculateRem($gutter/2);
			padding-right: calculateRem($gutter/2);

			$max-width: map-get($outer-grid, max-width);

			@if ($fluid == false) {
				// Generate Media Queries for each breakpoints specified in _vars.scss::$guards
				@each $guard, $value in $guards {
					@include mq($guard) {
						@if($value > $max-width) {
							max-width: calculateRem($max-width);
						}
						@else {
							max-width: calculateRem($value);
						}
					}
				}
			}
			@else {
				// Max Width for largest (which we assume is the last) breakpoint
				max-width: calculateRem(nth($guards, -1));
			}
		}



		//	grid-push
		//	Push an element over in the grid
		//
		//	@param span 			:  int // Number of columns to span
		//	@param columns 			:  int // Total number of columns
		//	@param gutter 			:  px // Gutter width between columns
		//
		@mixin grid-push($span, $columns: map-get($outer-grid, columns)) {
			position: relative;
			left: (calc-grid-size($columns, $span));
		}


		//	grid-pull
		//	Pull an element over in the grid
		//
		//	@param span 			:  int // Number of columns to span
		//	@param columns 			:  int // Total number of columns
		//
		@mixin grid-pull($span, $columns: map-get($outer-grid, columns)) {
			position: relative;
			left: (calc-grid-size($columns, $span) * -1);
		}

		@mixin grid-base() {
			float: left;
			min-height: 1px;
			display: block;
		}

		//	grid-gutters
		//	Generate the gutter sizes
		//
		//	@param gutter 			:  px // Defaults to the outer-grid gutter
		//
		@mixin grid-gutters($gutter: map-get($outer-grid, gutter)) {

			// Warn if the grid gutters are bigger than the outer-grid default
			@if ($gutter > (map-get($outer-grid, gutter))) {
				@warn "The specified gutter size is bigger than the outer-grid and may cause horizontal-scrolling";
			}

			padding-left: (calculateRem($gutter/2));
			padding-right: (calculateRem($gutter/2));
		}

		//	grid-row-gutter
		//	Generate the grid row gutter
		//
		//	@param gutter 			:  px // Defaults to the outer-grid gutter
		//
		@mixin grid-row-gutter($gutter: map-get($outer-grid, gutter)) {

			// Warn if the grid gutters are bigger than the outer-grid default
			@if ($gutter > (map-get($outer-grid, gutter))) {
				@warn "The specified gutter size is bigger than the outer-grid and may cause horizontal-scrolling";
			}

			margin-left: 0;
			margin-right: 0;
		}



	//	mq
	//	Create a media query, defaults to major breakpoints (xs, sm, md, lg) but can be overwritten for specific content areas
	//
	//	@param bp 			:  px, 'xs, sm, md, lg'
	//	@param query1 		:  min !default, max
	//	@param query2 		:  width !default, height
	//
	@mixin mq($bp, $query1: min, $query2: width) {
		$bp: getBreakPoint($bp);

		@media (#{$query1}-#{$query2}: (calculateRem($bp))) {
			$mq-active: true !global;
			@content;
			$mq-active: false !global;
		}
	}

	//	hidden
	//	Hides a perticular element in the given media breakpoint
	//
	//	@param bp 			:  px, 'xs, sm, md, lg'

	@mixin hidden($bp){
		$bp: getBreakPoint($bp);
		display: none;
		@include mq($bp) {
			display: block;
		}
	}

	//	Visible
	//	Shows a perticular element in the given media breakpoint
	//	@param bp 			:  px, 'xs, sm, md, lg'

	@mixin visible($bp){
		$bp: getBreakPoint($bp);
		display: block;
		@include mq($bp) {
			display: none;
		}
	}

	// rem
	// converts the property values from px to rem works only for values like margin, padding etc
	//
	// @param property: 	: css property (margin, padding, font-size etc)
	// @param values: 	: css property (margin, padding, font-size etc)


	//	respond-print
	//	Print specific styles
	//
	@mixin mq-print() {
		@media print {
			@content;
		}
	}


// ----------------------------------------------------------------------------------------
//	=Mixins - Sprites
// ----------------------------------------------------------------------------------------

	//	create-sprite
	//	Adds a sprite image and associated styles
	//
	//	@param sprite 			:  string // Associated with the sprite-var-type in the vars file, defaults to primary
	//
	// @mixin create-sprite($sprite: primary) {

	// 	// Generate placeholder
	// 	%sprite-#{$sprite} {
	// 		background: url(map-get(map-get($sprites, $sprite), image)) no-repeat;
	// 		background-size: (map-get(map-get($sprites, $sprite), size));

	// 		// Fallback sprite
	// 		@include ie8() {
	// 			background-image: url(map-get(map-get($sprites, $sprite), image-fallback));
	// 		}
	// 	}
	// }

		//	sprite-map
		//	Add sprite position
		//	Extends %sprite with _vars $sprite background image
		//
		//	@param x 			:  int // Total number of columns
		//	@param y 			:  px // Gutter width between columns
		//
		// @mixin sprite-map($x, $y, $sprite: primary) {
		// 	@extend %sprite-#{$sprite};

		// 	// Calculate pixel position
		// 	background-position: calc-spriteblock($x, $sprite) calc-spriteblock($y, $sprite);
		// }


// ----------------------------------------------------------------------------------------
//	=Mixins - General
// ----------------------------------------------------------------------------------------


	//	ie8
	//	IE8 specific styling, targets the .lt-ie9 parent selector
	//
	@mixin ie8() {

		// Check for global ie8 support
		@if ($ie8 == true) {
			.lt-ie9 & {
				@content;
			}
		}
	}


	//	format-selected-text
	//	Set colours for text selection
	//
	//	@param colour 			:  string
	//
	@mixin format-selected-text {
		::-moz-selection {
			background: palette(selected-text-background);
			color: palette(selected-text-colour);
			text-shadow: none;
		}

		::selection {
			background: palette(selected-text-background);
			color: palette(selected-text-colour);
			text-shadow: none;
		}
	}


	//	font-size
	//	Set font sizes Rem with px fallback
	//
	//	@param size 			:  int
	//
	@mixin font-size($size) {

		// Check for font-size defaults
		@if ($size == 'xxs' or $size == 'xs' or $size == 'sm' or $size == 'm' or $size == 'l' or $size == 'xl' or $size == 'xxl') {
			$size: font('size-#{$size}');
		}

		// Check to see if we need ie8 support
		@if ($ie8 == true) {
			font-size: $size; // Provide px fallback
		}

		font-size: (calculateRem($size));
	}


	// Import the grid generator
	@import 'mixins/grid';


//  -------------------------------------------------------------
// 	=Placeholders (used with @extend)
//	-------------------------------------------------------------

	//	visuallyhidden
	//	Text shown only for screen readers
	//
	%visuallyhidden  {
		margin: -1px;
		padding: 0;
		width: 1px;
		height: 1px;
		overflow: hidden;
		clip: rect(0 0 0 0);
		clip: rect(0, 0, 0, 0);
		position: absolute;
	}


	//	focusable
	//	Used to reset visuallyhidden
	//
	%focusable {
		&:active,
		&:focus {
			clip: auto;
			height: auto;
			margin: 0;
			overflow: visible;
			position: static;
			width: auto;
		}
	}

	//	clearfix
	//	Clear the parent of floated elements
	//
	%clearfix {
		&:before,
		&:after {
			// content: "";
			display: table;
		}
		&:after {
			clear: both;
		}

		*zoom: 1;
	}


	//	no-wrap
	//	Remove wrapping of white space
	//
	%no-wrap {
		white-space: nowrap;
	}


	//	hide-on-print
	//	Hide an element on print
	//
	%hide-on-print  {
		@include mq-print {
			display: none;
		}
	}


	//	grid-base
	//	Set base grid properties, so they aren't duplicated in the markup
	//
	%grid-base {
		@include grid-base();
	}

	// grid-gutters
	// Set base grid gutters
	//
	%grid-gutters {
		@include grid-gutters();
	}

	// grid-rows
	// Set base negative margins for base gutter sizing
	//
	%grid-rows {
		@include grid-row-gutter();
	}

	%grey-hover{
		background-color: darken(#2b2b2b, 5%);
	}
	%yellow-hover{
		background-color: darken( $yellow-color , 5%);
	}
	%orange-hover{
		background-color: darken($orange-color , 5%);
	}
	%pink-hover{
		background-color: darken($pink-color, 5%);
	}
	// rem
	// Calculates the rem values from given px values and provides a fallback in px
	//
	// @param property  	: css properties such as margin, padding, etc;
	// @param values  		: values in px

	@mixin rem($property, $values...) {
		// Create a couple of empty lists as output buffers.
		$font-size: $font-size-base;
		$px-values: ();
		$rem-values: ();

		// Loop through the $values list
		@each $value in $values {
		    // For each property value, if it's in rem or px, derive both rem and
		    // px values for it and add those to the end of the appropriate buffer.
		    // Ensure all pixel values are rounded to the nearest pixel.
		    @if $value == 0 or $value == 0px {
		        // 0 -- use it without a unit
		        $px-values: join($px-values, 0);
		        $rem-values: join($rem-values, 0);
		    } @else if type-of($value) == number and not unitless($value) and (unit($value) == px) {
		        // px value given - calculate rem value from font-size
		        $px-values: join($px-values, $value);
		        $rem-values: join($rem-values, ((int($value) / $font-size) + rem));
		    } @else if $value == auto {
		        // auto - don't add px or rem
		        $px-values: join($px-values, auto);
		        $rem-values: join($rem-values, auto);
		    } @else {
		        // unitless value - use those directly as rem and calculate the px-fallback
		        $px-values: join($px-values, round($value * $font-size));
		        $rem-values: join($rem-values, #{$value}rem);
		    }
    	}

		// output the converted rules
		#{$property}: $px-values;
		#{$property}: $rem-values;
	}

	//	center
	//	Vertically center specified height element
	//
	//	@param offset-top 	:  px, em
	//	@param offset-left 	:  px, em
	//
	@mixin center($offset-top, $offset-left) {
		top: 50%;
		left: 50%;
		margin-top: $offset-top * -1;
		margin-left: $offset-top * -1;
	}


	//	dead-center
	//	Vertically and horizontally align a responsive element - doesn't work in IE8
	//
	@mixin dead-center() {
		@include transform(translate(-50%, -50%));
		position: absolute;
		top: 50%;
		left: 50%;
	}


	//	css-arrow
	//	Create solid css arrows
	//
	//	@param direction 	:  string
	//	@param size 		:  px, rem
	//	@param colour 		:  string
	//	@param type 		:  string : solid, outlined !optional
	//
	@mixin css-arrow($direction, $size: .5em, $colour: palette(brand-colour), $type: solid) {

		// Generate solid arrows
		@if ($type == 'solid') {
			width: 0;
			height: 0;
			border-style: solid;
			display: inline-block;

			@if ($direction == 'up') {
				border-width: 0 $size $size $size;
				@include ie8() {
					border-color: transparent transparent $colour transparent; // Falback for non RGBA browsers
				}
				border-color: setRGBA($colour, 0) setRGBA($colour, 0) setRGBA($colour, 1) setRGBA($colour, 0);
			}

			@if ($direction == 'right') {
				border-width: $size 0 $size $size;
				@include ie8() {
					border-color: transparent transparent transparent $colour; // Falback for non RGBA browsers
				}
				border-color: setRGBA($colour, 0) setRGBA($colour, 0) setRGBA($colour, 0) setRGBA($colour, 1);
			}

			@if ($direction == 'down') {
				border-width: $size $size 0 $size;
				@include ie8() {
					border-color: $colour transparent transparent transparent; // Falback for non RGBA browsers
				}
				border-color: setRGBA($colour, 1) setRGBA($colour, 0) setRGBA($colour, 0) setRGBA($colour, 0);
			}

			@if ($direction == 'left') {
				border-width: $size $size $size 0;
				@include ie8() {
					border-color: transparent $colour transparent transparent; // Falback for non RGBA browsers
				}
				border-color: setRGBA($colour, 0) setRGBA($colour, 1) setRGBA($colour, 0) setRGBA($colour, 0);
			}
		}

		// Generate bordered/outlined arrows, not IE8 compatible
		@if ($type == 'outlined') {
			width: $size;
			height: $size;
			border: ($size/2) solid $colour;
			border-bottom: 0;
			border-left: 0;
			display: inline-block;

			// Warn if the grid gutters are bigger than the outer-grid default
			@if ($ie8 == true) {
				@warn "This type of arrow uses transforms, which are not supported in IE8";
			}

			@if ($direction == 'up') {
				@include transform( rotate( -45deg ) );
			}

			@if ($direction == 'right') {
				@include transform( rotate( 45deg ) );
			}

			@if ($direction == 'down') {
				@include transform( rotate( 135deg ) );
			}

			@if ($direction == 'left') {
				@include transform( rotate( -135deg ) );
			}
		}

	}

	/// Triangle helper mixin
	/// @param {Direction} $direction - Triangle direction, either `top`, `right`, `bottom` or `left`
	/// @param {Color} $color [currentcolor] - Triangle color
	/// @param {Length} $size [1em] - Triangle size
	@mixin triangle($direction, $color: palette(brand-colour), $width: 1em, $height: 1em) {
		@if not index(top right bottom left, $direction) {
			@error "Direction must be either `top`, `right`, `bottom` or `left`.";
		}

		width: 0;
		height: 0;
		content: '';
		z-index: 2;

		$perpendicular-borders: $width/2 solid transparent;

		@if $direction == top or $direction == bottom {
			border-left:   $perpendicular-borders;
			border-right:  $perpendicular-borders;
		}
		@else if $direction == right or $direction == left {
			border-bottom: $perpendicular-borders;
			border-top:    $perpendicular-borders;
		}

		@if $direction == top {
			border-bottom: $height solid $color;
		}
		@else if $direction == bottom {
			border-top: $height solid $color;
		}
		@else if $direction == left {
			border-right: $height solid $color;
		}
		@else if $direction == right {
			border-left: $height solid $color;
		}
	}
	/**
		Home page- Tyson mixins
	*/
	@mixin transform($transformation){
		transform: $transformation;
		-ms-transform: $transformation;
		-webkit-transform: $transformation;
	}
	@mixin transition($property: all, $duration: 0.5s, $easing: ease-in) {
		transition: $property $duration $easing;
		-o-transition: $property $duration $easing;
		-ms-transition: $property $duration $easing;
		-moz-transition: $property $duration $easing;
		-webkit-transition: $property $duration $easing;
	}
	@mixin transition-complex($transition) {
		transition: $transition;
		-o-transition: $transition;
		-ms-transition: $transition;
		-moz-transition: $transition;
		-webkit-transition: $transition;
	}
	@mixin vertical-center(){
		position: relative;
		top: 50%;
		@include transform(translateY(-50%));
	}
	@mixin horizontal-center () {
		position: relative;
		left: 50%;
		@include transform(translateX(-50%));
	}
	@mixin deadCenter () {
		@include transform(translate(-50%, -50%));
		left: 50%;
		position: absolute;
		top: 50%;
	}

  @mixin play-button($color: $white, $hover: $orange) {
	fill: $color;
	@include transition(0.3s ease-out fill);

	&:hover {
	  fill: $hover;
	  opacity: 1;
	}
  }