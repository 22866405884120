.awo-desktop-search {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 10rem;
  background-color: $awo-black-jet-dark;
  margin-top: 4rem;
  padding: 8rem 0;
  z-index: 4;
  opacity: 0;
  max-height: 0;
  transition: all 120ms ease-in-out;
  transform-origin: top;
  transform: scaleY(0);

  @media screen and (max-width: $top-nav-max-width) {
    display: none;
  }

  &.is-active {
    opacity: 1;
    max-height: 100%;
    transition: all 120ms ease-in-out;
    transform: scaleY(1);
  }

  .container {
    max-width: 880px;
    padding-left: 7rem;

    form {
      input {
        border-bottom: 1px solid $awo-gray-spanish;
        padding: 16px 0;
        font-family: $lato;
        font-size: 28px;
        color: $white;
        background: transparent;
        border-radius: 0;
      }
    }
  }
}
