.awo-cc-ep {
    .awo-cc-ep__gbl {
        margin-top: 5rem;
    }
    img.work-with-us-img {
        display: block;
        margin-left: auto;
        min-height: auto;
        width: 75%;
    }
}
