.awo-cc-wwd {
    .awo-cc-wwd__carousel {
        margin-top: 50px;
        .awo-itc:first-child {
            margin-bottom: 3.25rem;
            @include mq(sm) {
                margin-bottom: 5.25rem;
            }
        }
    }
}
