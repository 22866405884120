/* HOME */
@media all and (max-width: 1675px){
    .donate-dots-bg-wrapper{
        width: 200px;
        height: 240px;
        background-size: contain;
    }
    .news-left-bird{
        width: 125px;
        left: -130px;
    }
    .main .top-left-pattern,   .main .bottom-right-pattern{
        width: 125px;
    }
    .atm:not(.atm--home) h1.three-lines{
        font-size: 2.3vw;
    }
}
@media all and (max-width: 1400px) {
    .atm--home {
        padding-bottom: 50vw;
    }
    .donate-dots-bg-wrapper, .donate-small-bird,.home-why-bird,.shop-dots  {
        display: none;
    }
    .footer-bird{
        right: 0px;
    }
    .news-left-bird, .news-dots{
        display: none;
    }
    .atm:not(.atm--home) h1.two-lines{
        font-size: 2.5vw;
    }
    .atm:not(.atm--home) h1.three-lines{
        font-size: 1.7vw;
    }
    .atm:not(.atm--home) .container{
        padding-bottom: 80px;
    }
}
@media all and (max-width: 1300px) {
    .header__logo {
        margin-right: 30px;
    }
    .shop-dots-patterns{
        width: 250px;
    }
}
@media all and (max-width: 1250px) {
    .header__nav>ul>li:not(:last-child) {
        padding-right: 12px;
    }
    .contact-touch .large-dots-wrapper{
       width: 404px;
       height: 667px;
       bottom: 50px;
       background-size: contain;
    }
}
@media all and (max-width: 1366px) {
    .header__nav-mobile {
        display: block;
    }
    .header__buttons {
        margin-right: 20px;
    }
    .header__burger {
        display: flex;
    }
    .header__search {
        display: none;
    }
    .header {
        z-index: 3000;
    }
    .megamenu__overlay.active {
        display: none;
    }
    .header__nav-hidden .megamenu {
        flex-direction: column;
    }
    .header__nav-hidden .megamenu li {
        flex-basis: 100%;
        line-height: 1.3;
    }
    .header__nav-hidden {
        width: 100%;
    }
    .header__nav {
        position: fixed;
        right: 0;
        top: 0;
        width: 90%;
        background: #fff;
        max-width: 300px;
        padding-top: 150px;
        z-index: 50;
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 20px;
        height: 100vh;
        overflow: auto;
        overflow-y: scroll;
        opacity: 0;
        visibility: hidden;
        transition: 120ms ease-in-out;
        &.active {
            opacity: 1;
            visibility: visible;
        }
    }
    .header__nav>ul>li {
        display: block;
    }
    .header__nav>ul>li:not(:last-child) {
        padding-right: 0;
    }
    .header__nav>ul>li>a {
        color: $main-color;
        text-transform: uppercase;
        font-size: 20px;
        padding-top: 22px;
        padding-bottom: 22px;
        display: inline-block;
        &.active {
            color: $orange-color;
        }
    }
    .header__nav-hidden {
        position: relative;
        left: 0;
        top: 0;
        padding-top: 0;
        opacity: 1;
        visibility: visible;
        height: 0;
        overflow: hidden;
        &.active {
            height: auto;
            padding-bottom: 30px;
        }
    }
    .header__nav-hidden .megamenu li a {
        color: $main-color;
    }
    .header__nav-hidden .megamenu {
        padding-bottom: 10px;
    }
    .header__right {
        padding-top: 0;
    }
    .header .container {
        align-items: center;
    }
    .header__buttons a:not(:last-child) {
        margin-right: 0;
    }
    //a.header__donate {
    //    display: none;
    //}
    a.header__order {
        display: none;
    }
    .header__logo {
        width: 240px;
        height: 63px;
        background-image: url('/img/mobile-logo.png');
        margin-right: 0;
    }
    .header__nav.mobile .nav__item-1.has-children .header__nav-arrow{
        display: inline-block;
    }
    a.header__order {
        border: 2px solid #fff;
        background-color: transparent;
    }
    .atm--home {
        padding-bottom: 80px;
        height: 600px;
    }
    .atm:not(.atm--home) h1.three-lines{
        font-size: 2.5vw;
    }
    .atm:not(.atm--home) h1.two-lines{
        font-size: 4vw;
    }
}
@media all and (max-width: 1200px){
    .camp-donate-left-bg-wrapper,  .camp-donate-right-bg-wrapper, .main .top-left-pattern,   .main .bottom-right-pattern{
        display: none;
    }
}
@media all and (max-width: 1286px) {
    .header {
        z-index: 20;
    }
}
@media all and (max-width: 1024px) {
    .atm--home h1 {
        font-size: 90px;
    }
    .atm--home h2 {
        text-align: center;
    }
    .home-what__list {
        flex-direction: column;
    }
    .home-what__item {
        width: 100%;
        display: flex;
        padding: 30px;
        align-items: center;
        &:not(:last-child) {
            margin-bottom: 20px;
        }
    }
    .home-what__item-pic {
        position: relative;
        margin-right: 40px;
        left: 0;
        top: 0 !important;
        transform: translate(0,0);
    }
    .home-cats__list {
        margin-bottom: -20px;
    }
    .home-cats__item {
        width: calc( (100% - 20px) / 2 );
        margin-bottom: 20px;
        height: 24vw;
        text-align: center;
    }
    .home-why__list {
        flex-wrap: wrap;
        justify-content: space-around;
    }
    .home-why__item {
        flex-basis: 35%;
        margin-bottom: 40px;
    }
    .home-why__item:not(:last-child) {
        margin-right: 0;
    }
    .home-links__item {
        width: 100%;
        height: 30vw;
        text-align: center;
    }
    .home-news__item--big {
        display: none;
    }
    .home-news__list {
        flex-direction: column;
    }
    .home-news__item {
        width: 100%;
    }
    .home-numbers__list {
        flex-direction: column;
        height: 540px;
    }
    .home-numbers__item {
        flex-basis: 100%;
        width: 100%;
        margin-bottom: 30px;
    }
    .home-shop__list {
        flex-wrap: wrap;
    }
    .home-shop__item {
        width: calc( (100% - 20px) / 2 );
        margin-bottom: 20px;
        color: #424242;
    }
    .home-shop__item:not(:last-child) {
        margin-right: 0;
    }
    .footer-middle .col:nth-child(1), .footer-middle .col:nth-child(2) {
        display: none;
    }
    .footer-bot .row:first-child {
        display: flex;
        flex-direction: column;
        justify-content: center;
        .footer__copy {
            margin-bottom: 25px;
        }
    }
    .footer::before {
        top: -3vw;
    }
    .footer-middle .col:nth-child(3) {
        flex-basis: 30%;
    }
    .contact-touch .large-dots-wrapper{
        display: none;
    }
    .donate-large-bird,.donate-small-bird{
        display: none;
    }
    .shop-dots-patterns{
        display: none;
    }
    .footer-bird{
        top: 0;
        width: 150px;
    }
}
/* CONTENT */
@media all and (max-width: 1024px) {
    .atm:not(.atm--home) {
        height: 300px;
        .container {
            padding-bottom: 50px;
        }
    }
    .atm:not(.atm--home) h1 {
       white-space: nowrap;
       overflow: hidden;
       vertical-align: bottom;
       line-height: 60px;
       text-overflow: ellipsis;
       max-width: 100%;
    }
    .content-page blockquote {
        padding-left: 30px;
        padding-right: 30px;
    }
    .content-page .document {
        flex-direction: column;
    }
    .content-page .document__link {
        width: 100%;
        margin-top: 20px;
    }
    .content-page .document__content {
        padding-right: 0;
    }
    .contact-locations .container{
        background: none;
    }
}
@media all and (max-width: 768px) {
    .content-page a.video-preview::before {
        width: 70px;
        height: 68px;
    }
    .content-gallery__item {
        width: calc( (100% - 18px) / 2);
        height: 25vw;
    }
    .content-page .accordeon {
        height: auto;
    }
    .content-page .accordeon__content {
        height: 0;
        padding: 0;
        overflow: hidden;
    }
    .content-page .accordeon__title .down {
        right: 25px;
    }
    .content-page .accordeon__title {
        padding: 18px 65px 18px 25px;
        font-size: 20px;
    }
    .content-page .accordeon.active {
        .accordeon__content {
            height: auto;
            padding: 40px 25px 30px 25px;
            overflow: hidden;
        }
    }
    .footer-top__title {
        text-align: center;
    }
    form.footer__form {
        flex-direction: column;
        input, button {
            width: 100%;
            flex-basis: 100%;
            max-width: 400px!important;
            margin-bottom: 20px;
            padding: 20px 27px;
        }
        button {
            margin-bottom: 0;
            padding: 21px 20px 27px 20px;
        }
    }
    .footer-middle {
        justify-content: center;
    }
    .footer-middle .col:nth-child(3) {
        text-align: center;
        flex-basis: 100%;
    }
    .footer__socials {
        justify-content: center;
        a:not(:last-child) {
            margin-right: 10px;
        }
    }
    .footer-bot .row:nth-child(2) {
        flex-direction: column;
    }
    .footer-middle {
        margin-bottom: 20px;
    }
    .footer-top {
        margin-bottom: 20px;
    }
    .footer__copy {
        margin-right: 0;
        margin-bottom: 20px;
    }
    .payment__methods .payment__method-button{
        float: none;
        width: 100%;
        &:first-child{
            margin-bottom: 20px;
        }
    }
    .payment__details-wrapper .credit__card_details-section-2 {
        .input-group{
            max-width: 768px;
        }
        .credit__card_details-number{
            width: 100%;
        }
        .credit__card_details-expiry-date,.credit__card_details-cvv{
            width: 48%;
        }
    }
    .donate__action-buttons .donform__tab{
            width: 100%;
            margin: 0 0 20px 0;
    }
    .shop__pagination {
        margin-top: 65px;
    }
   .footer-bird{
        display: none;
    }
    .home-what-bird{
        display: none;
    }
}
@media all and (max-width: 500px) {
    .header__logo {
        background: url("/img/mobile-logo.png")!important;
        background-repeat: no-repeat!important;
        background-position: center!important;
        width: 150px!important;
        height: 40px!important;
        background-size: contain!important;
    }
    .home-cats__item {
        width: 100%;
        height: 40vw;
    }
    .home-links__item {
        height: 40vw;
    }
    .payment__details-wrapper .credit__card_details-section-2 {
        .credit__card_details-expiry-date,.credit__card_details-cvv{
            width: 100%;
            margin-right: 0;
        }
    }
}
/* SHOP */
@media all and (max-width: 1024px) {
    .shop-sidebar {
        display: none;
    }
}
@media all and (max-width: 768px) {
    .shop__item {
        width: calc( (100% - 20px) / 2 );
        margin-bottom: 20px;
    }
}
/* DONATE */
@media all and (max-width: 1200px) {
    .donate__amount-item {
        font-size: 25px;
        height: 60px;
    }
    .donate__amount-item--other input[type=number] {
        font-size: 25px;
    }
}
@media all and (max-width: 1024px) {
    .donate__amount-wrapper {
        flex-wrap: wrap;
    }
    .donate__amount-item {
        width: calc( (100% - 60px) / 3 );
        margin-bottom: 20px;
    }
}
/* CAMPAIGN */
@media all and (max-width: 1600px) {
    .atm:not(.atm--home).atm--camp h1 {
        font-size: 120px;
    }
    .atm:not(.atm--home).atm--camp .atm__donate {
        font-size: 40px;
    }
}
@media all and (max-width: 1200px) {
    .camp-story__content {
        padding-top: 70px;
        padding-bottom: 70px;
        width: 100%;
        padding-left: 20px;
        padding-right: 20px;
    }
    .camp-story__content h3 {
        font-size: 25px;
        margin-bottom: 20px;
    }
    .camp-story__content p {
        font-size: 14px;
    }
    .atm:not(.atm--home).atm--camp h1 {
        font-size: 60px;
    }
    .atm  .slick-slide > div > div .container{
        justify-content: flex-end;
        align-items: center!important;
    }
    .atm .hight-light{
        margin-bottom: 80px;
        text-align: center;
    }
    .cart__details td.quantity{
        .remove{
            display: block;
            width: 100%;
            margin-top: 30px;
            margin-left: 0;
        }
    }
}
@media all and (max-width: 1024px) {
    .atm:not(.atm--home).atm--camp .atm__donate {
        display: none;
    }
    .atm:not(.atm--home).atm--camp {
        padding-bottom: 0;
        height: 400px;
    }
}
@media all and (max-width: 500px) {
    .camp-story__pic {
        display: none;
    }
    .camp-story__right {
        flex-basis: 100%;
    }
}
/* SERVICES */
@media all and (max-width: 1024px) {
    .services__list,.news-small{
        justify-content: space-between;
    }
    .news-small__item{
        width: calc( ( 100% - 20px ) / 2 );
        margin-right: 15px;
    }
}
@media all and (max-width: 768px) {
    .services__item {
        height: auto;
        padding-bottom: 30px;
    }
}
/* NEWS / EVENTS*/
@media all and (max-width: 1024px) {
    .news-large__item-content, .events-large__item-content {
        flex-basis: 100%;
    }
    .news-large__item-pic {
        display: none;
    }
    .events-large__item-pic {
        height: 280px;
    }
    .events-large__item {
        border-radius: 18px;
        display: block;
    }
    .news-filters {
        background-image: none;
        padding: 40px 35px;
    }
    .news-filters__form {
        flex-wrap: wrap;
    }
    .news-filters__form select:nth-child(1), .news-filters__form select:nth-child(2), .news-filters__form button {
        margin-bottom: 20px;
        flex-basis: 100%;
        max-width: 100%!important;
    }
    .news-filters__form button {
        margin-bottom: 0;
    }
}
@media all and (max-width: 768px) {
    .news-large__item,.events-large__item{
        border-radius: 18px;
    }
    .news-large__item-title::after,.events-large__item-title::after {
        width: 100%;
    }
    .news-small, .events-small {
        flex-wrap: wrap;
        justify-content: center;
    }
    .news-small__item, .events-small__item {
        width: 100%;
        flex-basis: 100%;
        margin-bottom: 20px;
        margin-right: 0;
        border-radius: 18px;
    }
    .news-small__item-pic, .events-small__item-pic{
        border-radius: 18px 18px 0px 0px;
    }
}
/* CONTACT */
@media all and (max-width: 1024px) {
    .contact-locations__box {
        flex-direction: column-reverse;
        align-items: center;
    }
    .contact-locations__map {
        margin-bottom: 20px;
        width: 100%;
        max-width: 500px;
    }
    .contact-locations__list {
        width: 100%;
        max-width: 500px;
    }
    .contact-locations h3{
        text-align: center;
    }
}
@media all and (max-width: 992px){
    .line__item-quantity{
        padding: 10px 58px 10px 20px;
    }
    .cart__details .line__item-image-title-wrapper > p{
        font-size: 18px;
    }
    .cart{
        .cart__details .line__item-image-title-wrapper > p{
            padding-left: 15px;
        }
        .cart__details td.quantity, .cart__details td.price{
           flex: 0 1 20%;
           font-size: 18px;
        }
    }
    .cart__summary{
        td.cost{
            font-size: 18px;
        }
    }
}
@media all and (max-width: 940px){
    .product__details-add-to-cart button{
        padding: 13px 30px;
    }
}
@media all and (max-width: 768px) {
    .contact-locations__item-title {
        font-size: 15px;
        padding-left: 10px;
    }
    .contact-locations__item-title .down {
        font-size: 20px;
    }
    .contact-locations__item.active .contact-locations__item-title .down {
        top: 5px;
    }
    .contact-locations__item-title .down {
        right: 10px;
    }
    .contact-locations__item {
        height: 51px;
    }
    .contact-locations__item-content {
        padding: 40px 20px 20px 20px;
    }
    .contact-locations__item-content .row {
        font-size: 14px;
    }
    .atm .hight-light{
        margin-bottom: 50px;
        text-align: center;
    }
    .product__details-pic, .product__details-info{
        width: 100%;
        float: none;
        max-width: 100%;
    }
    .product__details-pic{
        padding-right: 0;
    }
    .product__details-info{
        padding-left: 0;
        padding-right: 0;
    }
    .product__details-add-to-cart button{
        padding: 13px 80px;
    }
    .general__el-photo{
        position: absolute;
        top: 20px;
    }
    .home-what{
        padding-bottom: 30px;
    }
    .donate__amount-item {
        width: 100%;
        &--other input[type=number]{
            font-size: 20px;
            text-align: center;
            width: 70px;
        }
    }
    .cart__details td.price{
        padding-right: 0;
    }
}
@media all and (max-width: 667px){
    .staff__el{
        .figure{
            float: none;
        }
        &-text{
            padding-left: 0;
        }
    }
}
@media all and (max-width: 568px){
     .general__el-photo{
        display: block;
        position: relative;
    }
   .general__el-text{
        padding-top: 15px;
   }
   .general__el.item-figure{
        padding: 0!important;
    }
    .cart{
        .cart__details td.line__item-image-title,.cart__details th:nth-of-type(1){
            width: 40%;
        }
        .cart .cart__details td.quantity, .cart .cart__details td.price,.cart__details th:nth-of-type(2),.cart__details th:nth-of-type(3){
            width: 30%;
        }
        .line__item-image-title{
            img{
                 display: none;
            }
        }
       .cart__details .line__item-image-title-wrapper > p{
            padding-left: 0px;
       }
    }
    .cart__summary{
        td{
            &.blank{
                width: 40%;
            }
            &.title{
                width: 30%;
            }
            &.cost{
                width: 30%;
            }
            &.checkout-btn{
                padding-top: 30px;
            }
        }
    }
}
@media all and (max-width: 480px){
    .atm--home h1 {
        font-size: 70px;
        word-break: break-word;
    }
    .product__details-add-to-cart input{
        max-width: 100px;
    }
    .product__details-add-to-cart button{
        padding: 13px 25px;
    }
    .product__details-description, .product__details-sizes{
        margin-top: 25px;
    }
    .product__details-description{
        margin-bottom: 25px;
    }
    .services__item{
        padding-left: 15px;
        padding-right: 15px;
        font-size: 25px;
    }
    form.footer__form {
        button {
            margin-bottom: 0;
            padding: 21px 20px 31px 20px;
        }
    }
    .home-what{
        padding-bottom: 0;
    }
    .cart{
        th{
            display: none;
        }
        .cart__details .line__item-image-title-wrapper > p{
            padding-left: 0px;
            line-height: 20px;
        }
        td.line__item-image-title{
            width: 100% !important;
            display: block;
        }
        .cart__details td.quantity,  .cart__details td.price{
            width: 50%;
            display: block;
            float: left;
        }
        .cart__details td{
            padding: 15px 20px;
        }
    }
    .cart__summary{
        td{
            &.blank{
                display:none;
                width: 0;
            }
            &.title{
                width: 50%;
            }
            &.cost{
                width: 50%;
            }
            &.checkout-btn{
                padding-top: 20px;
            }
        }
    }
    .atm:not(.atm--home) h1 {
      font-size: 30px!important;
    }
    .home-numbers__list {
        height: 660px;
    }
}
