.awo-sa {
    position: relative;
    .awo-sa__title {
        color: #404040;
        font-family: $lu;
        font-size: 36px;
        line-height: 40px;
        font-weight: 400;
        margin-bottom: 3.5rem;
        @include mq(sm) {
            font-size: 45px;
            line-height: 55px;
        }
    }
    .awo-sa__container {
        margin: auto;
    }
    .awo-sa__item-container {
        border-top: 2px solid rgba(141, 83, 141, 0.30);
    }
    .awo-sa__step-item-container {
        dt {
            button.awo-sa__item-title {
                padding-left: 90px;
            }
        }
    }
    .awo-sa__item {
        border-bottom: 2px solid rgba(141, 83, 141, 0.30);
        dt {
            display: flex;
            align-items: center;
            min-height: 6rem;
            position: relative;
            @include mq(sm) {
                min-height: 6.25rem;
            }
            .awo-sa__item-title-wrapper {
                position: relative;
            }
            .awo-sa__item-number {
                align-items: center;
                background-color: #8D538D;
                border-radius: 100%;
                display: flex;
                height: 40px;
                justify-content: center;
                left: 0;
                position: absolute;
                pointer-events: none;
                top: 50%;
                transform: translateY(-50%);
                width: 40px;
                span {
                    color: #fff;
                    line-height: 1.5rem;
                    font-family: $lu;
                    font-size: 1.5rem;
                    font-weight: 400;
                    margin-bottom: -5px;
                }
                @include mq(sm) {
                    height: 67px;
                    width: 67px;
                    span {
                        font-size: 35px;
                        line-height: 43px;
                        margin-bottom: -10px;
                    }
                }
            }
            .awo-sa__item-title {
                background: transparent;
                border: none;
                color: #404040;
                font-family: $lu;
                font-size: 1.5rem;
                line-height: 1.5rem;
                font-weight: 400;
                padding: 1rem 3.25rem 1rem 4rem;
                margin-bottom: -10px;
                text-align: left;
                width: 100%;
                @include mq(sm) {
                    font-size: 32px;
                    line-height: 40px;
                }
                span {
                    display: inline-block;   
                    width: 85%;
                }
            }
            .awo-sa__item-title.awo-no-padding-left {
                padding-left: 0;
            }
            .awo-sa__item-icon {
                display: flex;
                align-items: center;
                justify-content: center;
                position: absolute;
                right: 0;
                top: 0;
                height: 100%;
                gap: 8px;
                margin-right: 1.5rem;
                background: transparent;
                border: none;
                pointer-events: none;
                .awo-sa__item-icon-plus {
                    position: absolute;
                    right: 0;
                    transition: all 300ms ease-in-out;
                    svg {
                        width: 20px;
                        height: 20px;
                        @include mq(sm) {
                            width: 26px;
                            height: 26px;
                        }
                    }
                }
                .awo-sa__item-icon-minus {
                    display: flex;
                    align-items: center;
                    gap: 1rem;
                    position: absolute;
                    right: 0;
                    opacity: 0;
                    transition: all 300ms ease-in-out;
                    span {
                        text-transform: uppercase;
                        color: #faa61a;
                        font-family: $lato;
                        font-size: 14px;
                        line-height: 9px;
                        font-weight: 700;
                        display: none;
                        @include mq(sm) {
                            display: block;
                        }
                    }
                    span:nth-child(2) {
                        margin-bottom: 6px;
                        display: block;
                    }
                    svg {
                        width: 20px;
                        height: 4px;
                        @include mq(sm) {
                            width: 26px;
                            height: 4px;
                        }
                    }
                }
            }
        }
        dd {
            height: 0;
            overflow: hidden;
            transition: all 300ms ease-in-out;
            .awo-sa__item-content {
                max-width: 85%;
                padding-top: 1.25rem;
                p {
                    text-align: left;
                }
            }
            .awo-gbl {
                padding-top: 2.5rem;
                padding-bottom: 3.75rem;
            }
            .awo-sa__item-content-close {
                align-items: center;
                display: flex;
                justify-content: flex-end;
                padding-bottom: 2.25rem;
                padding-top: 2.25rem;
                position: relative;
                button {
                    background: transparent;
                    border: none;
                    margin-right: 1.25rem;
                    .awo-sa__item-icon-minus {
                        display: flex;
                        align-items: center;
                        gap: 1rem;
                    }
                }
                span {
                    text-transform: uppercase;
                    color: #faa61a;
                    font-family: $lato;
                    font-size: 14px;
                    line-height: 9px;
                    font-weight: 700;
                    display: block;
                }
                span:nth-child(2) {
                    margin-bottom: 6px;
                }
                svg {
                    width: 20px;
                    height: 4px;
                    @include mq(sm) {
                        width: 26px;
                        height: 4px;
                    }
                }
            }
        }
    }
    .awo-sa__item.is-active {
        .awo-sa__item-icon-plus {
            opacity: 0;
        }
        .awo-sa__item-icon-minus {
            opacity: 1 !important;
        }
    }
}