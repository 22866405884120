.atm {
    position: relative;
    &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgb(0,0,0);
        background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.80) 100%);
    }
    .awo-home-hero-white-banner {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        z-index: 2;
        margin-bottom: -0.25rem;
        @include mq(fhd) {
            margin-bottom: -2rem;
        }
        svg {
            fill: $awo-white;
        }
    }
    .slick-list,
    .slick-track,
    .slick-slide,
    .slick-slide > div {
        height: 100%;
    }
    .slick-slide > div > div {
        height: 100%;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        .container {
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }
    }
    .slick-dots {
        width: 100%;
        position: absolute;
        left: 0;
        bottom: 80px;
        width: 100%;
        font-size: 0;
        text-align: center;
        li {
            display: inline-block;
            vertical-align: middle;
            &:not(:last-child) {
                margin-right: 25px;
            }
            button {
                font-size: 0;
                width: 16px;
                height: 16px;
                border-radius: 50%;
                border: 1px solid #fff;
                background: transparent;
                transition: 120ms ease-in-out;
            }
            &.slick-active button {
                background-color: $yellow-color;
                border-color: $yellow-color;
            }
        }
    }
    &.-contact {
        padding-bottom: 60px;
        .awo-home-hero-white-banner svg {
            fill: $yellow-color-light;
        }
    }
}
.atm:not(.atm--home) {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 32.5vw;
    min-height: 26rem;
    overflow: hidden;
    z-index: 1;
    &.reveal {
        transform: translate(0);
        img {
            transform: scale(1.15);
            transform: translate3d(0, 2rem, 0);
            filter: blur(4px);
            transition: transform 500ms ease, filter 150ms ease 400ms;
        }
        &.revealed {
            img {
                transform: scale(1);
                filter: blur(0);
            }
        }
    }
    @include mq(sm) {
        min-height: 36rem;
    }
    .container {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        // justify-content: flex-end;
        // padding-bottom: 90px; // offset for the white curve svg
        // padding-bottom: 6.1vw;
    }
    img {
        position: absolute;
        left: 0;
        top: 0;
        object-position: center;
        object-fit: cover;
        width: 100%;
        height: 100%;
    }
    h1 {
        color: #ffffff;
        font-family: $lu;
        font-size: 60px;
        font-weight: 400;
        letter-spacing: 1.42px;
        margin-bottom: 5px;
        &.two-lines{
            font-size: 3vw;
        }
        &.three-lines{
            font-size: 2.5vw;
        }
    }
}
.atm--home {
    background: #fff;
    position: relative;
    width: 100%;
    padding-bottom: 40vw;
    h1 {
        text-align: center;
        color: #ffffff;
        font-family: $lu;
        font-size: 80px;
        font-weight: 400;
        letter-spacing: 2.8px;
        margin-bottom: 25px;
    }
    h2 {
        color: #ffffff;
        font-family: $lato;
        font-size: 23px;
        font-weight: 400;
        line-height: 1.35;
        color: #ffffff;
        letter-spacing: 0.15px;
        max-width: 600px;
        margin: 0 auto 40px auto;
    }
    .hight-light{
        margin-bottom:0px;
        text-align: center;
    }
}
.atm__slider {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
}
.atm__breadcrumbs,
.atm__breadcrumbs--donate {
    color: #ffffff;
    font-size: 14px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0.49px;
    a {
        color: #fff;
    }
}
.content-page {
    .atm__breadcrumbs--donate {
        a {
            color: #fff;
            text-decoration: none;
        }
    }
}
.atm:not(.atm--home).atm--camp {
    padding-bottom: 58vw;
    h1 {
        font-size: 158px;
        letter-spacing: 3.75px;
        text-align: center;
    }
    .atm__donate {
        display: inline-block;
        padding: 53px 40px;
        font-size: 77px;
        color: #fff;
        border-radius: 20px;
        position: relative;
        background: $yellow-color url('/img/svg/icon-donate.svg') no-repeat center top 16px;
        background-size: 59px 43px;
        font-family: $lu;
        margin-bottom: 50px;
        span {
            position: absolute;
            left: 50%;
            bottom: 5px;
            transform: translate(-50%,0);
            font-size: 42px;
        }
    }
}
