.awo-looking-for__dropdown {
  max-width: 40rem;
  margin-bottom: 1.5rem;
  margin-top: 1rem;

  .selectize-dropdown {
    background-color: $awo-black-jet;
    border: 1px solid $awo-black-jet;
    border-radius: 10px;
    padding: 2.25rem 2.5rem;

    .option {
      background-color: transparent;
      color: $awo-white;
      cursor: pointer;
      font-size: 16px;
      line-height: 20px;
    }

    .active {
      background-color: transparent;
      color: $awo-sunglow;
    }
  }

  .selectize-dropdown-content {
    max-height: 500px;
  }

  .selectize-input {
    border: 0;
    border-bottom: 2px solid $awo-yellow-bright-crayola;
    box-shadow: none;

    input {
      font-size: 18px;
      line-height: 29px;
      top: -10px;

      @include mq(sm) {
        font-size: 42px;
        line-height: 42px;
      }

      &::placeholder {
        opacity: 1;
      }
    }
  }

  .selectize-input.full {
    background-color: transparent;
    border: 0;
    border-bottom: 2px solid $awo-yellow-bright-crayola;
    border-radius: 0;
    box-shadow: none;
    padding-left: 0;
    padding-top: 0;

    .item {
      font-size: 18px;
      line-height: 29px;

      @include mq(sm) {
        font-size: 36px;
        line-height: 60px;
        max-width: 38rem;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }

  .selectize-input {
    background-color: transparent !important;
  }

  .selectize-input::after {
    height: 0.45rem !important;
    width: 0.75rem !important;
    margin: 0 !important;
    border: 0 !important;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 239.61 139.85'%3E%3Cg data-name='Layer 2'%3E%3Cpath style='fill:none;stroke:%23f7a824;stroke-linecap:square;stroke-miterlimit:10;stroke-width:28.35px' d='m219.57 20.04-99.76 99.77-99.77-99.77' data-name='Layer 1'/%3E%3C/g%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-size: cover;
    right: 0 !important;
    top: 42% !important;

    @include mq(sm) {
      height: 0.9rem !important;
      width: 1.5rem !important;
    }
  }


}
