.awo-acc {
    border-top: 1px solid rgba(141, 83, 141, 0.30);
    dl {
        border-bottom: 1px solid rgba(141, 83, 141, 0.30);
        dt {
            position: relative;
            padding: 1.5rem 0;
            padding-right: 4rem;
            button {
                background: transparent;
                border: none;
                color: #404040;
                font-family: $lu;
                font-size: 26px;
                line-height: 32px;
                margin-bottom: -10px;
                text-align: left;
                font-weight: 400;
                width: 100%;
                @include mq(lg) {
                    font-size: 32px;
                    line-height: 55px;
                }
            }
            > span {
                color: #faa61a;
                position: absolute;
                right: 0;
                top: 0;
                height: 100%;
                display: flex;
                gap: 8px;
                align-items: center;
                pointer-events: none;
                margin-right: 1.5rem;
                transition: all 300ms ease-in-out;
                svg {
                    width: 26px;
                    height: 26px;
                    transition: all 300ms ease-in-out;
                }
            }
            > span.close-icon {
                opacity: 0;
            }
            > span.open-icon {
                opacity: 1;
            }
        }
        dd {
            height: 0;
            transition: all 300ms ease-in-out;
            overflow: hidden;
            max-width: 55rem;
            div, p {
                font-family: $lato;
                font-size: 16px;
                line-height: 27px;
                font-weight: 400;
            }
            div {
               padding-bottom: 3rem;
            }
        }
    }
    dl.is-active {
        dt {
            span.close-icon {
                opacity: 1;
            }
            span.open-icon {
                opacity: 0;
            }
        }
    }
    ul {
        list-style-type: disc;
        list-style-position: inside;
        margin-left: 40px;
        padding-left: 0;
    }
}
