.awo-floating-social-widget {
  height: 100vh;
  width: 2rem;
  position: absolute;
  top: 0;
  right: 0;
  display: none;
  justify-content: flex-end;
  align-items: center;

  @include mq(sm) {
    display: flex;
  }

  &.reveal-widget {
    .awo-floating-social-widget__content {
      transform: translateX(100%);
      transition: transform 300ms ease 2s;
    }

    &.revealed-widget {
      .awo-floating-social-widget__content {
        transform: translateX(0%);
      }
    }
  }

  .awo-floating-social-widget__content {
    z-index: 3;
    position: fixed;
    background-color: $awo-black-jet-dark;
    padding: 1.25rem 0.5rem 1.5rem 0.75rem;
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;

    li {
      margin-bottom: 1rem;
    }

    li:last-child {
      margin-bottom: 0;
    }

    .awo-floating-social-widget__icons {
      text-align: center;
      color: $awo-white;

      a {
        color: $awo-white;
        transform: perspective(1px) translateZ(0);
        transition-duration: 0.3s;
        transition-property: color, background-color, border;

        &:hover {
          color: $awo-orange-peel;
        }

        &.fb {
          svg {
            width: 1.25rem;
            height: 1.25rem;
          }
        }

        &.twitter {
          svg {
            width: 1.25rem;
            height: 1.2rem;
          }
        }

        &.link {
          svg {
            width: 1.5rem;
            height: 1.3rem;
          }
        }

        &.inst {
          svg {
            width: 1.5rem;
            height: 1.25rem;
          }
        }

        &.youtube {
          svg {
            width: 1.5rem;
            height: 1.15rem;
          }
        }
      }
    }
  }
}
