$awo-yellow-bright-crayola: #f7a824;
$awo-sunglow: #fecb1a;
$awo-black-jet: #383838;
$awo-black-jet-alt: #3b3b3b;
$awo-black-jet-dark: #2b2b2b;
$awo-black: #000000;
$awo-raisin-black: #231f20;
$awo-black-liver: #413d4b;
$awo-orange-peel: #f69e0b;
$awo-gray-davys: #474747;
$awo-gray-platinum: #e9e9e9;
$awo-gray-spanish: #949494;
$awo-gray-light: #d1d1d1;
$awo-gray-gainsboro: #e2dfdf;
$awo-straw: #d7ce61;
$awo-flame: #d85625;
$awo-camel: #c69c6d;
$awo-bistre-brown: #92712e;
$awo-rosso-corsa: #db0000;
$awo-red: #ff0000;
$awo-indian-red: #a73336;
$awo-blue-dodger: #3289ff;
$awo-white: #ffffff;
$awo-onyx: #404040;
$awo-old-lace: #f7efe1;
$awo-light-gray: #d2d2d2;
$awo-silver: #c2c2c2;
$awo-cultured: #f2f2f2;

$font-awo-title: 'Ludicrous-Stencil', serif;
$font-awo-body: 'Lato', sans-serif;

$top-nav-max-width: 1210px;
$border-radius: 0.75em;
