.news {
    padding-bottom: 30px;
    padding-top: 10px;
    position: relative;
    margin-top: 0.75rem;
    margin-bottom: 2.25rem;

    .container{
        position: relative;
    }
    &-left-bird{
        position: absolute;
        top: 10px;
        left: -232px;
        z-index: -999999;
    }
    &-dots{
        position: absolute;
        top: 1150px;
        right: 0;
        z-index: -999999;
    }
}

.news-filters {
    border-radius: 18px;
    padding: 40px 50px;
    margin-bottom: 35px;
    background: url('/img/news/news-dots-in-filter.png') $yellow-color-light no-repeat;
    background-position: right 30px center;
    position: relative;
    z-index: 2;
}

.news-filters__form {
    display: flex;
    align-items: center;
    justify-content: space-between;
    select {
        height: 45px;
        border-radius: 12px;
        background: #ffffff url('/img/icon-select.png') no-repeat right 25px center;
        font-family: $lato;
        font-size: 14px;
        font-weight: 600;
        border: 0;
        text-transform: uppercase;
        padding: 0 30px;
        -webkit-appearance: none;
        cursor: pointer;
        &:nth-child(1) {
            flex-basis: 31%;
            max-width: 334px;
        }
        &:nth-child(2) {
            flex-basis: 42%;
            max-width: 454px;
        }
        &::-ms-expand {
            display: none;
        }
    }
    button {
        flex-basis: 22%;
        background-color: #2b2b2b;
        text-align: center;
        text-transform: uppercase;
        border: 0;
        border-radius: 12px;
        height: 45px;
        font-family: $lato;
        font-size: 16px;
        font-weight: 400;
        color: #fff;
        &:hover{
            @extend %grey-hover;
        }
    }
}

.news-large {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
}

.news-large__item {
    display: flex;
    background: $orange-color;
    border-top-left-radius: 18px;
    border-bottom-left-radius: 18px;
    overflow: hidden;
    &:not(:last-child) {
        margin-bottom: 40px;
    }
    &:hover {
        background-color: #2b2b2b;
        .news-large__item-content {
            background-color: #2b2b2b;
        }
    }
}
.news-large__item-content {
    flex-basis: 60%;
    padding: 30px 35px;
    background: $orange-color url('/img/news/item-icon.png') no-repeat right center;
    background-size: auto 100%;
}
.news-large__item-date {
    margin-bottom: 15px;
    font-size: 18px;
    font-weight: 400;
    color: #d3d3d3;
    letter-spacing: 0.09px;
}
.news-large__item-title {
    font-size: 26px;
    font-weight: 700;
    line-height: 1.25;
    color: #ffffff;
    letter-spacing: 0.13px;
    position: relative;
    &::after {
        content: '';
        display: block;
        width: 480px;
        height: 6px;
        background: url('/img/news/icon-news-title.png') no-repeat center;
        background-size: cover;
        margin-top: 12px;
        margin-bottom: 20px;
    }
}
.news-large__item-desc {
    font-size: 17px;
    font-weight: 400;
    line-height: 28px;
    color: #ffffff;
    letter-spacing: 0.59px;
}
.news-large__item-pic {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    flex-basis: 40%;
}

.news-small {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 30px;
    .news-small__item{
        &:nth-of-type(3n), &:last-child{
            margin-right: 0px;
        }

    }

}

.news-small__item {
    background-color: $orange-color;
    border-bottom-right-radius: 18px;
    border-bottom-left-radius: 18px;
    width: calc((100%/3) - 15px);
    margin-right: 22px;
    &:hover {
        background-color: #2b2b2b;
    }
    &.nohover {
        &:hover {
            background-color: $orange-color;
        }
    }
    z-index: 2;
}
.news-small__item-pic {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 280px;
}
.news-small__item-title {
    padding: 20px 30px 50px 30px;
    font-size: 26px;
    font-weight: 700;
    color: #fff;
    line-height: 1.25;
}

.news-small__item-date{
    margin-bottom: 15px;
    font-size: 18px;
    font-weight: 400;
    color: #d3d3d3;
    letter-spacing: 0.09px;
}

.news-small__item-date-small{
    font-size: 14px;
    font-weight: bold;
    color: #ffffff;
    letter-spacing: 0.09px;
    padding-bottom: 2px;
    padding-top: 2px;
}

.news-small__item-location-small{
    font-size: 14px;
    font-weight: bold;
    color: #ffffff;
    letter-spacing: 0.09px;
}

.news-small__item--info-container{
    min-height: 6.6rem;
}

.news__button {
    text-align: center;
    margin-top: 35px;
    a {
        margin: 0 auto;
    }

}
.chunk-hidden{
    display: none;
}
