.awo-mobile-menu {
  position: fixed;
  top: 0;
  left: 0;
  height: auto;
  width: 100%;
  z-index: 15;
  transform-origin: top;
  transform: scaleY(0);
  transition: transform 100ms var(--ease-in-out);
  opacity: 0;
  transition-delay: 75ms;
  margin-top: 8.2rem;

  &::after {
    content: "";
    background-color: $awo-black-jet-dark;
    opacity: 0.7;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 200vh;
  }

  @media screen and (min-width: $top-nav-max-width) {
    display: none;
  }

  &.is-active {
    transform-origin: top;
    transform: scaleY(1);
    opacity: 1;
    transition: transform 300ms var(--ease-in-out);
    transition-delay: 75ms;
  }

  .awo-mobile-menu__container {
    background-color: transparent;
    z-index: 2;
    position: relative;
    height: 80vh;
    overflow-y: scroll;
    margin-top: -0.1rem;
  }

  $cubic: cubic-bezier(0.4, 0.01, 0.165, 0.99);
  $menuItems: 12;
  .awo-mobile-menu__navigation {
    padding: 0.5rem 0 0;
    background-color: $awo-white;

    ul {
      li {
        padding: 0.75rem 1.25rem;
        border-bottom: 1px solid $awo-cultured;

        .awo-mobile-menu__navigation-title {
          position: relative;
          display: flex;

          &.is-active {
            a {
              &::after {
                opacity: 1;
                transition: opacity 250ms var(--ease-in-out);
              }
            }
          }

          a {
            font-size: 1.125rem;
            line-height: 1.5rem;
            font-family: $lato;
            font-weight: bold;
            color: $black;
            display: inline-block;
            position: relative;
            text-transform: uppercase;
            position: relative;

            &::after {
              content: "";
              position: absolute;
              bottom: 0;
              left: 0;
              width: 100%;
              height: 1px;
              background-color: $awo-yellow-bright-crayola;
              margin-bottom: -0.2rem;
              opacity: 0;
              transition: opacity 250ms var(--ease-in-out);
            }
          }

          span {
            position: absolute;
            top: 0;
            right: 0;
            width: 1.85rem;
            height: 2.5rem;
            cursor: pointer;
            margin-top: -0.25rem;

            &.is-active {
              svg {
                transform-origin: center;
                transform: rotate(180deg);
                transition: transform 300ms ease-in-out;
                fill: $awo-yellow-bright-crayola;
              }
            }

            svg{
              width: 2.5rem;
              height: 2.5rem;
              position: absolute;
              top: 0;
              left: 0;
              margin-top: -0.25rem;
              transition: transform 300ms ease-in-out;
            }
          }
        }

        .awo-header__mobile-navigation--children {
          max-height: 0;
          opacity: 0;
          transform-origin: top;
          transform: scaleY(0);

          ul {
            padding-top: 0.5rem;
            padding-bottom: 1rem;

            li {
              margin-bottom: 0.75rem;
              transform: translateY(-30px);
              opacity: 0;
              transition: transform 0.5s $cubic, opacity 0.6s $cubic;
              will-change: transform;
              border-bottom: none;
              padding-left: 0;
              padding-bottom: 0;
              margin-bottom: 0;

              a {
                font-family: $lato;
                font-size: 0.9375rem;
                line-height: 1.375rem;
                color: $black;
              }
            }
          }

          &.is-active {
            max-height: 100%;
            opacity: 1;
            transform-origin: top;
            transform: scaleY(1);

            li {
              transform: scale(1) translateY(0);
              opacity: 1;

              @for $i from 0 through $menuItems {
                &:nth-child(#{$i}) {
                  transition-delay: #{0.1 + ($i * 0.07)}s;
                }
              }
            }
          }
        }
      }
    }

    .awo-mobile-menu__navigation-donate {
      background-color: $awo-black-jet-dark;
      padding: 1.25rem;

      a {
        background-color: $awo-flame;
        border: 1px solid $awo-flame;
        font-family: $font-awo-body;
        font-weight: 700;
        font-size: 0.875rem;
        line-height: 0.9375rem;
        color: $awo-white;
        display: inline-block;
        text-align: center;
        text-transform: uppercase;
        padding: 0.65rem 1.75rem;
        width: 100%;
        margin: 0 auto;

        @include mq(sm) {
          width: auto;
        }
      }
    }
  }
}

body.is-fixed {
  position: fixed;
}
