.awo-cc-hww {
    .awo-cc-hww__button {
        margin-bottom: 4rem;

        @include mq(sm) {
            margin-bottom: 4.75rem;
        }
    }

    .awo-cc-hww__video {
        margin-bottom: 4rem;
        position: relative;

        .awo-cc-hww__video__bird {
            position: absolute;
            top: 0;
            right: 0;
            height: 100%;
            display: flex;
            align-items: center;
            display: none;

            @include mq(md) {
                display: block;
            }
        }

        @include mq(sm) {
            margin-bottom: 4.75rem;
        }
    }
}
