.awo-accordion {
  &.reveal {
    .awo-accordion__item-head {
      opacity: 0;
      transition: opacity 500ms ease;

      @for $i from 0 through 20 {
        &:nth-child(#{$i}) {
          transition-delay: #{0.25 + ($i * 0.07)}s;
        }
      }
    }

    &.revealed {
      .awo-accordion__item-head {
        opacity: 1;
      }
    }
  }

  dt {
    background-color: $awo-old-lace;
    padding: 1.75rem 1.85rem 1.75rem 2.25rem;
    margin-top: 1.5rem;
    border-radius: 0.75rem;

    &.open {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;

      .down {
        color: $awo-flame;

        svg {
          transition: all 300ms ease-in;
          transform: rotate(-180deg);
        }
      }
    }

    &:first-child {
      margin-top: 0;
    }

    a {
      font-family: $font-awo-body;
      font-size: 1.0625rem;
      line-height: 1.625rem;
      color: $awo-gray-davys;
      font-weight: 700;
      display: flex;

      @include mq(md) {
        font-size: 1.25rem;
        line-height: 1.75rem;
      }

      .awo-accordion__item-title {
        flex: 1 1 auto;
        margin-right: 1rem;
      }

      .down {
        color: $awo-black-jet-dark;

        svg {
          transition: all 300ms ease-in;
        }
      }
    }
  }

  dd {
    background-color: $awo-old-lace;
    border-bottom-left-radius: 0.75rem;
    border-bottom-right-radius: 0.75rem;
    padding: 0 1.85rem 1.75rem 2.25rem;
    margin-top: -0.5rem;

    p {
      font-family: $font-awo-body;
      font-size: 1rem;
      line-height: 1.6875rem;
      max-width: 57rem;
    }

    .awo-accordion__item-button {
      a {
        padding: 1rem 1.5rem;
      }
    }
  }
}

.awo-accordion__button {
  margin-top: 3rem;
  text-align: center;
}
