.awo-careers {
  padding-top: 3.75rem;
  padding-bottom: 6rem;

  @include mq(md) {
    padding-top: 2rem;
  }

  .container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    @include mq(sm) {
      flex-wrap: nowrap;
    }
  }

  .awo-careers__left {
    width: 100%;
    display: flex;

    @include mq(sm) {
      margin-right: 2rem;
    }

    @include mq(md) {
      margin-right: 3.25rem;
      width: auto;
      display: block;
    }

    img {
      min-height: 17.25rem;
      max-height: 17.25rem;
      max-width: 17.25rem;
      border-radius: 100%;
      object-fit: cover;
      object-position: center;
      margin: 0 auto;

      @include mq(md) {
        min-height: 23.5rem;
        max-height: 23.5rem;
        max-width: 23.5rem;
      }
    }
  }

  .awo-careers__right {
    .awo-careers__type {
      font-size: 15px;
      line-height: 25px;
      font-weight: 700;
      color: $awo-yellow-bright-crayola;
      margin-bottom: 1rem;
      margin-top: 2.5rem;

      @include mq(md) {
        font-size: 20px;
        line-height: 20px;
        margin-top: 0;
        margin-bottom: 1.25rem;
      }
    }

    .awo-careers__title {
      font-family: $font-awo-title;
      font-size: 36px;
      line-height: 40px;
      color: $awo-onyx;
      margin-top: 0;
      text-align: left;
      margin-bottom: 0.75rem;

      @include mq(md) {
        font-size: 45px;
        margin-bottom: 1rem;
      }
    }

    .awo-careers__description {
      font-size: 16px;
      line-height: 27px;
      margin-bottom: 1.5rem;

      @include mq(md) {
        margin-bottom: 2rem;
      }
    }
  }
}
