.home-shop {
    padding: 50px 0;
    position: relative;
    &-pattern{
        position: absolute;
        left: 0px;
        bottom: 0;
        z-index: -999999;
    }
}

.home-shop__list {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}
.home-shop__item {
    width: 100%;
    color: #424242;
    &:not(:last-child) {
        margin-right: 20px;
    }
}
.home-shop__item-pic {
    height: 255px;
    border-radius: 18px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    margin-bottom: 20px;
    width: 100%;
}
.home-shop__item-title {
    font-size: 20px;
    font-weight: 700;
    line-height: 1.35;
}
.home-shop__item-price {
    font-size: 24px;
    font-weight: 400;
    line-height: 1.35;
}

.home-shop__button {
    text-align: center;
    margin-top: 35px;
    a {
        margin: 0 auto;
    }
}

