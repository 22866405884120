.awo-three-col-grid {
  .awo-three-col-grid__container {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    column-gap: 2.75rem;
    row-gap: 2rem;
    @include mq(sm) {
      grid-template-columns: repeat(2, 1fr);
    }
    @include mq(md) {
      grid-template-columns: repeat(3, 1fr);
      row-gap: 3.25rem;
    }
  }
  a {
    background-color: $awo-old-lace;
    border-radius: 1.25rem;
    cursor: pointer;
    min-height: 11.75rem;
    position: relative;
    &:hover {
      background-color: $awo-flame;
      .awo-three-col-grid__item-title {
        color: $awo-white;
      }
    }
    .awo-three-col-grid__item-title {
      display: block;
      font-family: $font-awo-title;
      font-size: 2.375rem;
      line-height: 2.5rem;
      color: $awo-flame;
      margin: 2.5rem 2rem 0;
      position: relative;
      z-index: 1;
      max-width: 16rem;
    }
    .awo-three-col-grid__item-overlay-image {
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }
}
