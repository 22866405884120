.staff__el {
	padding: 0!important;
	list-style: none;
	&::before{
		content: '';
		width: 0!important;
		height: 0;
	}
	
	&:not(:last-child) {
		margin-bottom: 0px;
	}
	border-radius: 0.9375rem;
    position: relative;
    display: block;
    &.item-figure{
    	padding: 25px 20px 35px 235px !important;
    }
    &:not(:last-child){
        margin-bottom: 50px;
    }
    .figure{
        position: relative;
        float: left;
    }
}

.staff__el-photo {
	width: 197px;
    height: 197px;
    margin: 0 auto 20px auto;
    background-position: center; 
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 50%;
    
	
}

.staff__el-text{
	padding-left: 230px;
}
.staff__el-title,.staff__el-desc{
    font-size: 18px;
    line-height: 28px;

}
.staff__el-title{
    margin-bottom: 0!important;
    font-weight: bold;
    margin-top: -5px;
}