.awo-video-player {
  .awo-video-player__wrapper {
    background-color: $awo-gray-platinum;
    position: relative;
    border-radius: 2rem;
    overflow: hidden;
    max-width: 57.5rem;
  }
  .awo-video-player__image {
    object-fit: cover;
    object-position: center;
    width: 100%;
    @include mq(sm) {
      min-height: 32rem;
    }
  }
  .awo-video-player__button {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      .awo-video-player__button-label {
        color: $awo-orange-peel;
      }
      &::after {
        opacity: .5;
      }
    }
    &::after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: $awo-black-jet-dark;
      opacity: 0.3;
      transform: perspective(1px) translateZ(0);
      transition-duration: 0.3s;
      transition-property: color, background-color, border, opacity;
    }
    .awo-video-player__button-label {
      font-family: $font-awo-title;
      font-size: 1.625rem;
      line-height: 2rem;
      color: $awo-white;
      z-index: 1;
      text-align: center;
      padding-top: 4rem;
      transform: perspective(1px) translateZ(0);
      transition-duration: 0.3s;
      transition-property: color, background-color, border;
      @include mq(sm) {
        font-size: 2.8125rem;
        line-height: 3.4375rem;
        padding-top: 7rem;
      }
      svg {
        width: 100%;
        height: 2rem;
        fill: currentColor;
        @include mq(sm) {
          height: 3.25rem;
        }
      }
    }
  }
}
.plyr__video-wrapper {
  border-radius: 20px 20px 0 0;
  overflow: hidden;
}
.goverlay {
  background: rgba(0, 0, 0, 0.8);
}
