.header {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    width: 100%;
    padding-top: 15px;

    & > .container {
        display: flex;
        align-items: flex-start;
        max-width: 1320px;
        position: relative;
        z-index: 9;
    }
    background-image: linear-gradient(to bottom, #252525 0%, rgba(37, 37, 37, 0) 100%);

    .exit-button {
        position: absolute;
        z-index: 2;
        right: 0;
        top: 80px;
        background-color: $orange;
        padding: 0.8rem;
        padding-bottom: 0.2rem;
        display: fixed;

        &__icon {
            background-image: url('/img/close-icon-white.svg');
            background-repeat: no-repeat;
            background-position: center;
            height: 0.8rem;
            width: 100%;
            margin-bottom: 0.7rem;
        }

        &__text {
            color: $white;
            font-family: $stack-black;
            font-size: 0.9rem;
            text-transform: uppercase;
            font-weight: bold;
        }
    }

    .exit-button.fixed {
        position: fixed;
        top: 0;
    }

}

.header__burger {
    display: none;
    width: 36px;
    height: 31px;
    position: relative;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
    span {
        width: 100%;
        height: 5px;
        display: block;
        background: #fff;
    }
}
.header__logo{
    background-size: contain;
}
.template-default .header .logo-container,.template-contact .header .logo-container,.template-contact-new .header .logo-container{

    .header__logo{
        background: url('/img/mobile-logo.png') no-repeat center;
        width: 240px;
        height: 63px;
    }

}
.template-home  .atm--home .header__logo {
    margin-right: 100px;
    width: 115px;
    height: 178px;
    background: url('/img/header-logo.png') no-repeat center;
    background-size: cover;
    img {
        display: none;
    }
    display: none;
    position: absolute;
    left: 0;
    top: 32%;

    @media screen and (min-width: 1366px) {
        display: block;
    }
}

.header__nav {
    padding-top: 40px;

   &.mobile .nav__item-1.has-children{
        .header__nav-arrow{
            background-image: url('/img/svg/icon-arrow-down.svg');
            width: 20px;
            height: 20px;
            background-size: contain;
            position: relative;
            margin-left: 40px;
            &:hover{
                cursor: pointer;
            }
            display: none;
        }
    }
}

.header__nav-mobile {
    display: none;
    position: absolute;
    top: 20px;
    left: 20px;
    width: calc( 100% - 30px );
    input {
        border: 0;
        padding: 5px;
        font-family: $lato;
        font-size: 14px;
        height: 40px;
        width: 100%;
    }
    a.header__donate, a.header__order {
        display: inline-block;
        vertical-align: middle;
    }
    a.header__order {
        margin-right: 20px;
        border-color: $main-color;
        color: $main-color;
        background-image: url('/img/svg/icon-order-dark.svg');
    }

}
.header__nav-mobile-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    margin-top: 10px;
}
.header__nav-mobile-close {
    display: block;
    min-width: 40px;
    height: 40px;
    background: url(/img/icon-close.png) no-repeat center;
    background-size: 22px 22px;
    cursor: pointer;
}

.header__nav > ul {
    font-size: 0;
    position: relative;
    > li {
        display: inline-block;
        vertical-align: middle;
        &.has-children:hover {
            &::before {
                opacity: 1;
                visibility: visible;
            }
            .header__nav-hidden {
                opacity: 1;
                visibility: visible;
            }
        }

        &:not(:last-child) {
            padding-right: 18px;
        }
        > a {
            color: #ffffff;
            font-family: Lato;
            font-size: 14px;
            font-weight: 700;
            text-transform: uppercase;
            letter-spacing: 0.75px;
        }
    }
}

.header__right {
    margin-left: auto;
    padding-top: 28px;
    display: flex;
    align-items: center;
}

.header__buttons {
    margin-right: 40px;
    font-size: 0;
    a {
        display: inline-block;
        vertical-align: middle;
    }
    a:not(:last-child) {
        margin-right: 40px;
    }
}

a.header__order {
    padding: 8px 13px 20px 45px;
    background: $orange-color url('/img/svg/icon-order.svg') no-repeat;
    background-size: 19px 21px;
    background-position: 19px 8px;
    border-radius: 9px;
    font-size: 11px;
    color: #fff;
    font-weight: 700;
    &:hover{
        @extend %orange-hover;
    }
}

a.header__donate {
    padding: 13px 20px 13px 60px;
    background: $yellow-color url('/img/svg/icon-donate.svg') no-repeat;
    background-size: 30px 23px;
    background-position: 22px 6px;
    border-radius: 9px;
    font-size: 14px;
    color: #fff;
    font-weight: 700;
    text-transform: uppercase;
    &:hover{
        @extend %yellow-hover;
    }
}

.header__search-open {
    width: 25px;
    height: 25px;
    background: url('/img/icon-header-search.png') no-repeat center;
    background-size: cover;
    cursor: pointer;
}

.header__nav-hidden {
    position: absolute;
    z-index: 100;
    left: 0;
    top: 15px;
    padding-top: 45px;
    width: 750px;
    height:370px;
    opacity: 0;
    visibility: hidden;
    z-index: 2;
    .megamenu {
        display: flex;
        flex-wrap: wrap;
        li {
            flex-basis: 50%;
            &:not(:last-child) {
                margin-right: 0;
                margin-bottom: 20px;
            }
            a {
                text-transform: none;
                font-size: 14px;
                font-weight: 400;
                color: #fff;
                &:hover {
                    color: $orange-color;
                }
            }
        }
    }
}

.megamenu__overlay {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 370px;
    z-index: 2;
    background-color: #2b2b2b;
    opacity: 0;
    visibility: hidden;
    transition: 120ms ease-in-out;
    &.active {
        opacity: 1;
        visibility: visible;
    }
}

.search__overlay {
    height: 370px;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 3;
    opacity: 0;
    visibility: hidden;
    transition: 120ms ease-in-out;
    &.active {
        opacity: 1;
        visibility: visible;
    }
    .container {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: 880px;
    }
    form{
        width: 100%;
    }
    input {
        border-radius: 0;
        background: transparent;
        width: 100%;
        border: 0;
        border-bottom: 1px solid #949494;
        padding: 16px 0;
        font-family: $lato;
        font-size: 28px;
        color: white;
    }
}
