.awo-tabbed-container {
  .awo-h2 {
    text-align: center;
    margin-bottom: 1.25rem;
  }
  .awo-tabbed-container__header {
    text-align: center;
    border-bottom: 2px solid $awo-yellow-bright-crayola;
    padding-bottom: 2.25rem;
    margin-bottom: 3.5rem;
    display: flex;
    overflow-x: scroll;
    position: relative;
    @include mq(sm) {
      overflow-x: hidden;
    }
    &::before {
      content: "";
      background-color: $awo-white;
      position: absolute;
      top: 0;
      right: 0;
      height: 3.5rem;
      width: 1rem;
      z-index: 1;
      box-shadow:  -6px 0px 15px 14px rgba(255, 255, 255, 1);
      -webkit-box-shadow:  -6px 0px 15px 14px rgba(255, 255, 255, 1);
      @include mq(sm) {
        display: none;
      }
    }
    &::after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      height: 2.25rem;
      width: 1rem;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 239.61 139.85'%3E%3Cg data-name='Layer 2'%3E%3Cpath style='fill:none;stroke:%23f7a824;stroke-linecap:square;stroke-miterlimit:10;stroke-width:28.35px' d='m219.57 20.04-99.76 99.77-99.77-99.77' data-name='Layer 1'/%3E%3C/g%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-size: contain;
      transform: rotate(270deg);
      z-index: 2;
      margin-right: -1rem;
      @include mq(sm) {
        display: none;
      }
    }
    a {
      font-family: $font-awo-body;
      font-weight: 400;
      text-transform: uppercase;
      font-size: 0.875rem;
      line-height: 0.875rem;
      border-radius: 0.75rem;
      border: 1px solid $awo-yellow-bright-crayola;
      color: $awo-black-jet-dark;
      padding: 0.65rem 1.75rem;
      display: inline-block;
      cursor: pointer;
      margin-right: 1.25rem;
      flex: 1 0 auto;
      @include mq(md) {
        padding: 0.9rem 3.25rem;
      }
    }
    a.is-active {
      background-color: $awo-yellow-bright-crayola;
    }
    a:hover {
      background-color: $awo-yellow-bright-crayola;
    }
    a:last-child {
      margin-right: 0;
    }
  }
}
