.awo-intro-text {
  margin-top: 4.5rem;

  h2 {
    font-family: $font-awo-title;
    font-size: 36px;
    line-height: 40px;
    color: $awo-onyx;
    text-align: center;

    @include mq(md) {
      font-size: 45px;
      line-height: 55px;
    }
  }

  .awo-intro-text__paragraphs {
    p {
      font-size: 16px;
      line-height: 27px;
      color: $awo-onyx;
      text-align: center;
    }
  }

  .awo-intro-text__buttons {
    justify-content: center;
    margin-top: 2.75rem;
    display: flex;
    flex-wrap: wrap;

    @include mq(md) {
      flex-wrap: wrap;
    }

    a:first-child {
      margin-bottom: 0.75rem;

      @include mq(md) {
        margin-bottom: 0;
        margin-right: 1.5rem;
      }
    }
  }
}
