.search-results{
	h4{
		font-size: 20px;
		padding-bottom: 10px;
		color: #4d4d4d;
	}
	&__item{
		padding: 20px;
	}
	&__summary{
		color: #4d4d4d;
		font-size: 18px;
	}
}