.awo-wysiwyg-content {
  position: relative;
  z-index: 1;
  h2 {
    font-family: $font-awo-title;
    font-weight: 400;
    font-size: 2.25rem;
    line-height: 2.5rem;
    color: $awo-onyx;
    text-align: center;
    @include mq(md) {
      font-size: 2.8125rem;
      line-height: 3.4375rem;
      max-width: 66rem;
      text-align: left;
    }
  }
  h3 {
    font-family: $font-awo-body;
    font-weight: 600;
    font-size: 1.5rem;
    line-height: 1.875rem;
    color: $awo-gray-davys;
    text-align: left;
    letter-spacing: 0.2px;
    @include mq(sm) {
      font-size: 1.75rem;
      line-height: 2.3125rem;
    }
  }
  p {
    font-size: 0.9375rem;
		line-height: 1.5625rem;
		letter-spacing: 0.15px;
		margin-bottom: 1.5625rem;
		max-width: 60rem;
    text-align: center;
    @include mq(md) {
      font-size: 1rem;
		  line-height: 1.6875rem;
      text-align: left;
    }
  }
  ul {
    list-style: disc;
  }
  ol {
    list-style: decimal;
  }
  ul,
  ol {
    padding-left: 1.5rem;
    li {
      font-size: 0.9375rem;
		  line-height: 1.5625rem;
      @include mq(md) {
        font-size: 1rem;
        line-height: 1.6875;
      }
    }
  }
  > ul,
  > ol {
    margin-bottom: 1.75rem;
  }
  a.awo-button {
    text-transform: none;
    font-size: 1.0625rem;
    line-height: 1.6875rem;
    font-weight: 400;
    padding: 0.5rem 1.5rem;
    margin: 0 0.25rem;
  }
  a.awo-button-orange {
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
  }
}
.awo-wysiwyg {
  @extend .awo-wysiwyg-content;
  p {
    max-width: 100%;
    //&:last-of-type {
    //  margin-bottom: 0;
    //}
  }
  img {
    width: 100%;
    height: auto;
    margin-top: 1.75rem;
    border-radius: 2rem;
  }
}
.content-wysiwyg-content {
  .awo-wysiwyg-content {
    h3 {
      max-width: 66rem;
    }
    p {
      font-size: 1rem;
      max-width: 64rem;
    }
  }
}
.wave-content-one__content {
  .awo-wysiwyg-content {
    p {
      max-width: 100%;
    }
  }
}
