.awo-wave-cta-one {
  position: relative;
  .awo-wave-cta-one__top-wave {
    svg {
      fill: $awo-old-lace;
      margin-bottom: -0.5rem;
    }
  }
  .awo-wave-one__wrapper {
    background-color: $awo-old-lace;
    padding-top: 3rem;
    padding-bottom: 6.5rem;
    @include mq(md) {
      padding-top: 0.5rem;
      padding-bottom: 4.25rem;
    }
    .awo-h2 {
      margin-top: 0;
      margin-bottom: 1rem;
      @include mq(md) {
        margin-bottom: 0.75rem;
      }
    }
    .awo-wave-cta-one__content {
      font-family: $font-awo-body;
      font-size: 0.9375rem;
		  line-height: 1.5625rem;
      max-width: 55.45rem;
      margin-bottom: 1.5rem;
      text-align: center;
      @include mq(md) {
        font-size: 1.0625rem;
        line-height: 1.6875rem;
        margin-bottom: 2.25rem;
        text-align: left;
      }
    }
    .awo-wave-cta-one__button {
      text-align: center;
      @include mq(md) {
        text-align: left;
      }
    }
  }
  .awo-wave-cta-one__footer-bg {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 5rem;
    background-color: $awo-old-lace;
    margin-bottom: -5rem;
  }
}
