.awo-vpa {
    height: 12rem;
    width: 100%;
    max-width: 45rem;
    padding: 0;
    border: none;
    border-radius: 20px;
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;

    @include mq(sm) {
        height: 26.25rem;
    }

    &::after {
        content: "";
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #000;
        opacity: 40%;
        display: block;
        position: absolute;
    }

    img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        position: absolute;
        left: 0;
        top: 0;
    }

    .awo-pa__button {
        color: #fff;
        z-index: 1;
        font-family: $lato;
        font-size: 14px;
        font-weight: 700;
        line-height: 9px;
        text-transform: uppercase;
        text-align: center;

        .awo-pa__button-icon {
            margin-bottom: 0.75rem;
        }

        svg {
            width: 4rem;
            height: 4rem;

            @include mq(sm) {
                width: 3rem;
                height: 3rem;
            }
        }
    }
}
