.general__el {
	padding: 0!important;
	list-style: none;
	&::before{
		content: '';
		width: 0!important;
		height: 0;
	}
	
	&:not(:last-child) {
		margin-bottom: 0px;
	}
	border-radius: 0.9375rem;
    position: relative;
    display: block;
    min-height: 224px;
    &.item-figure{
    	padding: 25px 20px 35px 235px !important;
    }
}

.general__el-photo {
	position: absolute;
	left: 0;
	width: 199px;
    height: 187px;
    margin: 0 auto 20px auto;
    background-position: center; 
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 15px;
    
	
}

.general__el-text{
	
}
   