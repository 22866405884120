.awo-gbl {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 1.5rem;

    @include mq(sm) {
        grid-template-columns: repeat(3, 1fr);
        gap: 1rem;
    }

    @include mq(md) {
        gap: 2.75rem;
    }

    a {
        background-color: #D85625;
        font-family: $lu;
        font-size: 30px;
        line-height: 28px;
        font-weight: 400;
        color: #fff;
        border-radius: 25px;
        padding: 1.75rem;
        min-height: 8.5rem;
        position: relative;
        text-align: left;

        @include mq(sm) {
            font-size: 38px;
            line-height: 40px;
        }

        span {
            max-width: 10rem;
            display: block;

            @include mq(sm) {
                max-width: 100%;
            }
        }

        img {
            position: absolute;
            right: 0;
            bottom: 0;
        }
    }
}
