.home-news {
    padding: 50px 0;
    position: relative;
    z-index: 2;
    &-pattern{
        position: absolute;
        right: -10px;
        bottom: 0;
        z-index: -999999;
    }
}

.home-news__list {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: -20px;
}
.home-news__item {
    width: calc( (100% - 80px) / 4 );
    border-radius: 18px;
    background: $orange-color;
    padding: 27px 29px;
    color: #fff;
    margin-bottom: 20px;
    background-size: cover;
    &--big {
        width: calc( (100% - 20px) / 2 );
        background-position: left;
        background-size: cover;
        background-repeat: no-repeat;
        padding-left: 350px;
        position: relative;
        overflow: hidden;
        & > * {
            position: relative;
            z-index: 2;
        }
        &::after {
            right: 0;
            top: 0;
            width: calc(100% - 325px);
            height: 100%;
            background-color: rgba(#303030,0.85);
            content: '';
            position: absolute;
        }
    }
    > a {
      display: block;
      color: inherit;
    }
}
.home-news__item-title {
    color: #ffffff;
    font-family: $lu;
    font-size: 28px;
    font-weight: 400;
    letter-spacing: 0.14px;
    position: relative;
    &::after {
        margin-top: 9px;
        margin-bottom: 19px;
        content: '';
        display: block;
        width: 135px;
        height: 4px;
        background: url('/img/home/icon-news-title.png') no-repeat center;
        background-size: cover;
    }
}
.home-news__item-desc {
    font-size: 17px;
    font-weight: 400;
    line-height: 1.35;
    letter-spacing: 0.09px;
    margin-bottom: 15px;
}
.home-news__item-date {
    color: #d3d3d3;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.35;
    letter-spacing: 0.07px;
}

.home-news__button {
    text-align: center;
    margin-top: 35px;
    a {
        margin: 0 auto;
    }
    z-index: 2;
}
