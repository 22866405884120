.swiper-pagination.swiper-pagination-horizontal.swiper-pagination-bullets {
  text-align: left;
  z-index: 2;
  .swiper-pagination-bullet {
    height: 0.9rem;
    width: 0.9rem;
    background-color: $awo-white;
    margin-right: 1.25rem;
  }
  .swiper-pagination-bullet.swiper-pagination-bullet-active {
    background-color: $awo-yellow-bright-crayola;
  }
}
