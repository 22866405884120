.awo-text-with-blockquote {
  .awo-text-with-blockquote__content {
    p {
      font-family: $font-awo-body;
      font-size: 0.9375rem;
		  line-height: 1.5625rem;
      color: $awo-onyx;
      margin-bottom: 1.75rem;
      text-align: center;
      @include mq(md) {
        font-size: 1.0625rem;
        line-height: 1.6875rem;
        text-align: left;
      }
    }
    blockquote {
      font-family: $lato;
      font-weight: 600;
      font-size: 1.25rem;
      line-height: 2.1875rem;
      color: $awo-onyx;
      max-width: 60rem;
      margin-top: 2rem;
      @include mq(md) {
        font-size: 1.4375rem;
      line-height: 2.5rem;
      }
      &::before {
        content: '“  ';
        font-family: $font-awo-title;
        color: $awo-yellow-bright-crayola;
        background: none;
        font-size: 4rem;
        margin-top: 1.75rem;
        @include mq(md) {
          font-size: 5.5rem;
          margin-top: 2.25rem;
        }
      }
      &::after {
        content: '”';
        font-family: $font-awo-title;
        color: $awo-yellow-bright-crayola;
        background: none;
        font-size: 4rem;
        margin-top: 1.25rem;
        margin-left: 0;
        @include mq(md) {
          margin-top: 1.75rem;
          font-size: 5.5rem;
          margin-left: 1.25rem;
        }
      }
    }
  }
}
