.awo-bgy {
    padding-bottom: 3.5rem;
    .awo-bgy__content {
        background-color: #f7efe1;
        z-index: 1;
        position: relative;
        padding-top: 2.5rem;
        padding-bottom: 3rem;
        &.bg-white {
            background-color: #fff;
        }
        @include mq(sm) {
            padding-top: 4rem;
        }
    }
    .awo-bgy__svg-wrapper {
        position: relative;
    }
    .awo-bgy__svg {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        margin-bottom: 1px;
        height: 1px;
        svg:first-child {
            display: none;
            @include mq(sm) {
                display: block;
            }
        }
        svg:nth-child(2) {
            display: block;
            background-color: #f7efe1;
            @include mq(sm) {
                display: none;
            }
        }
    }
}
