.template-careers-how-to-apply {
    .awo-hero-careers {
        padding-bottom: 0px;
    }
    .awo-bgy,
    .awo-sa {
        padding-bottom: 0;
    }
    main {
        // &:before {
        //     background-image: url('/img/awo-career/beige-curve.svg');
        //     background-size: cover;
        //     // outline: 6px solid red;
        //     content:'';
        //     height: 86px;
        //     position: absolute;
        //     left: 0;
        //     right: 0;
        //     transform: rotate(180deg);
        //     top: -80px;
        //     width: 100vw;
        //     z-index: 1;
        // }
    }
}