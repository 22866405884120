.awo-news-events {
  padding-top: 7.5rem;
  overflow: hidden;

  .container {
    padding: 0;
    position: relative;

    @include mq(md) {
      padding: 0 10px;
    }
  }

  .awo-news-events__bird {
    position: absolute;
    top: 0;
    right: 0;
    margin-top: -6.5rem;
    display: none;

    @include mq(md) {
      display: block;
    }
  }

  .awo-news-events__title {
    font-family: $font-awo-title;
    font-size: 36px;
    line-height: 40px;
    color: $awo-onyx;
    margin-bottom: 1.75rem;

    @include mq(md) {
      font-size: 45px;
      line-height: 40px;
      margin-bottom: 2.5rem;
    }
  }

  .awo-news-events__navigation-wrapper {
    position: relative;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      width: 3rem;
      height: 100%;
      display: flex;
      box-shadow:  -17px 0px 37px 13px rgba(255, 255, 255, 1);
-webkit-box-shadow:  -17px 0px 37px 13px rgba(255, 255, 255, 1);
      background-color: $awo-white;
      z-index: 1;

      @include mq(sm) {
        display: none;
      }
    }

    &::after {
      content: "";
      position: absolute;
      right: 0;
      top: 0;
      width: 1.25rem;
      height: 1rem;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 239.61 139.85'%3E%3Cg data-name='Layer 2'%3E%3Cpath style='fill:none;stroke:%23f7a824;stroke-linecap:square;stroke-miterlimit:10;stroke-width:28.35px' d='m219.57 20.04-99.76 99.77-99.77-99.77' data-name='Layer 1'/%3E%3C/g%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-size: contain;
      margin-right: 0.75rem;
      margin-top: 0.5rem;
      transform: rotate(270deg);
      z-index: 2;

      @include mq(sm) {
        display: none;
      }
    }
  }

  .awo-news-events__navigation {
    display: flex;
    justify-content: flex-start;
    padding: 0 1.25rem 1rem;
    overflow-x: scroll;
    position: relative;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    @include mq(sm) {
      padding: 0 0.625rem;
      justify-content: center;
      overflow: visible;
    }

    a {
      font-size: 14px;
      line-height: 15px;
      font-weight: 700;
      color: $awo-black-jet-dark;
      text-transform: uppercase;
      border: 1px solid $awo-yellow-bright-crayola;
      border-radius: 10px;
      padding: 0.6rem 2rem;
      margin-right: 1rem;
      cursor: pointer;
      white-space: nowrap;
    }

    a:hover {
      background-color: $awo-yellow-bright-crayola;
    }

    a:last-child {
      margin-right: 0;
    }

    a.is-active {
      background-color: $awo-yellow-bright-crayola;
    }
  }

  .awo-news-events__container {
    .awo-news-events__container--content {
      overflow: hidden;
      max-height: 0;
      opacity: 0;
      transition: opacity 300ms ease-out;
    }

    .awo-news-events__container--content.is-active {
      opacity: 1;
      max-height: 100%;
      padding-top: 2.25rem;
      padding-bottom: 2rem;
      transition: opacity 300ms ease-in;
    }
  }

  .awo-news-events__container--grid {
    display: block;

    @include mq(md) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 1rem;
    }

    .awo-news-events__container--grid--mobile {
      padding-right: 6rem;
      padding-left: 2.25rem;

      .swiper {
        overflow: visible;
      }

      @include mq(md) {
        padding-left: 1.25rem;
        display: none;
      }
    }

    .awo-news-events__container--grid-left,
    .awo-news-events__container--grid-right {
      display: none;
      grid-template-columns: repeat(2, 1fr);
      gap: 1.1rem;

      @include mq(md) {
        display: grid;
      }
    }
  }

  .awo-news-events__button {
    text-align: center;
  }

  .awo-news-events__container--item {
    background-color: $awo-flame;
    border-radius: 15px;
    overflow: hidden;
    cursor: pointer;
    min-height: 15.5rem;
    display: block;

    &.is-event {
      background-color: $awo-camel;
    }

    .awo-news-events__container--item-image {
      max-height: 17rem;
      min-height: 17rem;
      object-fit: cover;
      object-position: center;
      width: 100%;
    }

    .awo-news-events__container--item-content {
      padding: 1.5rem 1.75rem;
    }

    .awo-news-events__container--item-type {
      display: flex;

      .title {
        font-family: $font-awo-title;
        font-size: 28px;
        line-height: 28px;
        color: $awo-white;
        width: 100%;
        flex: 1 1 auto;
      }

      .date {
        font-size: 16px;
        color: $awo-light-gray;
        flex: 1 0 auto;
      }
    }

    .awo-news-events__container--item-stroke {
      margin-top: -0.5rem;
    }

    .awo-news-events__container--item-description {
      font-size: 17px;
      line-height: 26px;
      color: $awo-white;
      margin-top: 0.5rem;
    }
  }
}
