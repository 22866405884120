.content-page.donate-content {
  min-height: 50rem;
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  padding-top: 8.5rem;

  &::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #000;
    opacity: 30%;
    z-index: 1;
  }

  .container {
    z-index: 2;
    position: relative;
  }

  .awo-donate-breadcrumbs {
    padding-top: 3.5rem;
    margin-bottom: 2.75rem;
  }

  #donate-vue-app {
    min-height: 66rem;
  }

  .awo-donate-title-content-icms {
    margin-bottom: 3.5rem;

    h1 {
      font-family: $font-awo-title;
      font-size: 3.75rem;
      line-height: 3.75rem;
      color: $white;
      margin-bottom: 0.75rem;
      font-weight: normal;
    }

    .awo-donate-body-content-icms {
      p {
        display: none;

        @include mq(sm) {
          display: block;
        }
      }
    }

    p {
      font-family: $lato;
      font-size: 18px;
      line-height: 28px;
      color: $white;
      font-weight: normal;
      max-width: 42rem;
      text-shadow: 1px 1px 3px black;
    }
  }

  .awo-donate-wizard {
    background-color: rgba($color: #ffffff, $alpha: 1);
    border-radius: 16px;
    max-width: 49rem;
    padding: 3.75rem 2.75rem 3.5rem;
    margin-bottom: 13rem;

    &.adw__step-2-padding {
      // padding: 3.75rem 0.75rem 3.5rem;

      .information-button {
        padding-left: 1rem;
      }
    }

    .awo-donate-errors {
      background-color: $awo-rosso-corsa;
      color: $white;
      border-radius: 10px;
      padding: 1rem;
      margin-top: 1rem;

      .awo-donate-errors--item {
        margin-bottom: 0.5rem;
        font-size: 14px;
      }

      .awo-donate-errors--item:last-child {
        margin-bottom: 0;
      }
    }

    .awo-donate-wizard-steps {
      position: relative;
      max-width: 44rem;
      margin: 0 auto;
      flex-wrap: wrap;
      display: flex;

      .awo-donate-wizard-steps-bar {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 1rem;
        padding: 0 8rem;
        display: none;

        @include mq(sm) {
          display: block;
        }

        &::after {
          content: "";
          border-bottom: 1px solid $awo-yellow-bright-crayola;
          width: 100%;
          display: flex;
          margin-top: 1.6rem;
          z-index: 1;
        }
      }

      .step:not(.active) {
        display: none;

        @include mq(sm) {
          display: block;
        }
      }

      .step {
        text-align: center;
        flex: 1 1 auto;
        z-index: 2;
        width: 100%;
        margin-bottom: 1.5rem;

        @include mq(sm) {
          width: auto;
        }

        .step__block {
          .step__block-number {
            background-color: $white;
            color: $awo-yellow-bright-crayola;
            font-family: $lato;
            font-weight: 700;
            font-size: 25px;
            line-height: 23px;
            border: 1px solid $awo-yellow-bright-crayola;
            border-radius: 100%;
            height: 3.25rem;
            width: 3.25rem;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 auto;
            cursor: pointer;
          }

          .step__block-text {
            font-family: $lato;
            font-size: 14px;
            color: $awo-yellow-bright-crayola;
            text-transform: uppercase;
            margin-top: 1rem;
            cursor: pointer;

            @include mq(sm) {
              margin-top: 1.5rem;
            }
          }
        }

        &.active {
          .step__block-number {
            background-color: $awo-yellow-bright-crayola;
            color: $white;
          }

          .step__block-text {
            color: $awo-black-jet-dark;
          }
        }

      }

    }

    .donate-wizard-container {
      .donate-type-selection {
        display: grid;
        grid-template-columns: 1fr;
        column-gap: 1.75rem;
        row-gap: 1rem;

        @include mq(sm) {
          grid-template-columns: 1fr 1fr;
          margin-top: 2.5rem;
          row-gap: 1.75rem;
        }

        .donate-type-selection--item {
          background-color: $white;
          border: 1px solid $awo-yellow-bright-crayola;
          border-radius: $border-radius;
          padding: 2rem;
          text-align: center;
          cursor: pointer;

          @include mq(sm)
          {
            padding: 0.5rem 2.25rem;
          }

          h3 {
            font-family: $lu;
            color: $awo-yellow-bright-crayola;
            font-size: 28px;
            line-height: 39px;
            margin-bottom: 0.5rem;
            text-align: center;

            @include mq(sm)
            {
              font-size: 38px;
              line-height: 39px;
            }
          }

          p {
            font-family: $lato;
            font-size: 16px;
            line-height: 25px;
            color: $awo-black-jet-dark;

            display: none;

            @include mq(sm)
            {
              display: block;
            }
          }

          &.active {
            background: $awo-yellow-bright-crayola;
            position: relative;

            &::after {
              content: "";
              background-image: url('/img/awo-donate/donate-selection-item-background.png');
              bottom: 0;
              right: 0;
              position: absolute;
              height: 6.5rem;
              width: 7.95rem;
              background-repeat: no-repeat;
              opacity: 0.9;
            }

            h3 {
              color: $white;
            }
          }
        }
      }

      .donate-navigation {
        margin-top: 2rem;
        display: flex;
        position: relative;
        z-index: 1;

        @include mq(sm)
        {
          margin-top: 0;
        }

        button {
          transform: perspective(1px) translateZ(0);
          transition-duration: 0.3s;
          transition-property: color, background-color, border;
        }

        button:not(.donate-navigation__next-button) {
          @include mq(sm) {
            display: none;
          }
        }

        .donate-navigation__next-button {
          margin-left: auto;
          background: $awo-flame;

          &:hover {
            background: $awo-black-jet-dark;
          }
        }
      }

      .donate-amount {
        margin-top: 3rem;
        margin-bottom: 3rem;

        .donate-amount-title {
          text-align: center;
          font-family: $lu;
          font-size: 35px;
          line-height: 39px;
          color: $awo-black-jet-dark;
          margin-bottom: 0.75rem;
          letter-spacing: 1.43px;
        }

        .donate-amount-description {
          font-family: $lato;
          font-size: 16px;
          line-height: 25px;
          text-align: center;
          margin: 0 auto;
          max-width: 36rem;
          margin-bottom: 2.5rem;
        }

        .donate-amount-selection {
          display: flex;
          flex-wrap: wrap;

          @include mq(sm) {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            column-gap: 1rem;
          }

          .donate-amount-selection--item:nth-child(2) {
            margin: 0 0.75rem;

            @include mq(sm) {
              margin: 0;
            }
          }

          .donate-amount-selection--item {
            background-color: $white;
            border: 1px solid $awo-yellow-bright-crayola;
            border-radius: $border-radius;
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            padding-top: 1.25rem;
            padding-bottom: 0.5rem;
            flex: 1 1 auto;

            div.amount {
              position: relative;
              font-size: 38px;
              color: $awo-yellow-bright-crayola;
              font-family: $lu;
              width: auto;
              padding-left: 1rem;
              display: inline-block;

              &::after {
                content: "$";
                font-family: $lu;
                position: absolute;
                top: 0;
                left: 0;
                height: 1rem;
                width: 1rem;
                font-size: 30px;
              }
            }

            &.active {
              background-color: $awo-yellow-bright-crayola;
              position: relative;

              &::before {
                content: "";
                position: absolute;
                top: 0;
                height: 1.25rem;
                width: 1.25rem;
                transform: rotate(45deg);
                background-color: $awo-yellow-bright-crayola;
                margin-top: -0.60rem;
              }

              div.amount {
                color: $white;

                &::after {
                  color: $white;
                }
              }
            }
          }

          .donate-amount-selection--item.custom-amount {
            padding: 0;
            flex: 1 1 auto;
            width: 100%;
            margin-top: 1rem;
            box-sizing: border-box;
            position: relative;

            @include mq(sm) {
              margin-top: 0;
            }

            div.amount {
              &::after {
                margin-top: 1rem;
              }
            }

            input {
              font-size: 24px;
              color: $awo-black-jet-dark;
              padding: 0;
              max-width: 5rem;
              margin-left: 0.25rem;
              line-height: 24px;
              height: auto;
              padding-top: 14px;
              font-family: $lu;

              &::placeholder {
                color: $awo-black-jet-dark;
                font-size: 24px;
                opacity: 1;
              }
            }

            &.active {
              input {
                background-color: $awo-yellow-bright-crayola;
                color: $white;

                &::placeholder {
                  color: $white;
                }
              }

              &::before {
                content: "";
                position: absolute;
                top: 0;
                height: 1.25rem;
                width: 1.25rem;
                transform: rotate(45deg);
                background-color: $awo-yellow-bright-crayola;
                margin-top: -0.60rem;
              }
            }
          }
        }
      }

      .donate-navigation {
        button {
          font-size: 14px;
          font-family: $lato;
          color: $white;
          background-color: $awo-black-jet-dark;
          border: none;
          appearance: none;
          text-transform: uppercase;
          padding: 0.9rem 0;
          min-width: 7.5rem;
          border-radius: 10px;
        }
      }

      .donate-information-type {
        display: grid;
        grid-template-columns: 1fr;
        gap: 1.75rem;
        margin-top: 2rem;

        @include mq(sm) {
          grid-template-columns: 1fr 1fr;
        }

        .donate-information-type--item {
          font-size: 30px;
          line-height: 39px;
          font-family: $lu;
          color: $awo-yellow-bright-crayola;
          background-color: $white;
          border-radius: $border-radius;
          border: 1px solid $awo-yellow-bright-crayola;
          text-align: center;
          padding: 1.1rem 0;
          cursor: pointer;

          &.active {
            background-color: $awo-yellow-bright-crayola;
            color: $white;
          }
        }
      }

      .donate-information {
        .donate-information-grid {
          display: grid;
          grid-template-columns: 1fr;
          gap: 1.1rem;
          margin-top: 2rem;
          margin-bottom: 3rem;

          @include mq(sm) {
            grid-template-columns: 1fr 1fr;
          }
        }

        .donate-input-group {
          label {
            font-family: $lato;
            font-size: 15px;
            display: block;
            margin-bottom: 0.5rem;
          }

          input {
            box-sizing: border-box;
            border: 1px solid $awo-gray-gainsboro;
            border-radius: $border-radius;
          }

          &.company--input {
            margin-top: 1.1rem;
          }
        }
      }

      .donate-payment {
        padding: 3rem 0;
        padding-bottom: 0;

        .donate-payment-grid {
          &::before {
            content: "Loading...";
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            padding-top: 10rem;
            text-align: center;
          }
        }

        iframe {
          border-radius: 1rem;
          position: relative;
          min-height: 102rem;

          @include mq(sm) {
            min-height: 76rem;
          }

          @include mq(md) {
            min-height: 80rem;
          }
        }
      }

      .donate-navigation.payment--step {
        .donate-navigation__privacy-container {
          input[type=checkbox]:checked + label {
            &::after {
              content: "";
              position: absolute;
              left: 0;
              top: 0;
              width: 0.7rem;
              height: 0.7rem;
              border-radius: 100%;
              background-color: $awo-yellow-bright-crayola;
              margin-top: 2px;
              margin-left: 5px;
            }
          }

          label {
            font-family: $lato;
            font-size: 13px;
            line-height: 18px;
            color: $awo-black-liver;
            position: relative;
            padding-left: 2.25rem;
            display: block;
            margin-bottom: 1.25rem;

            &::before {
              content: "";
              position: absolute;
              top: 0;
              left: 0;
              width: 1.2rem;
              height: 1.2rem;
              border-radius: 100%;
              border: 1px solid $awo-gray-gainsboro;
              margin-top: -0.2rem;
              background-color: $white;
            }
          }

          a {
            font-size: 13px;
            line-height: 18px;
            color: $awo-blue-dodger;
            position: relative;
            text-decoration: none;

            svg {
              width: 1.5rem;
              height: 1.5rem;
              margin-right: -1.2rem;
              margin-top: -0.2rem;
              position: absolute;
              top: 0;
              right: 0;
              fill: $awo-blue-dodger;
              transform: rotate(0);
            }

            &.active {
              svg {
                transform: rotate(180deg);
              }
            }
          }

          p {
            font-family: $lato;
            font-size: 13px;
            line-height: 20px;
            color: $awo-black-liver;
            transform: scaleY(0);
            transform-origin: top;
            max-height: 0;

            &.active {
              max-height: 100%;
              transform: scaleY(1);
            }
          }
        }

        button {
          background-color: $awo-rosso-corsa;
          padding: 0.9rem 2.5rem;
        }
      }

    }
  }

  .donate-thank-you__container {
    background-color: rgba($color: $awo-yellow-bright-crayola, $alpha: 0.9);
    border-radius: 16px;
    max-width: 49rem;
    padding: 1.75rem 2.75rem 1rem;
    margin-bottom: 13rem;

    .donate-thank-you__title {
      font-family: $font-awo-title;
      font-size: 3.5rem;
      line-height: 4rem;
      text-align: center;
      color: $awo-white;
      margin-bottom: 0;

      @include mq(sm) {
        font-size: 6.625rem;
        line-height: 7.625rem;
      }
    }

    .donate-thank-you__text {
      color: $awo-white;
      text-align: center;
      font-size: 1.125rem;
      line-height: 1.75rem;
      max-width: 29rem;
      margin: 0 auto;
    }

    .awo-video-player__button {
      text-decoration: none;
      display: block;
      max-width: 14rem;
      margin: 0.75rem auto 0;

      .awo-video-player__button-label {
        color: $awo-white;
        transform: perspective(1px) translateZ(0);
        transition-duration: 0.3s;
        transition-property: color, background-color, border;

        &:hover {
          color: $awo-black-jet-dark;
        }

        svg {
          height: 3.25rem;
          width: 100%;
          fill: currentColor;
          margin-bottom: 0.5rem;
        }

        span {
          font-size: 2.0625rem;
          line-height: 2.5rem;
          text-align: center;
          display: block;
          margin: 0 auto;
          font-family: $font-awo-title;
        }
      }
    }

    .awo-video-player__button.with-thumbnail {
      max-width: 100%;
      position: relative;
      border-radius: 4px;
      overflow: hidden;

      img {
        width: 100%;
        height: auto;
        max-height: 28rem;
        object-fit: cover;
        object-position: center;
      }

      .awo-video-player__button-label {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        span {
          font-size: 16px;
          line-height: 16px;

          @include mq(sm) {
            font-size: 2.0625rem;
            line-height: 2.5rem;
          }
        }

        &:hover {
          color: $yellow-color;
        }

        svg {
          width: 1.5rem;
          height: 1.5rem;

          @include mq(sm) {
            height: 3.25rem;
            width: 100%;
            margin-bottom: 0.5rem;
          }
        }
      }
    }

    .donate-thank-you__subtitle {
      font-family: $font-awo-title;
      color: $awo-white;
      font-size: 2.0625rem;
      font-weight: normal;
      line-height: 2.5rem;
      text-align: center;
      margin-top: 3rem;
    }

    .donate-thank-you__links {
      text-align: center;

      a:first-child  {
        margin-left: 0;
      }

      a:last-child {
        margin-right: 0;
      }

      a {
        margin: 0 0.6rem 0 0.6rem;
        margin-bottom: 1.6rem;
        text-decoration: none;
      }
    }
  }
}

@keyframes loading {
  0% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0 50%;
  }
}
