.awo-tc__spacer {
    padding-top: 5rem;
    // padding-top: 5.75rem;
}
.awo-tc {
    display: flex;
    flex-flow: column;
    position: relative;
    .awo-tc__svg {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        margin-top: 1px;
        height: 1px;
        svg:first-child {
            display: none;
            position: absolute;
            bottom: 0;
            height: 76px;
            width: 100%;
            @include mq(sm) {
                display: block;
            }
        }
        svg:nth-child(2) {
            display: block;
            position: absolute;
            bottom: 0;
            height: 20px;
            width: 100%;
            @include mq(sm) {
                display: none;
            }
        }
    }
    .awo-tc__bg {
        background-color: #8d538d;
        padding-top: 4rem;
        padding-bottom: 4rem;
        position: relative;
        &::after {
            content: "";
            position: absolute;
            left: 0;
            bottom: 0;
            height: 5rem;
            width: 100%;
            background-color: #8d538d;
            margin-bottom: -5rem;
        }
        @include mq(sm) {
            padding-top: 2.75rem;
            padding-bottom: 3rem;
        }
    }
    .awo-tc__title {
        color: #fff;
        font-family: $lu;
        font-size: 50px;
        line-height: 55px;
        font-weight: 400;
        max-width: 62rem;
        margin-bottom: 2.5rem;
        margin-top: 0;
        z-index: 1;
        position: relative;
        @include mq(sm) {
            font-size: 60px;
            line-height: 65px;
            margin-bottom: 1.75rem;
        }
    }
    .awo-tc__button-wrapper {
        display: flex;
        align-items: center;
        width: 100%;
        gap: 1.75rem;
        flex-wrap: wrap;
        @include mq(sm) {
            width: auto;
            flex-wrap: wrap;
        }
    }
    .awo-tc__button {
        color: #fff;
        background-color: #000;
        font-family: $lato;
        font-size: 14px;
        line-height: 9px;
        font-weight: 700;
        text-align: center;
        text-transform: uppercase;
        padding: 1rem;
        transform: perspective(1px) translateZ(0);
        transition-duration: 0.3s;
        transition-property: color, background-color, border;
        min-height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 324px;
        border-radius: 10px;
        width: 100%;
        &:hover {
            color: #2b2b2b;
            background-color: #f7a824;
        }
        @include mq(sm) {
            width: auto;
        }
    }
    .awo-tc__button.button-secondary {
        color: #2b2b2b;
        background-color: #f7a824;
        &:hover {
            color: #fff;
            background-color: #000;
        }
    }
}
