// ----------------------------------------------------------
// =General Vars
// ----------------------------------------------------------

	$using-modernizr: true; 	// whether or not modernizer feature-detection classes are being used
	$ie8: true; 				// Do we need to support ie8 filters (used in mixins)

	$vertical-margins: 15px;	// Margins below elements


// ----------------------------------------------------------
// =Paths
// ----------------------------------------------------------

	$base:							"/";
	$css:							'#{$base}css/';
	$images:						'#{$base}img/';
	$backgrounds: 					'#{$images}backgrounds/';
	$icons: 						'#{$images}icons/';
	$sprites: 						'#{$images}sprites/';
	$js:							'#{$base}js/';
	$fonts: 						'#{$css}fonts/';


// ----------------------------------------------------------
// =Sprites
// ----------------------------------------------------------

	// Please note that size and block size are not retina sizes
	$primary-sprite: (
		image: 						"#{$sprites}global.png", // Retina ready
		image-fallback: 			"#{$sprites}global-sm.png", // Not retina
		size: 						200px 100px,
		block-size: 				10px
	);

	$secondary-sprite: (
		image: 						"#{$sprites}global.png", // Retina ready
		image-fallback: 			"#{$sprites}global-sm.png", // Not retina
		size: 						200px 100px, // Not retina
		block-size: 				10px
	);

	$sprites: (
		primary: $primary-sprite,
		secondary: $secondary-sprite
	);




// ----------------------------------------------------------
// =Colours
// ----------------------------------------------------------

	// Change these colours on a project-by-project basis
	$red: #df3526;
	$darker-orange: #da5527;
	$green: #00CC00;
	$orange: #da5527;

	// fonts
	$lato: 'Lato', sans-serif;
	$lu: 'Ludicrous-Stencil', serif;

	// colors
	$main-color: #2b2b2b;
	$pink-color: #a73336;
	$yellow-color: #f7a824;
	$orange-color: #da5527;
	$yellow-color-light: #f7efe1;

	// Generic greyscale colours
	$white:							#fff;
	$extreme-light-grey:			#f8f6f7;
	$very-light-grey: 				#e7e7e7;
	$grey: 							#8b8b8b;
	$dark-grey:						#333333;
	$black:							#000;
	$almost-black:					#2b2b2b;

	// these colours not used
	$light-grey:					#999;
	$medium-grey:					#666;
	$very-dark-grey:				#222;

	// Access these using palette(brand-colour);
	$palette: (

		// Main brand colour
		brand-colour: $red,
		brand-colour-light: lighten($red, 10%),
		//brand-colour-dark: $darker-red,

		// Secondary brand colour
		secondary-brand-colour: $black,
		secondary-brand-colour-light: lighten($black, 10%),
		secondary-brand-colour-dark: darken($black, 10%),

		// Tertiary brand colour
		tertiary-brand-colour: $white,
		tertiary-brand-colour-light: lighten($white, 10%),
		tertiary-brand-colour-dark: darken($white, 10%),

		// Primary background colours and matching text
		primary-background: $white,
		primary-text: #2b2b2b,

		// Secondary background and matching text
		secondary-background: $extreme-light-grey,
		secondary-text: $black,

		// Default link colours
		link: $orange,
		link-hover: $darker-orange,
		link-active: $darker-orange,
		link-visited: $orange,

		// Default nav colours
		nav: $black,
		nav-hover: $red,
		nav-active: $red,
		nav-visited: $red,
		nav-background: $white,
		nav-background-hover: $white,
		nav-background-active: $white,

			// Nav dropdown
			nav-dropdown: $white,
			nav-dropdown-hover: $black,
			nav-dropdown-active: $black,
			nav-dropdown-visited: $black,
			nav-dropdown-background: $red,
			nav-dropdown-background-hover: $red,
			nav-dropdown-background-active: $red,

		// Buttons
		primary-button: $black,
		primary-button-hover: lighten($black, 20%),
		primary-button-active: lighten($black, 20%),
		primary-button-text: $white,
		primary-button-hover-text: $white,

		// Secondary buttons
		secondary-button: $red,
		secondary-button-hover: darken($red, 10%),
		secondary-button-active: darken($red, 10%),
		secondary-button-text: $white,
		secondary-button-hover-text: $white,

		// Text Selection colours
		selected-text-background: $red,
		selected-text-colour: lighten($white, 10%),

		form-input-background: $very-light-grey,

		// Border colour - used for HR and input fields
		primary-border-color: $light-grey,
		primary-border-color-hover: $light-grey,
		primary-border-color-active: $light-grey
	);


// ----------------------------------------------------------
// =Mobile menu - uses the jquery.mmenu plugin
// ----------------------------------------------------------

	// Mobile menu vars
	$mobile-menu: (

		// Outer background
		outer-background: $extreme-light-grey,

		// Link backgrounds
		background: $extreme-light-grey,
		background-hover: $red,
		background-selected: $red,

		// Link text
		text: $black,
		text-hover: $white,
		text-selected: $white,

		// General styles
		arrow-colour: $black,
		arrow-colour-hover: $red,
		selected-arrow-colour: $red,
		selected-arrow-colour-hover: $red,
		border-colour: $very-light-grey,
	);


// ----------------------------------------------------------
// =Typography
// ----------------------------------------------------------

	// Fonts.com
	$stack-light: 'Avenir LT W01_35 Light1475496', 'Arial', 'sans-serif'; 	// Light
	$stack-light-italics: 'Avenir LT W01_35 Light_1475502', 'Arial', 'sans-serif'; 	// Light Italics
	$stack-normal: 'Avenir LT W01_55 Roman1475520', 'Arial', 'sans-serif'; 	// Normal
	$stack-normal-italics: 'Avenir LT W01_55 Obliqu1475526', 'Arial', 'sans-serif'; 	// Normal Italics
	$stack-black: 'Avenir LT W01_95 Black1475556', 'Arial', 'sans-serif'; 	// Black
	$stack-black-italics: 'Avenir LT W01_95 Black_1475562', 'Arial', 'sans-serif'; 	// Black Italics

	// Access these using font(default);
	$font: (
		heading: $stack-black, // Heading font
		default: $stack-normal, // Main body font
		// secondary: $default-stack,
		size-xxl: 78px,
		size-xl: 29px,
		size-l: 24px,
		size-m: 18px,
		size-sm: 14px,
		size-xs: 12px,
		size-xxs: 11px
	);

	// Headings
	$heading-sizes: (
		h1: map-get($font, size-xl),
		h2: map-get($font, size-l),
		h3: map-get($font, size-m),
		h4: map-get($font, size-m),
		h5: map-get($font, size-m),
		h6: map-get($font, size-m)
	);
		// Headings
	$heading-margin-bottoms: (
		h1: 36px,
		h2: 36px,
		h3: 36px,
		h4: 36px,
		h5: 36px,
		h6: 36px
	);


// ----------------------------------------------------------
// =Guards and grids
// ----------------------------------------------------------

	// Guard/breakpoint sizes
	$guards: (
		xs: 460px,
		sm: 768px,
		md: 992px,
		lg: 1170px,
		xlg: 1440px,
		fhd: 1920px,
		big: 2300px,
	);

	// Main layout grid, set grid defaults
	$outer-grid: (
		columns: 12,
		gutter: 30px,
		max-width: map-get($guards, xlg),  // Set to 0 for fluid width
		output: false, // Set to true to output a class-based pre-calculated grid (col-xs-x,col-sm-x etc)
	);


// ----------------------------------------------------------
// =Misc
// ----------------------------------------------------------

	// Padding for the content area, to separate from the #hero
	$content-padding-top: 15px;
	$content-padding-bottom: 15px;

	$header-height: 55px;