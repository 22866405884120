.awo-text-image {
    display: grid;
    gap: 50px;
    .awo-text-image__text {
        display: flex;
        flex-direction: column;
        gap: 35px;
        .awo-button {
            align-self: flex-start;
        }
    }
    .awo-wysiwyg {
        //p:last-of-type {
        //    margin-bottom: 0;
        //}
    }
    .awo-text-image__image {
        background-position: center;
        background-size: cover;
        border-radius: 20px;
        height: 100%;
        min-height: 580px;
        width: 100%;
    }
    @include mq(sm) {
        gap: 2rem;
        grid-auto-rows: 1fr;
        grid-template-columns: 1fr 44%;
        .awo-text-image__image {
            min-height: 637px;
        }
    }
}
