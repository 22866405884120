.about-wysiwyg-content {
  margin-top: 3.5rem;

  @include mq(md) {
    margin-top: 0;
  }
}

.about-three-numbers {
  margin-top: 3.5rem;

  @include mq(md) {
    margin-top: 4.5rem;
  }

  .awo-three-numbers {
    .awo-three-numbers__grid {
      .awo-three-numbers__grid-item {
        .awo-three-numbers__grid-item__text {
          max-width: 15rem;
        }
      }
    }
  }
}

.about-person-details-content {
  margin-top: 2.75rem;
}

.about-three-col-grid {
  margin-top: 3rem;

  @include mq(md) {
    margin-top: 5.5rem;
  }
}

.about-text-with-blockquote {
  margin-top: 3rem;

  @include mq(md) {
    margin-top: 6.5rem;
  }
}

.about-tabbed-container {
  margin-top: 1rem;
  position: relative;

  @include mq(md) {
    margin-top: 6rem;
  }

  .container {
    position: relative;
  }

  .about-tabbed-container__bird {
    position: absolute;
    top: 0;
    right: 0;
    margin-top: -8.5rem;
    margin-right: 1rem;
    display: none;

    @include mq(md) {
      display: block;
    }
  }
}

.about-awo-wave-cta-one {
  margin-top: 3rem;

  @include mq(md) {
    margin-top: 6rem;
  }
}
