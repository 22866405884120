body {
    font-family: $font-awo-body;
    font-weight: 400;
    line-height: 1;
    background: #fff;
    color: $main-color;
    position: relative;
    &::before {
        position: fixed;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        background-color: #fff;
        content: '';
        z-index: 200;
        transition: 200ms ease-in-out;
    }
    &.loaded {
        &::before {
            opacity: 0;
            visibility: hidden;
        }
    }
    &.fixed {
        overflow: hidden;
    }
}

.container {
    margin: 0 auto;
    max-width: 1200px;
    padding-left: 1.25rem;
    padding-right: 1.25rem;

    @include mq(sm) {
        padding-left: 2.25rem;
        padding-right: 2.25rem;
    }

    @include mq(md) {
        padding-left: 0.625rem;
        padding-right: 0.625rem;
    }
}

.template-home, .content-gallery, .contact-locations, .container {
    h3 {
        margin-bottom: 40px;
        color: $main-color;
        font-size: 45px;
        font-weight: 400;
        letter-spacing: 1.43px;
        font-family: $lu;
        text-align: center;
    }
}
.content-page .container h3{
    text-align: left;
}
a:hover{
    text-decoration: none;
}
.clearfix::after {
    content: "";
    clear: both;
    display: table;
  }