.services {
    padding: 25px 0 50px 0;
}

.services__list {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    column-gap: 3rem;
    row-gap: 1.5rem;

    @include mq(sm) {
        grid-template-columns: repeat(2, 1fr);
        row-gap: 2.5rem;
    }

    @include mq(md) {
        grid-template-columns: repeat(3, 1fr);
        row-gap: 2.5rem;
    }
}

.services__item {
    width: 100%;
    height: 190px;
    border-radius: 20px;
    padding: 38px 30px 0 30px;
    color: $orange-color!important;
    text-decoration: none!important;
    font-family: $lu;
    font-size: 37px;
    font-weight: 400;
    line-height: 1.1;
    letter-spacing: 1.81px;

    &:hover{
        background-color: $orange-color!important;
        color: white!important;
    }
}
.service_contacts{
    p {
        margin-bottom: 5px!important;
    }
}
