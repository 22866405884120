html {
  scroll-behavior: smooth;
  scroll-padding-top: 9.25rem;
}

.max-w-264 {
  max-width: 66rem;
}

.max-w-272 {
  max-width: 68rem;
}

.max-w-280 {
  max-width: 70rem;
}

.max-w-284 {
  max-width: 71rem;
}

[x-cloak] {
  display: none;
}

.hidden {
  display: none !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.orange-panel {
  background-color: #D85625;
  border-radius: 25px;
  color: #fff;
  padding: 2rem;
  margin-top: 50px;
  text-align: left;
  &.awo-wysiwyg {

    h2, a {
      color: #fff;
      margin-top: 0;
      text-align: left;
    }
    p {
      text-align: left;
    }
    //p:last-of-type {
    //  margin-bottom: 0;
    //}
  }
}
