.resource-hub-item {

  display: flex;
  flex-wrap: wrap;

  @include mq(sm) {
    flex-wrap: nowrap;
  }

  &--left {
    flex: 0 0 100%;

    @include mq(sm) {
      flex: 0 0 30%;
    }

    .item-image {
      text-align: center;
      margin: 0 2rem;
      margin-bottom: 3rem;

      img {
        width: 100%;
        height: auto;
        min-width: 16rem;

        @include mq(sm){
          min-width: unset;
        }
      }
    }

    .item-download-button {
      text-align: center;

      a:link, a:hover, a:focus, a:active, a:visited {
        background-color: $almost-black;
        text-align: center;
        text-transform: uppercase;
        text-decoration: none;
        border: 0;
        border-radius: 12px;
        height: 52px;
        font-family: $lato;
        font-size: 16px;
        font-size: 400;
        color: $white;
        padding: 1rem 3rem;
      }
    }
  }

  &--right {
    flex: 0 0 100%;
    margin-top: 4rem;

    @include mq(sm) {
      flex: 0 0 70%;
      margin-top: 0;
    }
  }
}

.container.top-content {
  margin-bottom: 3rem;
}
