//  -------------------------------------------------------------
// 	=Main Sass file, used for importing sections
//	-------------------------------------------------------------


	// Base vars, mixins and reset
	// ----------------------------------------------------------

		@import 'base/vars';
		@import 'awo/base/awo-vars';
		@import 'base/mixins/vendor-prefixes';
		@import 'base/mixins';
		@import 'base/helpers';
		@import 'base/messages';
		@import 'base/reset';
		@import 'base/image';
    	@import 'partials/reset';

	// Components - Default styling of elements
	// ----------------------------------------------------------

	@import 'components/form-elements';
	@import 'components/style-guide';
    //listing
	@import 'components/all-listings';
	@import 'components/dismissable-modal';

	// Vendor - Plugin files
	// ----------------------------------------------------------

	@import 'vendor/magnific_popup_main';
	@import 'vendor/animate';
	@import 'vendor/jquery-modal';
	@import '../../../node_modules/swiper/swiper';
	@import '../../../node_modules/swiper/modules/navigation/navigation';
	@import '../../../node_modules/swiper/modules/pagination/pagination';
	@import '../../../node_modules/swiper/modules/effect-fade/effect-fade';
	@import '../../../node_modules/selectize/dist/css/selectize';
	@import '../../../node_modules/glightbox/dist/css/glightbox';

	// Layout - Layout files
	// ----------------------------------------------------------

	/* BASICS */

	@import 'partials/fonts';
	@import 'partials/slick';
	@import 'partials/fancybox';
	@import 'partials/vars';
	@import 'partials/basics';
	@import 'partials/shop';

	/* BLOCKS */
	@import 'blocks/header';

	@import 'blocks/home/atm';
	@import 'blocks/home/cats';
	@import 'blocks/home/links';
	@import 'blocks/home/news';
	@import 'blocks/home/numbers';
	@import 'blocks/home/shop';
	@import 'blocks/home/what';
	@import 'blocks/home/why';
	@import 'blocks/home/override';

	@import 'blocks/services/services';

	@import 'blocks/story';

	@import 'blocks/news/news';
	@import 'blocks/events/events';

	@import 'blocks/shop/shop';

	@import 'blocks/donate/donate';

	@import 'blocks/campaign/campaign';

	@import 'blocks/contact/contact';

	@import 'blocks/content/content';

	@import 'blocks/footer';
	@import 'blocks/search';
	@import 'blocks/floating-disclaimer';


	/* MEDIA */
	@import 'media';

	/* RESOURCE HUB */
	@import 'blocks/resource-hub/filter-bar';
	@import 'blocks/resource-hub/items';
	@import 'blocks/resource-hub/content-item';
	@import 'blocks/resource-hub/pagination';
	@import 'awo/base/awo-global';
	@import 'awo/base/awo-scroll-animation';
	@import 'awo/components/awo-general';
	@import 'awo/components/awo-buttons';
	@import 'awo/components/awo-swiper-pagination';
	@import 'awo/components/awo-home-selectize';
	@import 'awo/components/awo-wysiwyg-content';
	@import 'awo/components/awo-three-numbers';
	@import 'awo/components/awo-person-details-content';
	@import 'awo/components/awo-three-col-grid';
	@import 'awo/components/awo-text-with-blockquote';
	@import 'awo/components/awo-tabbed-container';
	@import 'awo/components/awo-wave-cta-one';
	@import 'awo/components/awo-document-items-row';
	@import 'awo/components/awo-accordion';
	@import 'awo/components/awo-wave-content-one';
	@import 'awo/components/awo-video-player';
	@import 'awo/components/awo-two-col-links';
	@import 'awo/components/awo-floating-social-widget';
	@import 'awo/components/awo-hero-careers';
	@import 'awo/components/awo-wysiwyg-w-buttons';
	@import 'awo/components/awo-newsletter';
	@import 'awo/components/awo-round-image-text-cta';
	@import 'awo/components/awo-text-cta';
	@import 'awo/components/awo-tabbed-content';
	@import 'awo/components/careers/awo-how-we-work';
	@import 'awo/components/awo-video-player-alt';
	@import 'awo/components/awo-image-text-carousel';
	@import 'awo/components/careers/awo-what-we-do';
	@import 'awo/components/awo-accordion-alt';
	@import 'awo/components/careers/awo-employee-benefits';
	@import 'awo/components/awo-text-image-right';
	@import 'awo/components/awo-grid-block-links';
	@import 'awo/components/careers/awo-employment-pathways';
	@import 'awo/components/awo-bg-yellow';
	@import 'awo/components/awo-hero-careers-sub-section';
	@import 'awo/components/awo-step-accordion';
	@import 'awo/components/careers/awo-faqs';
	@import 'awo/components/careers/awo-how-to-apply';

	@import 'awo/blocks/awo-header';
	@import 'awo/blocks/awo-mobile-menu';
	@import 'awo/blocks/awo-desktop-search';
	@import 'awo/blocks/awo-footer';
	@import 'awo/blocks/donate/awo-donate';

	@import 'awo/blocks/home/awo-hero';
	@import 'awo/blocks/home/awo-quick-links';
	@import 'awo/blocks/home/awo-intro-text';
	@import 'awo/blocks/home/awo-looking-for';
	@import 'awo/blocks/home/awo-hover-area';
	@import 'awo/blocks/home/awo-news-events';
	@import 'awo/blocks/home/awo-resources';
	@import 'awo/blocks/home/awo-principles';
	@import 'awo/blocks/home/awo-impact';
	@import 'awo/blocks/home/awo-careers';

	@import 'awo/templates/page';
	@import 'awo/templates/page-involved';
	@import 'awo/templates/page-about';
	@import 'awo/templates/page-annual-reports';
	@import 'awo/templates/page-foster-care';
	@import 'awo/templates/page-public-statements';

	@import 'awo/base/awo-hamburger';
	@import '../../../node_modules/hamburgers/_sass/hamburgers/hamburgers';
