.awo-looking-for {
  background-color: $awo-old-lace;
  position: relative;
  padding-top: 4.25rem;
  padding-bottom: 3rem;

  @include mq(md) {
    padding-top: 0;
    padding-bottom: 0;
  }

  &__section {
    position: relative;
    z-index: 1;
  }

  .awo-looking-for__dropdown-container--title {
    font-family: $font-awo-title;
    font-size: 40px;
    line-height: 44px;
    margin-top: 0;
    margin-bottom: 1.5rem;

    @include mq(md) {
      margin-top: 0.25rem;
      margin-bottom: 0;
      font-size: 61px;
      line-height: 84px;
    }
  }

  .container {
    position: relative;
    overflow: visible;
  }

  .awo-looking-for__bird-image {
    position: absolute;
    top: 0;
    right: 0;
    margin-top: -8.5rem;
    display: none;

    @include mq(md) {
      display: block;
    }
  }
}

.awo-looking-for__top-wave,
.awo-looking-for__bottom-wave {
  svg {
    fill: $awo-old-lace;
    stroke: $awo-old-lace;
    margin-bottom: -0.5rem;
  }
}

.awo-looking-for__top-wave {
  position: relative;
  padding-top: 3.75rem;

  @include mq(md) {
    padding-top: 4.5rem;
  }

  .awo-looking-for__top-wave--container {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    min-height: 2rem;
    margin-bottom: -4rem;

    @include mq(md) {
      margin-bottom: 0;
    }

    .awo-looking-for__title {
      margin: 0;
      font-size: 15px;
      line-height: 25px;
      font-weight: 600;
      color: $awo-yellow-bright-crayola;
      z-index: 2;
      position: relative;
      font-family: $font-awo-body;

      @include mq(md) {
        font-size: 20px;
        line-height: 20px;
      }
    }
  }
}
