.floating-disclaimer {
  width: 100%;
  position: fixed;
  bottom: 0px;
  z-index: 1000;
  .container {
	background-color: $almost-black;
	color: white;
	padding: 15px 30px;
	position: relative;
	border-radius: 5px;
	p {
	  font-size: 12px;
	  margin: 0;
	  @include mq(sm) {
		font-size: 20px;
	  }
	}
	a {
	  color: $white;
	  text-decoration: underline;
	  &:hover {
		text-decoration: none;
	  }
	}
	.close {
	  right: 10px;
	  position: absolute;
	  top: 10px;
	  background: url('/img/svg/icon-close.svg') no-repeat center;
	  width: 20px;
	  height: 20px;
	  background-size: cover;
	  &:hover {
		cursor: pointer;
	  }
	}
  }
  &.message-banner {
	position: absolute;
	top: 80px;
	z-index: 1001;
	bottom: auto;
	.container {
	  background-color: #538caa;
	  border-top-left-radius: 0;
	  border-top-right-radius: 0;
	}
  }
}
